import { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentBaseQueryId } from 'ducks/searches/selectors'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'
import {
  selectMissingPinnedSearchTagIdsAndNames,
  selectPinnedSearchesByChannelId,
} from 'ducks/pinnedSearch/selectors'
import { isEmpty } from 'util/arrays'
import {
  isBoostrappedSelector,
  selectAccountCanSeePinnedSearches,
} from 'selectors/app'
import { doFetchTagsV2ByIdsOrNames } from 'ducks/tags/actions'
import { toBaseQueryId } from 'ducks/searches/utils/query'

export const usePinnedSearches = () => {
  const dispatch = useDispatch()
  const canSeePinnedSearches = useSelector(selectAccountCanSeePinnedSearches)
  const { channel = [] } = useSelector(selectCurrentQuery)
  const [isOpen, setIsOpen] = useState(!channel)
  const [hasLoaded, setHasLoaded] = useState(false)
  const currentQueryId = useSelector(selectCurrentBaseQueryId)
  const missingTags = useSelector(selectMissingPinnedSearchTagIdsAndNames)
  const isBootstrapped = useSelector(isBoostrappedSelector)

  const onPinClick = useCallback(() => {
    setIsOpen(currentIsOpen => !currentIsOpen)
  }, [])

  const pinnedSearches = useSelector(state =>
    selectPinnedSearchesByChannelId(state, channel[0])
  )

  const shouldShowStarredSearches =
    canSeePinnedSearches && pinnedSearches && !isEmpty(pinnedSearches)

  useEffect(
    () => {
      if (isBootstrapped && !hasLoaded && missingTags.length > 0) {
        setHasLoaded(true)
        dispatch(doFetchTagsV2ByIdsOrNames(missingTags))
      }
    },
    [dispatch, hasLoaded, missingTags, isBootstrapped]
  )

  useEffect(
    () => {
      const shouldCollectionByOpen = pinnedSearches.some(
        ({ queryId: fullQueryId }) => {
          const queryId = toBaseQueryId(fullQueryId)
          return queryId === currentQueryId
        }
      )

      if (shouldCollectionByOpen) setIsOpen(shouldCollectionByOpen)
    },
    [currentQueryId, pinnedSearches]
  )

  return {
    shouldShowStarredSearches,
    onPinClick,
    isOpen,
    pinnedSearches,
    canSeePinnedSearches,
    channelId: channel,
    currentQueryId,
  }
}
