import { createBasicSelector } from 'util/redux'
import { selectAccountPreferenceReportingPermission } from 'selectors/app'
import { selectIsFreemium } from 'ducks/billing/selectors/selectIsFreemium'
import { AGENT_ROLE_ADMIN, AGENT_ROLE_OWNER } from 'ducks/agents/constants'
import { selectCurrentUser } from './selectCurrentUser'

export const selectReportingPermitted = createBasicSelector(
  selectCurrentUser,
  selectAccountPreferenceReportingPermission,
  selectIsFreemium,
  (currentUser, reportingPermission, isFreemium) => {
    if (isFreemium) return false
    if (currentUser.role === AGENT_ROLE_OWNER) {
      return true
    }

    if (
      reportingPermission === AGENT_ROLE_ADMIN &&
      currentUser.role === AGENT_ROLE_ADMIN
    ) {
      return true
    }

    if (reportingPermission === 'ALL') {
      return true
    }

    return false
  }
)
