export const HAS_SEEN_INSTALL_APP_MENU = 'has_seen_install_app_menu'
export const HIDE_INSTALL_APP_MENU = 'hide_install_app_menu'
export const HIDE_TEAMMATES_CARD = 'hide_teammates_card'
export const HIDE_EMAIL_TEMPLATES_VIDEO = 'hide_email_templates_video'
export const HIDE_FOLDERS_VIDEO = 'hide_folders_video'
export const HIDE_RULES_VIDEO = 'hide_rules_video'
export const HIDE_TEAMS_VIDEO = 'hide_teams_video'
export const HIDE_TEAMMATES_VIDEO = 'hide_teammates_video'
export const HIDE_INTEGRATION_CARD = 'hide_integration_card'
export const HIDE_SHOPIFY_V2_SIDEBAR_INSTALL_CARD =
  'hide-shopify-v2-sidebar-install'
export const HIDE_IMPROVEMENT_IDEA = 'hide-improvement-idea'
export const HIDE_BOOK_DEMO_CARD = 'hide_book-demo_card'
export const HIDE_ROADMAP_ANNOUNCEMENT = 'hide_roadmap_announcement'
export const HIDE_OKR_RECAP_VIDEO_ANNOUNCEMENT =
  'hide_okr_recap_video_announcement'
export const HIDE_CANNED_REPLY_ACTIONS_CALLOUT =
  'hide_canned_reply_actions_callout'
export const HIDE_CANNED_REPLY_PREVIEW_CALLOUT =
  'hide_canned_reply_preview_callout'
export const HIDE_CANNED_REPLY_INBOX_SCOPE_CALLOUT =
  'hide_canned_reply_inbox_scope_callout'

export const HIDE_RULE_TRIGGERS_CALLOUT = 'hide_rule_triggers_callout'
export const HIDE_RULE_ADVANDED_CALLOUT = 'hide_rule_advanced_callout'
export const HIDE_FEATURES_ANNOUNCEMENT = 'hide_features_announcement_2023_08'
export const HIDE_INBOX_SEARCH_FILTER_CALLOUT =
  'hide_inbox_search_filter_callout'
export const HAS_SEEN_TICKETS_WAIT_LONGEST = 'has_seen_tickets_wait_longest'
export const HIDE_TICKETS_WAIT_LONGEST_CALLOUT =
  'hide_tickets_wait_longest_callout'
export const HIDE_CSAT_MULTIPLE_CALLOUT = 'hide_csat_multiple_callout'
export const HIDE_ANNUAL_OFFER = 'hide_annual_offer'
export const HIDE_AI_MESSAGE_CALLOUT = 'hide_ai_message_callout'
export const HIDE_AI_TEXT_MODIFICATIONS_CALLOUT =
  'hide_ai_text_modifications_callout'
export const HIDE_TWO_FA_CALLOUT = 'hide_two_fa_callout'
export const HIDE_NOTE_EDIT_CALLOUT = 'hide_note_edit_callout'
export const HIDE_CUSTOM_NOTIFICATIONS_CALLOUT =
  'hide_custom_notifications_callout'
export const HIDE_NEW_COMPOSER_CALLOUT = 'hide_new_composer_callout'
export const EDITOR_PINNED = 'editor_pinned'
export const HIDE_AI_AGENT_DOT = 'hide_ai_agent_dot'
