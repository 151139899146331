/* eslint-disable no-underscore-dangle */
import {
  FETCH_CONVERSATION_SUCCESS,
  PRELOAD_CONVERSATIONS_SUCCESS,
} from 'ducks/tickets/actionTypes'
import {
  CHANGE_TYPE_NOTE_MESSAGE,
  CHANGE_TYPE_SUMMARY_MESSAGE,
} from 'ducks/tickets/constants'
import {
  buildConversationEventsByGroupIdRequestKey,
  buildConversationRequestKey,
} from 'ducks/tickets/utils/request'
import { withPush } from 'util/arrays'
import { getRawId } from 'util/globalId'
import { createActionTypeReducer } from 'util/reducers'
import { camelize } from 'util/strings'

const initialState = {
  last100RequestIds: [],
}

const updateLast100RequestIds = (draftState, requestId) => {
  if (!requestId) return
  withPush(draftState.last100RequestIds, requestId)
  if (draftState.last100RequestIds.length > 100) {
    draftState.last100RequestIds.shift()
  }
}

// We have a special case for notes in the fact that they can get updated. When this happens
// we need to mark the request as unloaded so that the frontend can kick off reloading the
// request and updating the store with the edited data
const markConversationEventsByGroupIdRequestUnloaded = (draftState, action) => {
  const currentMessageById =
    action?.entities?.state?.current?.message?.byId || {}
  const newMessageById = action?.transformedEntities?.message || {}
  const currentConverstionEventGroupById =
    action?.entities?.state?.current?.conversationEventGroup?.byId || {}
  const newConverstionEventGroupById =
    action?.transformedEntities?.conversationEventGroup || {}
  const eventGroupIdByMessageId = Object.values(
    action?.entities?.state?.current?.conversationEventGroup?.byId || {}
  ).reduce((acc, eventGroup) => {
    // eslint-disable-next-line no-param-reassign
    acc[eventGroup.summary] = eventGroup.id
    return acc
  }, {})

  Object.keys(newMessageById).forEach(messageId => {
    const currentMessage = currentMessageById[messageId]
    const newMessage = newMessageById[messageId]
    const eventGroupId = eventGroupIdByMessageId[messageId]
    const currentEventGroup = currentConverstionEventGroupById[eventGroupId]
    const newEventGroup = newConverstionEventGroupById[eventGroupId]

    if (
      eventGroupIdByMessageId[messageId] &&
      currentMessage &&
      ((currentMessage.__typename === CHANGE_TYPE_NOTE_MESSAGE &&
        newMessage.__typename === CHANGE_TYPE_SUMMARY_MESSAGE) ||
        (currentEventGroup &&
          newEventGroup &&
          currentEventGroup.lastEventId !== newEventGroup.lastEventId))
    ) {
      const requestKey = camelize(
        buildConversationEventsByGroupIdRequestKey(
          eventGroupIdByMessageId[messageId]
        )
      )
      draftState[requestKey] = {
        untried: true,
        loading: false,
        loaded: false,
        error: false,
      }
    }
  })
  return draftState
}

const markConversationLoaded = (draftState, action) => {
  const conversationIds = action.meta?.requestParameters?.conversationIds || []
  conversationIds.forEach(conversationId => {
    const requestKey = camelize(
      buildConversationRequestKey(getRawId(conversationId))
    )

    Object.assign(draftState, {
      [requestKey]: {
        untried: false,
        loading: false,
        loaded: true,
        error: false,
      },
    })
  })
  return draftState
}

export default createActionTypeReducer(
  {
    '*': (
      draftState,
      { type, meta: { requestId, requestKey } = {}, payload }
    ) => {
      const actionName = camelize(type.substring(0, type.lastIndexOf('_')))
      const actionType = type.split('_').pop()
      const id = requestKey || actionName

      // Apply logic based on actionType, similar to switch-case in the original reducer
      switch (actionType) {
        case 'STARTED':
        case 'REQUEST':
          Object.assign(draftState, {
            [id]: {
              untried: false,
              loading: true,
              loaded: draftState[id]?.loaded || false,
              error: false,
            },
          })
          updateLast100RequestIds(draftState, requestId)
          break
        case 'SUCCESS':
          Object.assign(draftState, {
            [id]: {
              untried: false,
              loading: false,
              loaded: true,
              error: false,
            },
          })
          updateLast100RequestIds(draftState, requestId)
          break
        case 'FAILED':
          Object.assign(draftState, {
            [id]: {
              untried: false,
              loading: false,
              loaded: draftState[id]?.loaded || false,
              error: payload,
            },
          })
          updateLast100RequestIds(draftState, requestId)
          break
        default:
        // Do nothing for unknown action types
      }
      return draftState
    },
    [PRELOAD_CONVERSATIONS_SUCCESS]: [
      markConversationLoaded,
      markConversationEventsByGroupIdRequestUnloaded,
    ],
    [FETCH_CONVERSATION_SUCCESS]: markConversationEventsByGroupIdRequestUnloaded,
  },
  initialState
)
