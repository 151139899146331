import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { createBasicSelector } from 'util/redux'
import { selectContactById } from './base'

export const selectContactFullNameOrYouByContactId = createBasicSelector(
  selectContactById,
  selectCurrentUser,
  (contact, currentUser) => {
    if (!contact) return null
    return contact.email === currentUser.email ? 'You' : contact.name
  }
)
