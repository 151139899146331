import { doLinkResource } from 'actions/userIntegrations'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import {
  selectJiraIssuesById,
  selectJiraSitesById,
} from 'ducks/integrations/jiraShared'
import doPostTicketLinkOnJiraCloudIssue from './doPostTicketLinkOnJiraCloudIssue'

export default function doLinkJiraCloudIssueToTicket(cloudId, issueId) {
  return (dispatch, getState) => {
    const state = getState()
    const issues = selectJiraIssuesById(state, { source: 'jiraCloud' })
    const sites = selectJiraSitesById(state, { source: 'jiraCloud' })
    const issue = issues[issueId]
    const { name: siteName, url: siteUrl } = sites[cloudId]
    const ticketId = selectCurrentTicketId(state)

    if (!issue) return Promise.reject('Cannot find Jira Cloud issue')

    const {
      key,
      fields: { summary },
    } = issue
    const url = `${siteUrl}/browse/${key}`

    return Promise.all([
      dispatch(
        doLinkResource({
          ticketId,
          title: `[${key}] ${summary}`,
          url,
          id: `${siteName}:${issueId}`,
          provider: 'jira_cloud',
        })
      ),
      dispatch(doPostTicketLinkOnJiraCloudIssue(cloudId, issueId)),
    ])
  }
}
