import { createBasicSelector } from 'util/redux'
import { billingUsageKeyFeature } from '../../featureTypes'
import { selectBillingUsage } from '../selectBillingUsage'

export const selectBillingUsageForFeature = createBasicSelector(
  selectBillingUsage,
  (_state, feature) => feature,
  (usage, feature) => {
    const key = billingUsageKeyFeature(feature)
    return usage[key]
  }
)
