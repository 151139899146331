import { doLinkResource } from 'actions/userIntegrations'
import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentConversationUrl } from 'selectors/tickets'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { filter } from 'util/objects'

import * as trello from '../api'
import {
  CREATE_CARD_REQUEST,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAILURE,
} from '../types'
import doFetchTrelloCard from './doFetchTrelloCard'

export default function doCreateAndLinkTrelloCardToTicket({
  description,
  dueDate,
  label,
  member,
  list,
  name,
}) {
  return (dispatch, getState) => {
    dispatch({ type: CREATE_CARD_REQUEST })
    const state = getState()
    const token = oauthTokenSelector(state)
    const data = filter(value => !!value, {
      desc: description,
      due: dueDate,
      name,
      idLabels: label && label.join(','),
      idList: list,
      idMembers: member && member.join(','),
    })
    const ticketUrl = selectCurrentConversationUrl(state)
    const ticketId = selectCurrentTicketId(state)
    return trello
      .createCard(token, data)
      .then(payload => {
        dispatch({
          type: CREATE_CARD_SUCCESS,
          ticketId,
          payload,
        })
        return payload
      })
      .then(payload => {
        const { name: title, url, id } = payload
        return Promise.all([
          dispatch(doFetchTrelloCard(id)),
          dispatch(
            doLinkResource({ ticketId, title, url, id, provider: 'trello' })
          ),
          trello.attachTicketToCard(
            token,
            id,
            ticketUrl,
            `Groove conversation #${ticketId}`
          ),
        ])
      })
      .catch(err =>
        dispatch({
          error: true,
          type: CREATE_CARD_FAILURE,
          payload: err,
        })
      )
  }
}
