import graphql from 'api/graphql'
import { v4 as uuidV4 } from 'uuid'
import { mergePlain } from 'util/merge'

import { doHideModal } from 'actions/modals'
import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'

import {
  selectInterpolateCannedReply,
  selectPendingCannedReplyById,
  selectCannedReplyCategories,
  selectIsLoadingCategoriesOrTemplates,
  selectCurrentCategoryBatchId,
  selectLoadingCannedReplyCategoryIds,
  selectLoadedCannedReplyCategoryIds,
  selectLastRequestedSearchTerm,
  selectCurrentCannedReplyById,
} from 'ducks/cannedReplies/selectors'

import {
  doGraphqlRequest,
  doApiReadRequest,
  doApiWriteRequest,
  doAttachementRequest,
} from 'ducks/requests/operations'

import {
  queryIdToQuery,
  constructGraphQLOrderByObject,
  constructApiV1SortBy,
} from 'ducks/searches/utils/query'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import {
  changeEntity,
  syncEntity,
  mergeEntityChanges,
} from 'ducks/entities/actionUtils'
import { cannedReplyCategory } from 'ducks/entities/schema'
import { handleDraftChange } from 'ducks/drafts2/operations'
import { selectDraftByTicketId } from 'ducks/drafts2/selectors'

import { buildId, getRawId } from 'util/globalId'
import { reverseHashInt, hash } from 'util/scatterSwap'
import { selectCurrentUserId } from 'ducks/currentUser/selectors/selectCurrentUserId'
import { runOnNextTick } from 'util/functions'
import { compact } from 'util/objects'
import { getWrappedRootNodes } from 'util/cannedReply'
import editor from 'shared/editor/utils'
import { selectCurrentTagById } from 'ducks/tags/selectors'

import { ATTACHMENT_UPLOAD_FINISHED } from 'constants/action_types'
import { doFetchTagsV2ByIds } from 'ducks/tags/actions'
import { cannedReplyActions } from 'subapps/settings/components/CannedReplyAutomaticActions/data'
import {
  UPDATE_MESSAGE_TEMPLATES,
  DELETE_MESSAGE_TEMPLATES,
} from 'ducks/batchJobs/jobTypes'
import { selectAgentById } from 'selectors/agents/base'
import selectGroupForId from 'ducks/teams/selectors/selectGroupForId'
import { selectIsInNoteMode } from 'selectors/location'
import { doTryFetchAccountUsageOnboardingForOnboarding } from 'ducks/accountPreferences/operations'
import { selectFeatureBasedOnboardingWorkflowData } from 'subapps/onboarding/selectors'

import {
  FETCH_CANNED_REPLY_CATEGORIES,
  FETCH_CANNED_REPLY_CATEGORIES_INMEMORY,
  FETCH_CANNED_REPLIES,
  TOGGLE_CANNED_REPLY_COLLAPSED_STATE,
  INSERT_CANNED_REPLY_REQUEST,
  INSERT_CANNED_REPLY,
  SET_TEMPLATE_SEARCH_TERM,
  CLEAR_TEMPLATES,
  FETCH_CANNED_REPLY_VARIABLES,
  UPDATE_CANNED_REPLY_TEMPLATE_OPTIMISTIC,
  FETCH_CANNED_REPLY_TEMPLATE,
  DELETE_CANNED_REPLIES,
  CREATE_CANNED_REPLY_CATEGORY_DRAFT,
  UPLOAD_CANNED_REPLY_ATTACHMENT,
  SAVE_CANNED_REPLY_TEMPLATE,
  SAVE_CANNED_REPLY_CATEGORY,
  UPDATE_CANNED_REPLY_CATEGORY,
  SAVE_CANNED_REPLY_TEMPLATE_DRAFT,
  CLEAR_CANNED_REPLY_TEMPLATE_DRAFT,
  TOGGLE_CANNED_REPLY_DROPDOWN,
} from './types'

import { getAllQuery as getAllQueryV1 } from './queries'
import {
  getAllQuery as getAllQueryV2,
  getAllCategoriesQuery as getAllCategoriesQueryV2,
} from './queries.v2'
import {
  cannedReplyGraphQlResponseSchema,
  cannedReplyCategoriesGraphQlResponseSchema,
  cannedReplyApiV0SettingsResponseSchema,
  cannedReplyApiV1ResponseSchema,
  cannedRepliesApiV1ResponseSchema,
  cannedReplyCategoryApiV1ResponseSchema,
  cannedReplyCategoryGraphqlV1ResponseSchema,
  cannedReplyVariablesApiV1ResponseSchema,
} from './schema'

export const doFetchCannedReplyCategories = (
  keyword,
  options = {}
) => dispatch => {
  const query = `
      query CommentTemplateCategoriesQuery(
        $keyword: String
      ) {
        commentTemplateCategories(keyword: $keyword) {
          id
          name
          total
        }
      }
    `
  // to add the results to the entities redux store
  const optionsWithSchema = {
    ...options,
    normalizationSchema: cannedReplyCategoryGraphqlV1ResponseSchema,
  }

  return dispatch(
    doGraphqlRequest(
      FETCH_CANNED_REPLY_CATEGORIES,
      query,
      { keyword },
      optionsWithSchema
    )
  )
}

export const doFetchCannedReplies = (
  categoryIds,
  keyword = '',
  mailboxIds = null,
  source
) => (dispatch, getState) => {
  const state = getState()
  const categoryBatchId = selectCurrentCategoryBatchId(state)
  const lastRequestSearchTerm = selectLastRequestedSearchTerm(state)
  const loadingCannedReplyCategoryIds = selectLoadingCannedReplyCategoryIds(
    state
  )
  const loadedCannedReplyCategoryIds = selectLoadedCannedReplyCategoryIds(state)

  const isEmptySearch = categoryIds.length === 0 && keyword.trim() === ''
  const isKeywordChange = lastRequestSearchTerm !== keyword

  let pendingCategoryIds = categoryIds

  if (lastRequestSearchTerm === keyword) {
    pendingCategoryIds = categoryIds.filter(
      c => !loadingCannedReplyCategoryIds.includes(c)
    )
    pendingCategoryIds = categoryIds.filter(
      c => !loadedCannedReplyCategoryIds.includes(c)
    )
  }
  const isMissingCategoryTemplates = pendingCategoryIds.length > 0

  if (!isEmptySearch && !isKeywordChange && !isMissingCategoryTemplates)
    return Promise.resolve()

  return dispatch(
    doGraphqlRequest(
      FETCH_CANNED_REPLIES,
      getAllQueryV1(),
      {
        categoryIds: pendingCategoryIds,
        mailboxIds,
        keyword,
        source: source || 'es',
      },
      {
        meta: {
          categoryBatchId,
        },
      }
    )
  )
}

export function doToggleCollapedStateForCategories(categoryIds) {
  return {
    type: TOGGLE_CANNED_REPLY_COLLAPSED_STATE,
    payload: {
      categoryIds,
    },
  }
}

export const doFetchCannedReplyCategoriesAndTemplatesBySearch = (
  keyword,
  mailboxIds = []
) => (dispatch, getState) => {
  const stateKeyword = selectLastRequestedSearchTerm(getState())
  const requestId = uuidV4()
  if (stateKeyword !== keyword) {
    return doFetchCannedReplyCategories(keyword, { requestId })(
      dispatch,
      getState
    ).then(() => {
      const state = getState()
      const categoryBatchId = selectCurrentCategoryBatchId(state)
      if (keyword.trim() !== '' && requestId === categoryBatchId) {
        const categories = selectCannedReplyCategories(getState())
        const categoryIds = categories.map(c => c.id)
        dispatch(doToggleCollapedStateForCategories(categoryIds))
        return doFetchCannedReplies(categoryIds, keyword, mailboxIds)(
          dispatch,
          getState
        )
      }
      return Promise.resolve()
    })
  }
  return Promise.resolve()
}

const fetchCannedReplyContent = (token, reply) => {
  const query = `
    query CannedRepliesBodyQuery {
      commentTemplate(id: "${reply.id}") {
        body
        attachments {
          file_name
          file_size
          file_type
          s3_key
          url
        }
      }
    }
  `
  return graphql(token, query).then(response => {
    const data = response.json.data
    return data.commentTemplate
  })
}

export const doInsertCannedReply = reply => async (dispatch, getState) => {
  let state = getState()
  const token = oauthTokenSelector(state)
  const ticketId = selectCurrentTicketId(state)
  const interpolateCannedReply = selectInterpolateCannedReply(state)
  const fields = {}
  const isNote = selectIsInNoteMode(state)
  const draftType = isNote ? 'note' : 'reply'
  let draftId

  dispatch({
    type: INSERT_CANNED_REPLY_REQUEST,
    data: { reply },
  })

  let draft = selectDraftByTicketId(state, ticketId, draftType)

  if (!draftId) {
    // Ensure that a draft exists
    await handleDraftChange(dispatch, draftType, draftId, ticketId, null, {
      touched: true,
    })

    // we changed state, refresh it
    state = getState()

    draft = selectDraftByTicketId(state, ticketId, draftType)
  }

  draftId = draft.id

  // Connect the canned reply's id to the draft
  fields.templateId = reply && reply.id

  // Get the body and attachments of the canned reply
  const cannedReplyContent = await fetchCannedReplyContent(token, reply)

  const overrides = {}

  if (ticketId === 'new') {
    if (draft.title) {
      fields.title = interpolateCannedReply(draft.title)
      overrides.title = fields.title
    } else if (reply.subject) {
      fields.title = interpolateCannedReply(reply.subject)
      overrides.title = fields.title
    }
  }

  // Process the body and update the editor
  fields.body = await processCannedReplyBody(
    state,
    draft,
    cannedReplyContent.body,
    overrides
  )

  if (reply?.automaticActions?.length) {
    fields.automaticActions = reply.automaticActions
  }

  // Process the attachments
  const attachmentsData = processCannedReplyAttachments(
    ticketId,
    { ...draft, ...fields },
    cannedReplyContent.attachments
  )

  if (attachmentsData) {
    attachmentsData.forEach(attachmentData => {
      dispatch({
        type: ATTACHMENT_UPLOAD_FINISHED,
        data: attachmentData,
      })
    })
  }

  handleDraftChange(dispatch, draftType, draftId, ticketId, null, {
    touched: true,
    ...fields,
  })

  return dispatch({
    type: INSERT_CANNED_REPLY,
    data: {
      reply,
    },
  })
}

// we only allow one search globally
let searchTimeoutId
const searchDelay = 125

export const doFetchCannedReplyTemplatesBySearch = keyword => (
  dispatch,
  getState
) => {
  dispatch({ type: SET_TEMPLATE_SEARCH_TERM, payload: { keyword } })
  const state = getState()
  const isLoading = selectIsLoadingCategoriesOrTemplates(state)
  if (keyword.trim() !== '' && !isLoading) {
    if (searchTimeoutId) clearTimeout(searchTimeoutId)
    searchTimeoutId = setTimeout(() => {
      dispatch(doClearTemplates())
      doFetchCannedReplies([], keyword)(dispatch, getState)
    }, searchDelay)
    return Promise.resolve()
  }
  return Promise.resolve()
}

export function doSelectReply(reply) {
  return dispatch => {
    if (!reply) return false
    runOnNextTick(async () => {
      await dispatch(doInsertCannedReply(reply))
      dispatch(doHideModal())
    })
    return true
  }
}

function isContentChanged(previousContent = '', content = '') {
  // Before comparing, need to delete the zero-width character at the cursor position
  const re = /\u200B/g
  return previousContent.replace(re, '') !== content.replace(re, '')
}

function setCaretAtContentEnd() {
  const tinyEditor = editor.getEditor()
  tinyEditor.selection.select(tinyEditor.getBody(), true)
  tinyEditor.selection.collapse(false)
  tinyEditor.focus()
}

let previousBodyContent
function processCannedReplyBody(state, draft, cannedReplyBody, overrides) {
  const interpolateCannedReply = selectInterpolateCannedReply(state)
  // let newTitle = draft.title || ''
  let newBody = draft.body || ''
  previousBodyContent = newBody
  const interpolatedBody = interpolateCannedReply(
    cannedReplyBody || '',
    overrides
  )
  const tinyEditor = editor.getEditor()

  if (tinyEditor) {
    const wrappedNodes = getWrappedRootNodes(interpolatedBody, tinyEditor)

    if (!wrappedNodes) {
      return editor.getContent()
    }

    // Images in the settings editor are styled using the style property
    // For security, we remove that property
    // Which is why we need to copy width and height to their own properties
    const images = Array.from(wrappedNodes.getElementsByTagName('img'))
    images.forEach(image => {
      // If image has no width style, use the width and height from figure (block styled image is wrapped in a figure in the settings editor)
      const figureElement =
        image.parentElement?.tagName === 'FIGURE' ? image.parentElement : null
      const style = image.style.width ? image.style : figureElement?.style

      if (!style) {
        return
      }
      // eslint-disable-next-line no-param-reassign
      if (style.height) image.height = parseInt(style.height, 10)
      if (style.width) {
        // The images of canned replies may have a percentage width
        const settingsEditorWidth = 680
        const width = style.width.includes('%')
          ? parseInt(style.width, 10) * settingsEditorWidth / 100
          : parseInt(style.width, 10)
        // eslint-disable-next-line no-param-reassign
        image.width = width
      }

      if (figureElement) {
        // Fix margin changes on sending and showing the reply:
        // Remove the image's figure element, which has 40px default margin from browsers.
        // And our backend remove it too.
        figureElement.outerHTML = figureElement.innerHTML
      }
    })
    const isSelectionInEditor = !!tinyEditor.dom.getParent(
      tinyEditor.selection.getNode(),
      '.mce-content-body'
    )
    const contentBeforeInsert = editor.getContent()

    // Inserting the canned reply at the end for new conversation with draft if the editor hasn't been focused before
    if (editor.getContent() && !isSelectionInEditor) {
      // The editor hasn't been focused before, then the selection(selected node) isn't inside the editor,
      // the reply will be inserted before the exiting content.
      // Need to put the caret at the end of the content before inserting,
      // to make sure the canned reply is inserted after the content
      setCaretAtContentEnd()
    }

    // Inserts content at caret position
    tinyEditor.insertContent(wrappedNodes.innerHTML)

    if (!isContentChanged(contentBeforeInsert, editor.getContent())) {
      // If the previous insert fails, adding the reply at the end.
      // For example, if you insert divs into the strong tag, the editor will filter out the invalid content.
      setCaretAtContentEnd()
      tinyEditor.insertContent(wrappedNodes.innerHTML)
    }
    // now that we've inserted, we need to set the new body
    newBody = editor.getContent()
  }

  return newBody
}

function processCannedReplyAttachments(
  ticketId,
  draft,
  cannedReplyAttachments
) {
  if (!cannedReplyAttachments || cannedReplyAttachments.length === 0) {
    return []
  }

  return cannedReplyAttachments.map(attachment => {
    return {
      file: {
        name: attachment.file_name,
        size: attachment.file_size,
        type: attachment.file_type,
      },
      url: attachment.url,
      ticketId,
      key: attachment.s3_key,
      forNote: false,
      // HACK (jscheel) We don't want to use an expiring url because the draft
      // may be viewed after the expiring url expires. We need to fix this
      // everywhere, but just trying to take care of a broken issue on prod right now.
      expiringUrl: attachment.url,
      draftId: draft.id,
    }
  })
}

export function doSetTemplateSearchTerm(keyword) {
  return {
    type: SET_TEMPLATE_SEARCH_TERM,
    payload: {
      keyword,
    },
  }
}

export function doClearTemplates() {
  return {
    type: CLEAR_TEMPLATES,
  }
}

export const doUndoInsertCannedReply = () => () => {
  editor.setContent(previousBodyContent, { focus: true })
}

export const doFetchCannedRepliesV1 = ({ queryId }) => dispatch => {
  const { cursor = 1, pageSize = 5, search, category: categoryId } =
    queryIdToQuery(queryId) || {}
  const sortBy = constructApiV1SortBy(queryId)

  const params = compact({
    category: categoryId,
    keywords: search,
    page: cursor,
    per_page: pageSize,
    sort_by: sortBy,
  })

  return dispatch(
    doApiReadRequest(FETCH_CANNED_REPLIES, 'v1/templates', params, {
      normalizationSchema: cannedRepliesApiV1ResponseSchema,
      app: true,
      searches: {
        queryId,
        cursor,
        extractPagination: 'apiv1',
      },
    })
  )
}

const LOAD_ALL_CANNED_REPLY_CATEGORY_QUERYID =
  'entityType:cannedReplyCategory pageSize:10000'
export const doFetchCannedReplyCategoriesV1 = ({ skipLoaded } = {}) => (
  dispatch,
  getState
) => {
  const queryId = LOAD_ALL_CANNED_REPLY_CATEGORY_QUERYID
  const cursor = 'all'
  const state = getState()
  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale

  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({})
  }

  return dispatch(
    doApiReadRequest(
      FETCH_CANNED_REPLY_CATEGORIES,
      'v1/template_categories',
      {
        per_page: 10000,
      },
      {
        normalizationSchema: cannedReplyCategoryApiV1ResponseSchema,
        app: true,
        searches: {
          queryId,
          cursor,
          extractPagination: 'apiv1',
        },
      }
    )
  )
}

export const doFetchCannedReplyCategoriesInMemory = createDoFetchInMemoryByQueryId(
  {
    fromQueryId: LOAD_ALL_CANNED_REPLY_CATEGORY_QUERYID,
    entityType: 'cannedReplyCategory',
    doLoadAllFn: doFetchCannedReplyCategoriesV1,
    STARTED_ACTION_TYPE: FETCH_CANNED_REPLY_CATEGORIES_INMEMORY,
    SUCCESS_ACTION_TYPE: FETCH_CANNED_REPLY_CATEGORIES_INMEMORY,
  }
)

export const doFetchCannedRepliesV2 = ({ queryId }) => dispatch => {
  const { cursor, pageSize = 20, search, categoryId } =
    queryIdToQuery(queryId) || {}
  const orderBy = constructGraphQLOrderByObject(queryId)

  return dispatch(
    doGraphqlRequest(
      FETCH_CANNED_REPLIES,
      getAllQueryV2(),
      {
        filter: {
          keywords: search,
          categoryIds: categoryId ? [categoryId] : undefined,
        },
        cursor,
        size: pageSize,
        orderBy,
      },
      {
        normalizationSchema: cannedReplyGraphQlResponseSchema,
        app: true,
        searches: {
          queryId,
          cursor,
        },
      }
    )
  )
}

export const doFetchCannedReplyCategoriesV2 = () => dispatch => {
  return dispatch(
    doGraphqlRequest(
      FETCH_CANNED_REPLY_CATEGORIES,
      getAllCategoriesQueryV2(),
      {
        size: 1000,
        orderBy: {
          field: 'NAME',
          direction: 'ASC',
        },
      },
      {
        normalizationSchema: cannedReplyCategoriesGraphQlResponseSchema,
        app: true,
      }
    )
  )
}

export const doFetchCannedReplyVariables = () => dispatch => {
  return dispatch(
    doApiReadRequest(
      FETCH_CANNED_REPLY_VARIABLES,
      'v1/template_variables',
      {
        per_page: 10000,
      },
      {
        normalizationSchema: cannedReplyVariablesApiV1ResponseSchema,
      }
    )
  )
}

export const doFetchCannedReplyByIdV0Settings = (
  gid,
  options = {}
) => dispatch => {
  // targetStore: optional, defaults to changeEntity()'s default store otherwise
  const { targetStore } = options

  return dispatch(
    doApiReadRequest(
      FETCH_CANNED_REPLY_TEMPLATE,
      `api/settings/canned_replies/${gid}`,
      {},
      {
        normalizationSchema: cannedReplyApiV0SettingsResponseSchema,
        moduleOptions: {
          entities: {
            targetStore,
            missingEntityActions: [
              {
                entityType: 'cannedReply',
                // convert from gid to scatter swapped that's used in entity store
                entityId: getRawId(gid),
                // Only remove the missing cannedReply for the entity store after fetching the template
                phases: ['SUCCESS'],
              },
            ],
          },
        },
      }
    )
  )
}

// eslint-disable-next-line no-unused-vars
export const doSaveCannedReplyTemplateDraft = (id, fields, options = {}) => {
  return {
    type: SAVE_CANNED_REPLY_TEMPLATE_DRAFT,
    ...changeEntity('cannedReply', id, fields, 'update', 'pending'),
  }
}

// eslint-disable-next-line no-unused-vars
export const doClearCannedReplyTemplateDraft = (id, options = {}) => {
  return {
    type: CLEAR_CANNED_REPLY_TEMPLATE_DRAFT,
    ...changeEntity('cannedReply', id, {}, 'remove', 'pending'),
  }
}

export const doSaveCannedReplyTemplate = (id, fields, options = {}) => async (
  dispatch,
  getState
) => {
  const entityType = 'cannedReply'
  const { uploadAttachments = [], shouldFetchCannedReplies } = options
  const isUpdate = id !== 'new' && id !== 'newAi'
  const entity = { ...fields, id }
  const hasAttachments = uploadAttachments.length > 0

  const payload = {
    canned_reply: {
      body: fields.body,
      // v0 api needs an integer db id, reverse the scatter swapped id
      category_id: fields.category ? reverseHashInt(fields.category) : null,
      subject: fields.subject,
      title: fields.name,
      mailbox_ids: fields.channelVisibility === 'all' ? [] : fields.mailboxIds,
      attachment_ids:
        fields.attachments?.map(scatterId => reverseHashInt(scatterId)) || [],
      automatic_actions: fields.automatic_actions,
    },
  }

  let saveUrl = 'api/settings/canned_replies'
  let saveMethod = 'POST'
  let targetStores = ['pending', 'current']
  if (isUpdate) {
    // v0 api needs an integer db id, reverse the scatter swapped id
    saveUrl += `/${reverseHashInt(id)}`
    saveMethod = 'PUT'
    targetStores = ['pending']
  }

  // Kevin R (2022-02-24)
  // These options invalidate the search and clears out the pending store once the request completes.
  // The problem we have is that our current process first creates/updated the canned reply, and then uploads
  // the files. While the file is being uploaded, the form infront of the customer looks all wonkey
  // because we've cleared out the pending state. To work around this, we only attach these options
  // to the first request if there are no attachments, otherwise they'll get attached to the
  // attachment upload request which means the pending state is preserved until everything is saved.
  const invalidateClearOptions = {
    searches: {
      additionalActions: [
        {
          type: 'INVALIDATE_ENTITIES',
          entityTypes: ['cannedReplyCategory', 'cannedReply'],
          phases: ['SUCCESS'],
        },
      ],
    },
    moduleOptions: {
      entities: {
        additionalActions: [
          {
            // For creates
            // Because this is a create operation, entity ID is not known until API responds after the POST is made
            // which means entity store API doesn't know to replace the entity in pending/current with API response entity
            // what below does is: Remove cannedReply entity with temp id #new from pending and current stores after create SUCCESS response from API
            // For updates
            // Clear pending entity after successful entity update
            entityType,
            entityId: id,
            stores: targetStores,
            operation: 'remove',
            phases: ['SUCCESS'],
          },
        ],
      },
    },
  }

  const cannedReplyResponse = await dispatch(
    doApiWriteRequest(
      SAVE_CANNED_REPLY_TEMPLATE,
      saveUrl,
      payload,
      mergePlain(
        {
          method: saveMethod,
          throwOnError: true,
          optimist: {
            entities: {
              cannedReply: {
                [id]: entity,
              },
            },
          },
          normalizationSchema: cannedReplyApiV0SettingsResponseSchema,
          onBeforeSuccessAction: () => {
            if (shouldFetchCannedReplies) {
              dispatch(doFetchCannedRepliesV1({}))
            }
          },
          moduleOptions: {
            toasts: {
              enabled: true,
              started: {
                enabled: false,
              },
              success: {
                enabled: true,
                content: isUpdate
                  ? `${app.t('Canned_reply')} changes saved`
                  : `New ${app.t('canned_reply')} created`,
              },
              failed: {
                content: isUpdate
                  ? `${app.t('Canned_reply')} changes failed`
                  : `${app.t('Canned_reply')} creation failed`,
                onClickAction: () => {
                  dispatch(doSaveCannedReplyTemplate(id, fields, options))
                },
              },
            },
          },
        },
        !hasAttachments ? invalidateClearOptions : {}
      )
    )
  )

  if (uploadAttachments.length > 0) {
    const attachmentResourceId = isUpdate
      ? id
      : getRawId(cannedReplyResponse.gid)
    await dispatch(
      doAttachementRequest(
        UPLOAD_CANNED_REPLY_ATTACHMENT,
        `/templates/${attachmentResourceId}/attachments`,
        uploadAttachments.map(upload => upload.editorFile),
        mergePlain(options, invalidateClearOptions)
      )
    )
  }
  if (!isUpdate) {
    const onboardingWorkflowData = selectFeatureBasedOnboardingWorkflowData(
      getState()
    )
    dispatch(
      doTryFetchAccountUsageOnboardingForOnboarding(
        onboardingWorkflowData.canned_reply?.usageKey
      )
    )
  }
  // api call, which will update 'current' store if response is successful
  return cannedReplyResponse
}

const transformReplaceIdWithGid = data => {
  return {
    ...data,
    id: getRawId(data.gid),
  }
}

export const doSaveCannedReplyCategory = (
  id,
  fields,
  options = {}
) => dispatch => {
  // and on here  (i.e. on save button click) you just copy the entity from pending to current store
  const entityType = 'cannedReplyCategory'
  const entity = { ...fields, id }
  const isUpdate = id !== 'new'

  let saveUrl = 'api/settings/canned_reply_categories'
  let saveMethod = 'POST'
  let targetStores = ['pending', 'current']
  if (isUpdate) {
    saveUrl += `/crc_${id}`
    saveMethod = 'PUT'
    targetStores = ['pending']
  }

  // api call
  return dispatch(
    doApiWriteRequest(SAVE_CANNED_REPLY_CATEGORY, saveUrl, fields, {
      method: saveMethod,
      transformResponse: transformReplaceIdWithGid,
      optimist: {
        entities: {
          [entityType]: {
            [id]: entity,
          },
        },
      },
      normalizationSchema: cannedReplyCategory,
      searches: {
        additionalActions: [
          {
            type: 'INVALIDATE_ENTITIES',
            entityTypes: ['cannedReplyCategory', 'cannedReply'],
            phases: ['SUCCESS'],
          },
        ],
      },
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: true,
            content: isUpdate
              ? 'Category changes saved'
              : 'New category created',
          },
          success: {
            enabled: false,
          },
          failed: {
            content: isUpdate
              ? 'Category changes failed'
              : 'Category creation failed',
            onClickAction: () => {
              dispatch(doSaveCannedReplyCategory(id, fields, options))
            },
          },
        },
        entities: {
          additionalActions: [
            {
              entityType,
              entityId: id,
              stores: targetStores,
              operation: 'remove',
              phases: ['SUCCESS'],
            },
          ],
        },
      },
    })
  )
}

export const doCreateCannedReplyDraft = id => ({
  type: CREATE_CANNED_REPLY_CATEGORY_DRAFT,
  ...syncEntity('cannedReplyCategory', id, 'merge', 'current'),
})

function getApiDeletionMode(ids, mode) {
  if (mode === 'ids' && ids.length === 1) {
    return 'single'
  }
  return 'batch'
}

export const doDeleteCannedReplyCategories = (
  deleteCategoryConfig,
  options = {}
) => dispatch => {
  const { ids, mode = 'ids' } = deleteCategoryConfig
  if (mode !== 'ids')
    throw new Error(
      `${mode} is not a supported deletion mode. Only ids is supported`
    )
  return Promise.all(
    ids.map(id =>
      dispatch(
        doApiWriteRequest(
          DELETE_CANNED_REPLIES,
          `api/settings/canned_reply_categories/${buildId('CannedReply', id)}`,
          {},
          {
            method: 'DELETE',
            optimist: {},
            normalizationSchema: cannedReplyCategoryApiV1ResponseSchema,
            searches: {
              additionalActions: [
                {
                  type: 'INVALIDATE_ENTITIES',
                  entityTypes: ['cannedReplyCategory', 'cannedReply'],
                  phases: ['SUCCESS'],
                },
              ],
            },
            moduleOptions: {
              toasts: {
                enabled: true,
                started: {
                  enabled: false,
                },
                success: {
                  enabled: true,
                  content: 'Category deleted',
                },
                failed: {
                  content: 'Category deletion failed',
                  onClickAction: () => {
                    dispatch(
                      doDeleteCannedReplyCategories(
                        deleteCategoryConfig,
                        options
                      )
                    )
                  },
                },
              },
              entities: {
                targetOperation: 'remove',
                additionalActions: [
                  {
                    // Because we're passing through a global id here, we need to manually delete
                    // the entity from the store
                    entityType: 'cannedReplyCategory',
                    entityId: id,
                    stores: ['pending', 'current'],
                    operation: 'remove',
                    phases: ['SUCCESS'],
                  },
                ],
              },
            },
          }
        )
      )
    )
  )
}

const doDeleteSingleCannedReply = (id, options = {}) => dispatch => {
  return dispatch(
    doApiWriteRequest(
      DELETE_CANNED_REPLIES,
      `api/settings/canned_replies/${buildId('CannedReply', id)}`,
      {},
      {
        method: 'DELETE',
        optimist: {},
        normalizationSchema: cannedReplyApiV1ResponseSchema,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['cannedReplyCategory', 'cannedReply'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: `${app.t('Canned_reply')} deleted`,
            },
            success: {
              enabled: false,
            },
            failed: {
              content: `${app.t('Canned_reply')} deletion failed`,
              onClickAction: () => {
                dispatch(doDeleteSingleCannedReply(id, options))
              },
            },
          },
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                // Because we're passing through a global id here, we need to manually delete
                // the entity from the store
                entityType: 'cannedReply',
                entityId: id,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['STARTED'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doDeleteCannedReplies = (
  deleteConfig,
  options = {}
) => dispatch => {
  const { ids, queryId, mode = 'ids' } = deleteConfig
  const apiDeletionMode = getApiDeletionMode(ids, mode)

  if (apiDeletionMode === 'single') {
    // Promise.all is not technically required in the current implementation because
    // we'll only step into this block if the ids array has a single id, but I do
    // want to leave the door open for us to say that deleting less than X rows uses
    // single deletes instead of batch deletes
    return Promise.all(
      ids.map(id =>
        dispatch(doDeleteSingleCannedReply(id, { ...options, queryId }))
      )
    )
  }

  const { search, categoryId } = queryIdToQuery(queryId) || {}
  const params =
    mode === 'ids'
      ? { ids }
      : compact({
          category: categoryId,
          keywords: search,
        })

  const additionalActions =
    mode === 'ids'
      ? ids.map(id => ({
          entityType: 'cannedReply',
          entityId: id,
          stores: ['pending', 'current'],
          operation: 'remove',
          phases: ['STARTED'],
        }))
      : []

  additionalActions.push({
    entityType: 'batchJob',
    entityId: 'new',
    stores: ['current'],
    operation: 'update',
    phases: ['STARTED'],
    entity: {
      id: 'new',
      batch_type: DELETE_MESSAGE_TEMPLATES,
    },
  })

  return dispatch(
    doApiWriteRequest(
      DELETE_CANNED_REPLIES,
      'v1/templates/batch_delete',
      {},
      {
        params,
        optimist: {},
        normalizationSchema: cannedReplyApiV1ResponseSchema,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['cannedReplyCategory', 'cannedReply'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: `Bulk ${app.t('canned_reply')} deletion complete`,
            },
            success: {
              enabled: false,
            },
            failed: {
              content: `Bulk ${app.t('canned_reply')} deletion failed`,
              onClickAction: () => {
                dispatch(doDeleteCannedReplies(deleteConfig, options))
              },
            },
          },
          entities: {
            targetOperation: 'remove',
            additionalActions,
          },
        },
      }
    )
  )
}

export const doChangeCategoryForCannedReplies = (
  changeCategoryConfig,
  options = {}
) => dispatch => {
  const { ids, queryId, mode = 'ids', newCategoryId } = changeCategoryConfig
  const { search, categoryId } = queryIdToQuery(queryId) || {}
  const params =
    mode === 'ids'
      ? { ids, new_category: newCategoryId }
      : compact({
          category: categoryId,
          keywords: search,
          new_category: newCategoryId,
        })

  return dispatch(
    doApiWriteRequest(
      UPDATE_CANNED_REPLY_CATEGORY,
      'v1/templates/batch_update',
      {},
      {
        params,
        optimist: {
          entities: {
            cannedReply: ids.reduce((cannedReplies, id) => {
              // eslint-disable-next-line no-param-reassign
              cannedReplies[id] = { id, category: newCategoryId }
              return cannedReplies
            }, {}),
            batchJob: {
              new: {
                id: 'new',
                batch_type: UPDATE_MESSAGE_TEMPLATES,
              },
            },
          },
        },
        normalizationSchema: cannedReplyApiV1ResponseSchema,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['cannedReplyCategory', 'cannedReply'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: 'Bulk category change complete',
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'Bulk category change failed',
              onClickAction: () => {
                dispatch(
                  doChangeCategoryForCannedReplies(
                    changeCategoryConfig,
                    options
                  )
                )
              },
            },
          },
        },
      }
    )
  )
}

export const doCannedReplyAddPendingAttachment = ({
  cannedReplyId,
  attachmentId,
  editorFile,
}) => (dispatch, getState) => {
  const state = getState()
  const creatorId = selectCurrentUserId(state)
  const { attachments = [] } =
    selectPendingCannedReplyById(state, cannedReplyId) || {}

  const attachment = {
    id: attachmentId,
    attachment_file_name: editorFile.name,
    attachment_file_size: editorFile.size,
    attachment_content_type: editorFile.type,
    token_url: '',
    token_download_url: '',
    s3_key: '',
    creator: creatorId,
  }

  dispatch({
    type: UPDATE_CANNED_REPLY_TEMPLATE_OPTIMISTIC,
    ...mergeEntityChanges([
      changeEntity('attachment', attachmentId, attachment, 'update', 'pending'),
      changeEntity(
        'cannedReply',
        cannedReplyId,
        {
          attachments: [...attachments, attachmentId],
        },
        'update',
        'pending'
      ),
    ]),
  })
}

export const doCannedReplyRemovePendingAttachment = ({
  cannedReplyId,
  attachmentId,
}) => (dispatch, getState) => {
  const state = getState()
  const { attachments = [] } =
    selectPendingCannedReplyById(state, cannedReplyId) || {}

  dispatch({
    type: UPDATE_CANNED_REPLY_TEMPLATE_OPTIMISTIC,
    ...mergeEntityChanges([
      changeEntity(
        'attachment',
        attachmentId,
        { id: attachmentId },
        'remove',
        'pending'
      ),
      changeEntity(
        'cannedReply',
        cannedReplyId,
        {
          attachments: attachments.filter(id => id !== attachmentId),
        },
        'update',
        'pending'
      ),
    ]),
  })
}

const fetchCannedReplyTagsById = actions => (dispatch, getState) => {
  const state = getState()
  const ids = new Set()

  // eslint-disable-next-line no-unused-expressions
  actions?.forEach(({ type, value } = {}) => {
    if (['labels_by_id', 'label_remove'].includes(type)) {
      if (value) {
        value.split(',').forEach(id => ids.add(id))
      }
    }
  })

  if (ids.size === 0) {
    return Promise.resolve()
  }
  const tagIdsToFetch = Array.from(ids).filter(tagId => {
    return !selectCurrentTagById(state, tagId)?.id
  })
  if (tagIdsToFetch.length === 0) {
    return Promise.resolve()
  }
  return dispatch(
    doFetchTagsV2ByIds({
      ids: tagIdsToFetch,
      pageSize: tagIdsToFetch.length,
    })
  )
}

const getAutomationTagActions = (rawActions, getState) => {
  const state = getState()
  const allRemoved = rawActions.some(a => a.type === 'label_remove_all')
  const added = rawActions
    .find(a => a.type === 'labels_by_id')
    ?.value?.split(',')
    .map(t => selectCurrentTagById(state, t))
    .filter(tag => !!tag)
  const removed = allRemoved
    ? []
    : rawActions
        .find(a => a.type === 'label_remove')
        ?.value?.split(',')
        .map(t => selectCurrentTagById(state, t))
        .filter(tag => !!tag)

  return {
    added,
    removed,
    allRemoved,
  }
}

const getAutomaticActionsWithLabel = (actions, getState) => {
  const state = getState()
  return actions.map(({ value, type }) => {
    let convertedValue
    switch (type) {
      case 'status':
      case 'snooze_until': {
        convertedValue = cannedReplyActions.values[type]?.options?.find(
          o => o.value === value
        )?.name
        break
      }
      case 'assignee_id': {
        convertedValue = selectAgentById(state, hash(value))?.name
        break
      }
      case 'assignee_group_id': {
        convertedValue = selectGroupForId(state, hash(value))?.name
        break
      }
      default: {
        convertedValue = value
      }
    }
    return {
      type,
      label: convertedValue,
    }
  })
}

export const getCannedReplyPreview = reply => {
  return async (dispatch, getState) => {
    const tagsProps = ['label_remove_all', 'labels_by_id', 'label_remove']
    const state = getState()
    const ticketId = selectCurrentTicketId(state)
    let replyWithBody = selectCurrentCannedReplyById(state, reply.id)
    const { automaticActions = [] } = reply

    const tagRawActions = []
    const rawActions = []

    automaticActions.forEach(action => {
      if (tagsProps.includes(action.type)) {
        tagRawActions.push(action)
      } else {
        rawActions.push(action)
      }
    })

    await Promise.all([
      !replyWithBody?.body &&
        dispatch(
          doFetchCannedReplyByIdV0Settings(buildId('CannedReply', reply.id))
        ),
      dispatch(fetchCannedReplyTagsById(tagRawActions)),
    ])

    replyWithBody = replyWithBody?.body
      ? replyWithBody
      : selectCurrentCannedReplyById(getState(), reply.id)

    const overrides = {}
    const draftType = 'reply'
    let draft = selectDraftByTicketId(state, ticketId, draftType)
    if (!draft) {
      await handleDraftChange(dispatch, draftType, undefined, ticketId, null, {
        touched: true,
      })
      draft = selectDraftByTicketId(getState(), ticketId, draftType)
    }
    const templateSubjectUsed = ticketId === 'new' && !draft?.title
    const interpolateCannedReply = selectInterpolateCannedReply(getState())
    if (reply.subject) {
      overrides.title = interpolateCannedReply(reply.subject)
    }

    return {
      id: interpolateCannedReply(reply.id),
      subject: interpolateCannedReply(overrides.title, overrides),
      body: interpolateCannedReply(replyWithBody?.body, overrides),
      tagActions: getAutomationTagActions(tagRawActions, getState),
      actions: getAutomaticActionsWithLabel(rawActions, getState),
      meta: {
        templateSubjectUsed,
      },
    }
  }
}

export function doToggleCannedReplyDropdown(isVisible, options = {}) {
  const { source = null, cursorBookmark = null } = options

  return {
    type: TOGGLE_CANNED_REPLY_DROPDOWN,
    payload: {
      isVisible,
      source,
      cursorBookmark,
    },
  }
}
