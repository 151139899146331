import { v4 as uuidV4 } from 'uuid'
import { CREATE_DRAFT } from 'ducks/drafts2/constants'
import { buildSharedDraftActionMetadata } from 'ducks/drafts2/util'
import { doSendDraftToServer } from './doSendDraftToServer'
import { selectDraftDefaultByTicketId } from '../selectors'

export const doCreateDraft = (ticketId, draftType, draftFields) => (
  dispatch,
  getState
) => {
  const state = getState()
  const draftDefaults = selectDraftDefaultByTicketId(state, ticketId, draftType)

  const fields = {
    ...draftDefaults,
    ...draftFields,
    draftType,
  }
  const isOnlyUpdatingRecipients =
    'to' in draftFields && Object.keys(draftFields).length === 1

  if (draftDefaults) {
    fields.defaultsSet = true
    fields.defaultsSetOnCreate = true
  }
  // catch a case when defaults don't set proper "to"
  // except when the "to" field is emptied by the user
  if (
    !isOnlyUpdatingRecipients &&
    fields.draftType === 'reply' &&
    (!fields.to || fields.to.length === 0)
  ) {
    delete fields.defaultsSet
    delete fields.defaultsSetOnCreate
    fields.defaultsAbortedOnCreate = true
  }
  const draftId = uuidV4()

  dispatch({
    type: CREATE_DRAFT,
    payload: { draftId, ticketId, draftType, fields },
    meta: {
      ...buildSharedDraftActionMetadata(state),
    },
  })
  return dispatch(doSendDraftToServer(draftId, ticketId, draftType))
}
