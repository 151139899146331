import { createSelector } from 'reselect'
import { selectDemoMailboxId } from './selectDemoMailboxId'
import { selectMailboxIds } from './selectMailboxIds'

export const selectCanConvertDemoMailboxToNewMailbox = createSelector(
  selectMailboxIds,
  selectDemoMailboxId,
  (allMailboxIds, demoMailboxId) => {
    const allMailboxesCount = (allMailboxIds || []).length

    return !!demoMailboxId && allMailboxesCount === 1
  }
)
