/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { buildId, buildIdFromAny, getRawId } from 'util/globalId'
import {
  queryIdToQuery,
  constructFolderItemQueryId,
  queryStringToQueryId,
} from 'ducks/searches/utils/query'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'
import { CHAT_PAGE, SOCIAL_PAGE } from 'subapps/chat/pages'
import { SEARCH_PAGE, TICKET_PAGE, TICKETS_PAGE } from 'constants/pages'
import { PAID_AGENT_ROLES } from 'ducks/agents/constants'
import { isAgentActive, isRoleOneOf } from 'ducks/agents/utils'
import { getShortAgentName, isAdmin } from 'util/agents'
import { NEWEST } from 'constants/defaults'
import {
  RESTRICTION_TYPE_EVERYONE,
  RESTRICTION_TYPE_GROUP,
  RESTRICTION_TYPE_USERS,
} from 'ducks/teams/constants'
import {
  BUILD_FROM_WIDGETS,
  BUILD_INBOX_MENU_FROM_MAILBOXES,
} from '../actionTypes/collections'
import { SET_ITEMS_BYID } from '../actionTypes/items'
import { mapChannelTypeToPageType } from '../utils'
import { AGENT_GROUP_ID, FOLDER_STATE } from '../constants'

const itemsByIdInitialState = {}
const folderKindsWithUnreadIndicator = ['unassigned', 'mine']

const updateOrderBy = (draftState, action) => {
  const {
    meta: {
      location: {
        current: { query: { orderBy } = {} } = {},
        prev: { query: { orderBy: previousOrderBy } = {} } = {},
      } = {},
    } = {},
  } =
    action || {}

  if (!orderBy || orderBy === previousOrderBy) return draftState

  Object.keys(draftState).forEach(id => {
    if (draftState[id]?.linkTo?.meta?.query?.orderBy) {
      draftState[id].linkTo.meta.query.orderBy = orderBy

      draftState[id].queryId = queryStringToQueryId(
        draftState[id].linkTo.meta.query
      )
    }
  })

  return draftState
}

export const itemsById = createActionTypeReducer(
  {
    [SET_ITEMS_BYID]: (_, { payload: { items = [] } }) => {
      return items.reduce((itemById, item) => {
        itemById[item.id] = item
        return itemById
      }, {})
    },
    [BUILD_FROM_WIDGETS]: (
      draftState,
      {
        payload: {
          widgets = [],
          folders: inputFolders = [],
          prefersAllMailboxesSectionVisible = false,
          prefersUnifiedInbox = false,
          channelType: pageChannelType,
          orderBy,
        },
      }
    ) => {
      const folders = inputFolders.filter(
        f => f.state !== FOLDER_STATE.INACTIVE
      )
      const validItems = []
      const hasAccessToAtleastOneWidget = widgets.some(w => w.hasAccess)

      const allowedWidgets = prefersUnifiedInbox
        ? []
        : [...widgets.filter(w => w.hasAccess)]
      const allowedWidgetsByPageType = allowedWidgets.filter(
        w => w.channelType === pageChannelType
      )

      if (
        hasAccessToAtleastOneWidget &&
        (prefersUnifiedInbox ||
          (prefersAllMailboxesSectionVisible &&
            allowedWidgetsByPageType.length > 1))
      ) {
        allowedWidgets.unshift({
          id: '',
          name: pageChannelType === 'widget' ? 'All widgets' : 'All social',
          hasAccess: true,
          channelType: pageChannelType,
        })
      }

      allowedWidgets.forEach(widget => {
        const { channelType } = widget
        const rawWidgetId = widget.id !== '' ? widget.id : null

        const widgetId = buildId('Widget', rawWidgetId) || ''
        folders.forEach(folder => {
          const {
            id: folderId,
            position,
            displayCountWhenInactive,
            hideIfZeroConversations,
            name,
          } = folder
          const itemId = `${widgetId}_${folderId}`
          validItems.push(itemId)
          const itemQueryId = constructFolderItemQueryId({
            channel: widget,
            folder,
            orderBy,
          })
          const finalChannelType = channelType || pageChannelType
          if (
            !draftState[itemId] &&
            // FIXME: Until we have chat folder permissions, hide facebook specific folders
            // for non-facebook widgets
            (!['New messages', 'Ending soon'].includes(name) ||
              isBridgeChannelType(finalChannelType))
          ) {
            draftState[itemId] = {
              id: itemId,
              resourceId: folderId,
              name,
              groupId: null,
              collectionId: widgetId,
              position,
              displayCountWhenInactive,
              displayUnreadIndicator: folderKindsWithUnreadIndicator.includes(
                folder.kind
              ),
              hideIfZeroConversations,
              queryId: itemQueryId,
              kind: folder.kind,
              highlight: false,
              showMoreOptions: false,
              warning: false,
              linkTo: {
                type: mapChannelTypeToPageType(channelType),
                payload: {},
                meta: {
                  query: queryIdToQuery(itemQueryId),
                },
              },
            }
            draftState[itemId].position = position
          }
        })
      })
      Object.keys(draftState).forEach(itemId => {
        if (!validItems.includes(itemId)) {
          delete draftState[itemId]
        }
      })
      return draftState
    },
    [BUILD_INBOX_MENU_FROM_MAILBOXES]: (
      draftState,
      {
        payload: {
          mailboxes = [],
          folders: inputFolders = [],
          prefersAllMailboxesSectionVisible = false,
          prefersUnifiedInbox = false,
          channelType: pageChannelType,
          agents: inputAgents,
          currentUser,
          teamsById,
          channelType,
        },
      }
    ) => {
      const folders = inputFolders.filter(
        f => f.state !== FOLDER_STATE.INACTIVE && f.hasAccess
      )
      const validItems = []
      // Note the BUILD_INBOX_MENU_FROM_MAILBOXES pre-filters the mailboxes
      // to accessible mailboxes only
      const hasAccessToAtleastOneChannel = mailboxes.length > 0

      const allowedChannels = prefersUnifiedInbox ? [] : [...mailboxes]

      if (
        hasAccessToAtleastOneChannel &&
        (prefersUnifiedInbox ||
          (prefersAllMailboxesSectionVisible && allowedChannels.length > 1))
      ) {
        allowedChannels.unshift({
          id: '',
          gid: '',
          name: `All ${app.t('Mailboxes')}`,
          hasAccess: true,
          channelType: pageChannelType,
          restriction_type: RESTRICTION_TYPE_EVERYONE,
        })
      }

      const activeAgents = (inputAgents || [])
        .filter(isAgentActive)
        .filter(a => isRoleOneOf(a, PAID_AGENT_ROLES))
        .map(agent => {
          const shortName = getShortAgentName(inputAgents, agent, {
            disableMutate: true,
          })
          return {
            ...agent,
            shortName,
          }
        })

      allowedChannels.forEach(channel => {
        const {
          id: rawChannelId,
          gid: channelId,
          restriction_type: restrictionType,
          user_ids: userIds,
          group_ids: groupIds,
        } = channel
        folders.forEach((folder, index) => {
          const {
            id: folderId,
            displayCountWhenInactive,
            hideIfZeroConversations,
            name,
            channelVisibility,
            channels: folderAllowedChannels,
          } = folder

          // Note there is a know bug here where the agents with the "agent" role
          // might have folders under the All Inboxes section which has been filtered
          // to specific mailboxes. This happens because inside calculateFolderAccess we
          // check the number of channels linked to a folder vs the total number of channels
          // to determine channel visibility. The problem is that for agent role agents, we
          // limit the channels they can load to accessible channels causing this check to fail.
          // We'll need to look at implementing somthing similar to the RestrictionType on folders
          // so that we can query channel visibility from the backend.
          if (
            rawChannelId !== '' &&
            (channelVisibility === 'selected' &&
              !folderAllowedChannels.includes(rawChannelId))
          )
            return

          const currentSortOrder = index + 1
          const itemId = `${channelId}_${folderId}`
          validItems.push(itemId)
          const itemQueryId = constructFolderItemQueryId({
            channel: {
              ...channel,
              // On mailboxes channel type represents forwarding and other internal types
              // For the purposes of folders we justneed to know that its a mailbox
              channelType: pageChannelType,
            },
            folder,
          })
          draftState[itemId] = {
            id: itemId,
            resourceId: folderId,
            name,
            groupId: null,
            collectionId: channelId,
            position: currentSortOrder,
            displayCountWhenInactive,
            displayUnreadIndicator: false,
            hideIfZeroConversations,
            queryId: itemQueryId,
            kind: folder.kind,
            highlight: false,
            showMoreOptions: isAdmin(currentUser),
            warning: false,
            linkTo: {
              type: mapChannelTypeToPageType(channelType),
              payload: {},
              meta: {
                query: queryIdToQuery(itemQueryId),
              },
            },
          }
        })

        const agents = activeAgents.filter(a => {
          return (
            restrictionType === RESTRICTION_TYPE_EVERYONE ||
            (restrictionType === RESTRICTION_TYPE_USERS &&
              userIds.includes(buildIdFromAny('Agent', a.id))) ||
            (restrictionType === RESTRICTION_TYPE_GROUP &&
              groupIds.some(gid => {
                const teamId = getRawId(gid)
                return teamsById[teamId]?.agents?.some(ao => ao.id === a.id)
              }))
          )
        })
        let agentsCurrentSortOrder = 0
        const agentGroupId = `${channelId}_${AGENT_GROUP_ID}`
        agents.forEach((agent, index) => {
          const { gid: agentId, shortName } = agent
          agentsCurrentSortOrder = index + 1
          const itemId = `${channelId}_${agentId}`
          validItems.push(itemId)
          const itemQueryId = constructFolderItemQueryId({
            channel: {
              ...channel,
              // On mailboxes channel type represents forwarding and other internal types
              // For the purposes of folders we justneed to know that its a mailbox
              channelType: pageChannelType,
            },
            assignee: agent,
            state: 'open',
            orderBy: NEWEST,
          })
          draftState[itemId] = {
            id: itemId,
            resourceId: agentId,
            name: shortName,
            groupId: agentGroupId,
            collectionId: channelId,
            position: agentsCurrentSortOrder,
            displayCountWhenInactive: true,
            displayUnreadIndicator: false,
            hideIfZeroConversations: false,
            queryId: itemQueryId,
            kind: null,
            highlight: false,
            showMoreOptions: false,
            warning: false,
            linkTo: {
              type: mapChannelTypeToPageType(channelType),
              payload: {},
              meta: {
                query: queryIdToQuery(itemQueryId),
              },
            },
          }
        })

        if (isAdmin(currentUser)) {
          const addTeammateItemId = `${channelId}_new`
          validItems.push(addTeammateItemId)
          draftState[addTeammateItemId] = {
            id: addTeammateItemId,
            resourceId: 'new',
            name: `+ Add ${app.t('agents')}`,
            groupId: agentGroupId,
            collectionId: channelId,
            position: agentsCurrentSortOrder + 1,
            displayCountWhenInactive: false,
            displayUnreadIndicator: false,
            hideIfZeroConversations: false,
            queryId: null,
            kind: null,
            highlight: agents.length <= 1,
            showMoreOptions: false,
            warning: false,
            linkTo: {
              type: mapChannelTypeToPageType(channelType),
              payload: {},
              meta: {
                query: {
                  'drawer-99': 'agent-add',
                  'drawerResourceId-99': 'new',
                },
              },
            },
          }
        }
      })
      Object.keys(draftState).forEach(itemId => {
        if (!validItems.includes(itemId)) {
          delete draftState[itemId]
        }
      })
      return draftState
    },
    [CHAT_PAGE]: updateOrderBy,
    [SOCIAL_PAGE]: updateOrderBy,
    [SEARCH_PAGE]: updateOrderBy,
    [TICKETS_PAGE]: updateOrderBy,
    [TICKET_PAGE]: updateOrderBy,
  },
  itemsByIdInitialState
)
