import { createBasicSelector } from 'util/redux'
import { isDefined } from 'util/nullOrUndefinedChecks'
import { selectBillingUsageForFeature } from './selectBillingUsageForFeature'
import { selectLocalUsageForFeature } from './selectLocalUsageForFeature'

export const selectUsageForFeature = createBasicSelector(
  selectBillingUsageForFeature,
  selectLocalUsageForFeature,
  (billingUsageAmount, localUsageAmount) => {
    // using local data first because we do not re-fetch billing usage after features have been added/deleted
    // this will be changing in the feature
    // would advice we send a realtime event when billing usage changes
    return isDefined(localUsageAmount) ? localUsageAmount : billingUsageAmount
  }
)
