import { createBasicSelector } from 'util/redux'
import { selectFetchAppBootstrapRequest } from './selectFetchAppBootstrapRequest'
import { selectLegacyIsInboxBootstrapped } from './selectLegacyIsInboxBootstrapped'

export const selectIsInboxBootstrapped = createBasicSelector(
  selectLegacyIsInboxBootstrapped,
  selectFetchAppBootstrapRequest,
  (legacyIsInboxBootstrapped, appBootstrapRequest) =>
    legacyIsInboxBootstrapped && appBootstrapRequest.loaded
)
