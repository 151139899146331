import customer from './customer'
import openState from './openState'
import changeUser from './changeUser'

export default {
  customer,
  openState,
  changeUser,
}

export * as customer from './customer'
export * as openState from './openState'
export * as changeUser from './changeUser'
