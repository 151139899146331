export const PIN_SEARCH = 'pinnedSearch/PIN_SEARCH'
export const PIN_SEARCH_REQUEST = 'pinnedSearch/PIN_SEARCH_REQUEST'
export const PIN_SEARCH_SUCCESS = 'pinnedSearch/PIN_SEARCH_SUCCESS'
export const PIN_SEARCH_FAILED = 'pinnedSearch/PIN_SEARCH_FAILED'

export const UNPIN_SEARCH = 'pinnedSearch/UNPIN_SEARCH'
export const UNPIN_SEARCH_REQUEST = 'pinnedSearch/UNPIN_SEARCH_REQUEST'
export const UNPIN_SEARCH_SUCCESS = 'pinnedSearch/UNPIN_SEARCH_SUCCESS'
export const UNPIN_SEARCH_FAILED = 'pinnedSearch/UNPIN_SEARCH_FAILED'

export const FETCH_MISSING_TAGS = 'pinnedSearch/FETCH_MISSING_TAGS'
export const FETCH_MISSING_TAGS_REQUEST =
  'pinnedSearch/FETCH_MISSING_TAGS_REQUEST'
export const FETCH_MISSING_TAGS_SUCCESS =
  'pinnedSearch/FETCH_MISSING_TAGS_SUCCESS'
export const FETCH_MISSING_TAGS_FAILED =
  'pinnedSearch/FETCH_MISSING_TAGS_FAILED'
