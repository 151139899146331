import { createBasicSelector } from 'util/redux'
import { emptyObj } from 'util/objects'
import { createSelector } from 'reselect'

function selectBase(state) {
  return state.merging || emptyObj
}

export const selectMergingMergeDirection = createBasicSelector(
  selectBase,
  base => base.direction
)

export const selectMergingSearchQueryId = createBasicSelector(
  selectBase,
  base => base.queryId
)

export const selectMergingSearchTerm = createSelector(
  selectBase,
  base => base.searchTerm
)

export const selectMergingMergeTicket = createBasicSelector(
  selectBase,
  base => base.mergeTicket
)

export const selectMergingMergeTicketId = createBasicSelector(
  selectMergingMergeTicket,
  mergeTicket => mergeTicket.id
)

export const selectMergingMergeTicketTitle = createBasicSelector(
  selectMergingMergeTicket,
  mergeTicket => mergeTicket.title
)

export const selectMergingIsMerging = createBasicSelector(
  selectBase,
  base => base.isMerging
)
