/* eslint-disable no-param-reassign */
import { RAW_EMAIL_PAGE } from 'constants/pages'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { camelize } from 'util/strings'
import { FETCH_CONVERSATION_RAW_EMAIL } from './actionTypes'
import { conversationRawEmailGraphQlResponseSchema } from './schema'

import { buildConversatioRawEmailRequestKey } from './utils'
import { ticketRawEmailQuery } from './queries'

export function doFetchRawEmail(rawEmailId, options = {}) {
  return async dispatch => {
    if (!rawEmailId) return null

    return dispatch(
      doGraphqlRequest(
        FETCH_CONVERSATION_RAW_EMAIL,
        ticketRawEmailQuery(),
        {
          rawEmailId,
        },
        {
          ...options,
          app: true,
          normalizationSchema: conversationRawEmailGraphQlResponseSchema,
          meta: {
            requestKey: camelize(
              buildConversatioRawEmailRequestKey(rawEmailId)
            ),
          },
          throwOnError: true,
        }
      )
    )
  }
}

export const doOpenRawEmailPage = rawEmailId => {
  return {
    type: RAW_EMAIL_PAGE,
    payload: {
      id: rawEmailId,
    },
  }
}
