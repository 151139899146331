import { isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import { FETCH_CONTACTS_SUCCESS } from '../types'

export default function reduceLatestLoadedRequestKey(
  state = null,
  { type, meta: { requestKey } = {} }
) {
  if (isNullOrUndefined(requestKey)) return state

  if (type === FETCH_CONTACTS_SUCCESS) return requestKey
  return state
}
