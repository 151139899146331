import { createSelector } from 'reselect'
import { selectCurrentMailbox } from './selectCurrentMailbox'
import { CHANNEL_STATE } from '../constants'

export const selectIsCurrentDemoMailbox = createSelector(
  selectCurrentMailbox,
  mailbox => {
    if (!mailbox) return null
    return mailbox.state === CHANNEL_STATE.DEMO
  }
)
