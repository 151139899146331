import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequestByType } from 'ducks/requests/selectors'
import { selectCurrentEntityById, selectPendingEntityById } from '../selectors'

export default function useFetchEntityById(
  id,
  entityType,
  fetchActionCreator,
  fetchActionType,
  targetStore = 'current'
) {
  const dispatch = useDispatch()

  const load = useCallback(
    () => {
      if (id && id !== 'new') {
        dispatch(fetchActionCreator({ ids: [id], id }))
      }
    },
    [dispatch, fetchActionCreator, id]
  )

  useEffect(
    () => {
      load()
    },
    [fetchActionCreator, id, load]
  )

  const entity = useSelector(
    state =>
      targetStore === 'current'
        ? selectCurrentEntityById(state, entityType, id)
        : selectPendingEntityById(state, entityType, id)
  )

  const requestState = useSelector(state =>
    selectRequestByType(state, fetchActionType)
  )

  return {
    entity,
    requestState,
    reload: load,
  }
}
