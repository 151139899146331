import { getRawId } from 'util/globalId'
import { CHAT_PAGE, SOCIAL_PAGE } from 'subapps/chat/pages'
import { TICKETS_PAGE } from 'constants/pages'
import {
  MAILBOX_CHANNEL_TYPE,
  MAILBOX_GQL_TYPE,
  SOCIAL_CHANNEL_TYPE,
  WIDGET_CHANNEL_TYPE,
  WIDGET_GQL_TYPE,
} from '../constants'

export function filterByActiveAndMailboxId(mailboxId) {
  return folder => {
    return (
      folder.state === 'ACTIVE' &&
      Object.values(folder.channels).some(
        ({ id: channelId }) => getRawId(channelId) === getRawId(mailboxId)
      )
    )
  }
}

export function mapChannelTypeToPageType(channelType) {
  if (channelType === MAILBOX_CHANNEL_TYPE) {
    return TICKETS_PAGE
  }
  if (channelType === SOCIAL_CHANNEL_TYPE) {
    return SOCIAL_PAGE
  }
  return CHAT_PAGE
}

export function mapChannelTypeToGlobalIdType(channelType) {
  if (channelType === MAILBOX_CHANNEL_TYPE) {
    return MAILBOX_GQL_TYPE
  }
  return WIDGET_GQL_TYPE
}

export function mapPageTypeToChannelType(pageType) {
  if (pageType === TICKETS_PAGE) {
    return MAILBOX_CHANNEL_TYPE
  }
  if (pageType === SOCIAL_PAGE) {
    return SOCIAL_CHANNEL_TYPE
  }
  return WIDGET_CHANNEL_TYPE
}
