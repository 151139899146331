import { createSelector } from 'reselect'
import { selectSearchTotalCountForCurrentQueryId } from 'ducks/searches/selectors'

/**
 * @returns {boolean|undefined} undefined if searches request not loaded yet, boolean if searches request has completed
 */

export const selectCurrentMailboxHasTickets = createSelector(
  selectSearchTotalCountForCurrentQueryId,
  countForCurrentQuery => {
    return countForCurrentQuery > 0
  }
)
