export const ticketRawEmailQuery = () => `
query TicketRawEmail($rawEmailId: ID!) {
  node(id: $rawEmailId) {
    ... on RawEmail {
      id
      headers {
        name
        value
      }
      bodyPlainText
      body
      createdAt
    }
  }
}
`
