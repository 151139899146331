import { channel } from 'ducks/entities/schema'

export const mailboxGraphQlResponseSchema = {
  channels: {
    edges: [{ node: channel }],
  },
}

export const mailboxUpdateGraphQlResponseSchema = {
  channelEmailUpdate: {
    channel,
  },
}

export const mailboxDeleteGraphQlResponseSchema = {
  mailbox: channel,
}
