import grooveAPI from 'api/groove'
import { BEGIN, COMMIT, REVERT } from 'redux-optimist'
import { v4 as uuidV4 } from 'uuid'
import { oauthTokenSelector } from 'selectors/app'
import {
  doGraphqlRequest,
  doApiReadRequest,
  doRequest,
  doApiPutRequest,
  doAppGraphqlRequest,
} from 'ducks/requests/operations'
import { looselyEquals, omit, pick } from 'util/objects'
import {
  agentPreferences as agentPreferencesEntity,
  agentNotificationPreferences as agentNotificationPreferencesEntity,
} from 'ducks/entities/schema'
import {
  selectCurrentAgentNotificationPreferences,
  selectCurrentAgentNotificationPreferencesScope,
} from 'ducks/currentUser/selectors/notificationPreferences'
import { isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import {
  UPDATE_CURRENT_USER,
  UPDATE_USER_PREFERENCES_REQUEST,
  UPDATE_USER_PREFERENCES_SUCCESS,
  UPDATE_USER_PREFERENCES_FAIL,
  RESEND_CONFIRMATION_TOKEN,
  UPLOAD_CURRENT_USER_AVATAR,
  UPDATE_NOTIFICATION_PREFERENCES,
  FETCH_PREFERENCES,
  UPDATE_PREFERENCES,
  FETCH_DEFAULT_NOTIFICATION_PREFERENCES,
  FETCH_NOTIFICATION_PREFERENCES_STARTED,
  FETCH_NOTIFICATION_PREFERENCES_SUCCESS,
  FETCH_NOTIFICATION_PREFERENCES_PAGE,
  FETCH_TWO_FACTOR_AUTHENTICATION_CODE,
} from '../types'
import {
  updateCurrentUserSignupMutation,
  confirmCurrentUserMutation,
  updateCurrentUserMutation,
  changePasswordMutation,
} from '../api'
import {
  currentUserNotificationPreferencesQuery,
  defaultNotificationPreferencesQuery,
  getTwoFactorAuthenticationCodeQuery,
} from './queries'
import { bulkUpsertAndDeleteMutation, bulkUpsertMutation } from './mutations'
import {
  agentNotificationPreferencesNormalizationSchema,
  agentNotificationPreferencesUpsertResponseSchema,
  defaultNotificationPreferencesNormalizationSchema,
} from './schema'
import { selectCurrentUser } from '../selectors/selectCurrentUser'

export const doUploadAvatar = file => dispatch => {
  const body = new FormData()
  body.append('current_user[avatar]', file)

  return dispatch(
    doRequest(UPLOAD_CURRENT_USER_AVATAR, async ({ getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)
      const { json } = await grooveAPI.send(
        'post',
        token,
        'v1/me/avatar',
        null,
        body,
        {},
        { skipContentTypeHeader: true }
      )
      return json
    })
  )
}

export const doUpdateCurrentUserForOnboarding = (
  fields,
  options = {}
) => async dispatch => {
  return dispatch(
    doGraphqlRequest(
      UPDATE_CURRENT_USER,
      updateCurrentUserSignupMutation,
      { input: { ...fields } },
      {
        throwOnError: true,
        ...options,
      }
    )
  )
}

export const doChangePassword = (fields, options = {}) => async dispatch => {
  const { currentPassword, password, passwordConfirmation } = fields
  return dispatch(
    doGraphqlRequest(
      UPDATE_CURRENT_USER,
      changePasswordMutation,
      { input: { currentPassword, password, passwordConfirmation } },
      options
    )
  )
}

export const doUpdateCurrentUser = (fields, options = {}) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const currentUser = selectCurrentUser(state)
  const avatarField = fields.avatar
  // NOTE (jscheel): If the field is and empty string, we still call
  // doUploadAvatar to let it remove the user's avatar. However, we do not try
  // to upload the avatar otherwise. Unfortunately, we are all over the map with
  // avatar urls right now, so this tries to be smart about updating the avatar.
  if (avatarField || avatarField === '') {
    let currentAvatar = currentUser.avatar_url || ''
    if (currentAvatar.match('missing.png')) {
      currentAvatar = ''
    }
    if (avatarField !== currentAvatar) {
      await dispatch(doUploadAvatar(avatarField))
    }
  }

  // HACK (jscheel): This sucks to do these in series, but until we work out our
  // preference and current user models, and how gql should do nested updates, this
  // is the fastest way to take care of these changes. Definitely tech debt.
  if (
    fields.preferences &&
    !looselyEquals(fields.preferences, currentUser.preferences)
  ) {
    await dispatch(doUpdateUserPreferences(fields.preferences))
  }

  return dispatch(
    doGraphqlRequest(
      UPDATE_CURRENT_USER,
      updateCurrentUserMutation,
      { input: { ...omit(['avatar', 'preferences'], fields) } },
      options
    )
  )
}

export function doUpdateUserPreferences(prefs) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const tid = uuidV4()
    const serializedData = JSON.stringify({ agent: prefs })
    dispatch({
      type: UPDATE_USER_PREFERENCES_REQUEST,
      payload: { preferences: prefs },
      optimist: { type: BEGIN, id: tid },
    })
    return grooveAPI
      .put(token, `/v1/preferences`, null, serializedData)
      .then(() => {
        dispatch({
          type: UPDATE_USER_PREFERENCES_SUCCESS,
          optimist: { type: COMMIT, id: tid },
        })
      })
      .catch(error => {
        dispatch({
          type: UPDATE_USER_PREFERENCES_FAIL,
          payload: { error },
          optimist: { type: REVERT, id: tid },
        })
      })
  }
}

export const doConfirmCurrentUser = confirmationToken => {
  return dispatch => {
    return dispatch(
      doGraphqlRequest(
        UPDATE_CURRENT_USER,
        confirmCurrentUserMutation,
        { input: { confirmationToken } },
        { throwOnError: true }
      )
    )
  }
}

export const doResendConfirmationToken = () => {
  return dispatch => {
    return dispatch(
      doApiReadRequest(
        RESEND_CONFIRMATION_TOKEN,
        'v1/me/resend_confirmation_token'
      )
    )
  }
}

export const doFetchDefaultNotificationPreferences = (
  options = {}
) => dispatch => {
  const { targetStore } = options

  return dispatch(
    doAppGraphqlRequest(
      FETCH_DEFAULT_NOTIFICATION_PREFERENCES,
      defaultNotificationPreferencesQuery,
      {},
      {
        normalizationSchema: defaultNotificationPreferencesNormalizationSchema,
        moduleOptions: {
          entities: {
            targetStore,
          },
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'Loading notification preferences failed',
              onClickAction: () =>
                dispatch(doFetchDefaultNotificationPreferences(options)),
            },
          },
        },
        ...options,
      }
    )
  )
}

export const doFetchNotificationPreferences = (
  options = {}
) => async dispatch => {
  const { targetStore } = options

  let hasNextPage
  let after

  dispatch({ type: FETCH_NOTIFICATION_PREFERENCES_STARTED })
  do {
    const variables = {
      after,
    }
    // eslint-disable-next-line no-await-in-loop
    const result = await dispatch(
      doAppGraphqlRequest(
        FETCH_NOTIFICATION_PREFERENCES_PAGE,
        currentUserNotificationPreferencesQuery,
        variables,
        {
          normalizationSchema: agentNotificationPreferencesNormalizationSchema,
          moduleOptions: {
            entities: {
              targetStore,
            },
            toasts: {
              enabled: true,
              started: {
                enabled: false,
              },
              success: {
                enabled: false,
              },
              failed: {
                content: 'Loading notification preferences failed',
                onClickAction: () =>
                  dispatch(doFetchNotificationPreferences(options)),
              },
            },
          },
          ...options,
        }
      )
    )
    const pageInfo = result.me.notificationPreferences.pageInfo
    hasNextPage = pageInfo.hasNextPage
    after = pageInfo.endCursor
  } while (hasNextPage)

  dispatch({ type: FETCH_NOTIFICATION_PREFERENCES_SUCCESS })
  return null
}

export const doUpdateNotificationPreferences = (fields, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()

  const currentPreferences = selectCurrentAgentNotificationPreferences(state)
  const currentScope = selectCurrentAgentNotificationPreferencesScope(state)

  const { scope, preferences } = fields

  const deleteIds = []
  const additionalActions = []

  if (currentScope !== scope) {
    // scope change, nuke old settings
    currentPreferences.forEach(preference => {
      if (preference?.id) {
        deleteIds.push(preference.id)
      }

      if (preference?.[agentNotificationPreferencesEntity.idAttribute]) {
        additionalActions.push({
          entityType: agentNotificationPreferencesEntity.key,
          entityId: preference[agentNotificationPreferencesEntity.idAttribute],
          stores: ['current'],
          operation: 'remove',
          phases: ['SUCCESS'],
        })
      }
    })
  }

  const upsertPreferences = Object.values(preferences)
    .flat()
    .filter(p => p.dirty === true || isNullOrUndefined(p.id))
    .map(preference => pick(['id', 'key', 'namespace', 'value'], preference))

  const variables = {
    upsertPreferences,
    deleteIds,
  }

  const queryOrMutation =
    deleteIds.length > 0 ? bulkUpsertAndDeleteMutation : bulkUpsertMutation

  return dispatch(
    doAppGraphqlRequest(
      UPDATE_NOTIFICATION_PREFERENCES,
      queryOrMutation,
      variables,
      {
        normalizationSchema: agentNotificationPreferencesUpsertResponseSchema,
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Notification settings saved',
            },
            failed: {
              content: 'Notification settings failed',
              onClickAction: () =>
                dispatch(doUpdateNotificationPreferences(fields, options)),
            },
          },
          entities: {
            additionalActions,
          },
        },
      }
    )
  )
}

export const doFetchPreferences = (options = {}) => dispatch => {
  return dispatch(
    doApiReadRequest(
      FETCH_PREFERENCES,
      '/api/settings/preferences',
      {},
      {
        normalizationSchema: agentPreferencesEntity,
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'Loading preferences failed',
              onClickAction: () => dispatch(doFetchPreferences(options)),
            },
          },
        },
        ...options,
      }
    )
  )
}

export const doUpdatePreferences = (
  agentPreference,
  options = {}
) => dispatch => {
  return dispatch(
    doApiPutRequest(
      UPDATE_PREFERENCES,
      '/api/settings/preferences',
      {
        preference: agentPreference,
      },
      {
        normalizationSchema: agentPreferencesEntity,
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Preferences changes saved',
            },
            failed: {
              content: 'Preferences changes failed',
              onClickAction: () =>
                dispatch(doUpdatePreferences(agentPreference, options)),
            },
          },
        },
        ...options,
      }
    )
  )
}

export const doFetchTwoFactorAuthenticationCode = () => dispatch => {
  dispatch(
    doAppGraphqlRequest(
      FETCH_TWO_FACTOR_AUTHENTICATION_CODE,
      getTwoFactorAuthenticationCodeQuery
    )
  )
}
