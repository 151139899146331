import { v4 as uuidV4 } from 'uuid'

import { isValidEmail, detectValueDelimiter } from 'util/strings'
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_AGENT,
  AGENT_ROLE_OWNER,
} from './constants'

export function isAgentNotArchived(agent) {
  if (!agent) return false
  return !agent.archived
}

export function isAgentInvitationAccepted(agent) {
  if (!agent) return false
  return agent.hasAcceptedInvitation
}

export function isAgentActive(agent) {
  if (!agent) return false
  return isAgentInvitationAccepted(agent) && isAgentNotArchived(agent)
}

export const compareRole = (a, b, direction = 'ASC') => {
  // Role sort: the ASC order is the same as roles in the array (the order isn't alphabetical)
  const roles = [AGENT_ROLE_OWNER, AGENT_ROLE_ADMIN, AGENT_ROLE_AGENT]
  const roleAIndex = roles.indexOf(a.role?.toUpperCase())
  const roleBIndex = roles.indexOf(b.role?.toUpperCase())
  return direction === 'ASC' ? roleAIndex - roleBIndex : roleBIndex - roleAIndex
}

export const convertEmailsToAgents = (
  bulkEmails,
  { role, agentIdPrefix = '' } = {}
) => {
  const users = []
  const delimiter = detectValueDelimiter(bulkEmails)
  const splitBulkEmails = bulkEmails.split(delimiter)
  splitBulkEmails.forEach(rawEmail => {
    const email = rawEmail.trim()
    if (email && isValidEmail(email)) {
      users.push({
        // we need to create unique ids for each agent since we will be saving multiple entities to pending store
        id: [agentIdPrefix, uuidV4()].filter(Boolean).join('-'),
        email,
        role,
      })
    }
  })
  return { users, splitBulkEmails }
}

export const isRoleOneOf = (agent, roles) => roles.includes(agent.role)
