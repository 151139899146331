import deepEqual from 'fast-deep-equal'

import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { doUpdateCustomFieldFileValues } from 'ducks/crm/customFields/operations/files'
import {
  selectCustomFieldForKey,
  selectCustomFieldWithHandleTypes,
} from 'ducks/crm/customFields/selectors/base'
import { all } from 'util/arrays'
import { readAsDataURL } from 'util/file'
import {
  inputSubjectIdToSubjectId,
  isContactId,
  subjectIdToEntityType,
} from 'ducks/crm/companies/utils'
import { sleep } from 'util/functions'
import { selectSubjectById } from 'ducks/crm/customFieldCategories/selectors/subject/selectSubjectById'
import {
  allConversationFragments,
  conversationFragment,
  customFieldValueFragment,
  widgetConversationFragment,
} from 'ducks/tickets/fragments'
import { processStrategyCustomFieldValuesToProperties } from 'ducks/entities/schema'
import { omit } from 'util/objects'
import { CUSTOM_FIELD_VALUE_UPDATE_MODE } from 'ducks/crm/customFields/constants'
import { doSyncReplyDraftWithContacts } from 'ducks/drafts2/operations/doSyncReplyDraftWithContacts'
import {
  contactsListNormalizationSchema,
  customFieldValueUpdateNormalizationScheme,
} from '../../schema'

import {
  selectContactById,
  selectCustomFieldValueForCustomFieldKeyAndSubjectId,
} from '../../selectors'
import {
  UPDATE_CUSTOM_FIELD_VALUE,
  BULK_UPDATE_CUSTOM_FIELD_VALUE,
  RESYNC_CONTACT,
  MERGE_CONTACT,
  DELETE_CONTACT,
} from '../../types'
import { doFetchContactForPrimaryEmail } from '../fetching/doFetchContactForPrimaryEmail'
import { doFetchCurrentContact } from '../fetching/doFetchCurrentContact'

const CONTACT_ENTITY_TYPE = 'contacts'

const mergeContactMutation = `
mutation ContactMerge(
  $sourceId: ID!,
  $targetId: ID!,
  $keepFieldIds: [ID!]
) {
  contactMerge(input: {
    sourceId: $sourceId,
    targetId: $targetId,
    keepFieldIds: $keepFieldIds
  }) {
    target {
      id
    }
    errors {
      message
      path
    }
  }
}
`

const bulkUpdateCustomFieldValueMutation = `
mutation CustomFieldValuesBulkUpdate(
  $items: [SubjectWithCustomFieldsInput!]!,
  $sync: Boolean!
) {
  customFieldValuesBulkUpdate(input: { items: $items, sync: $sync }) {
    jid
  }
}
`

const deleteContactMutation = `
  mutation ContactDelete(
    $contactId: ID!,
  ) {
    contactDelete(
      input: {
        contactId: $contactId,
      }
    ) {
      deletedContactId
      errors {
        path
        message
      }
    }
  }
`

export const doMergeContact = (
  sourceContactId,
  targetContactId,
  keepFieldIds,
  options = {}
) => async dispatch => {
  const variables = {
    sourceId: sourceContactId,
    targetId: targetContactId,
    keepFieldIds,
  }

  return dispatch(
    doAppGraphqlRequest(MERGE_CONTACT, mergeContactMutation, variables, options)
  )
}

export const doMergeContactFromForm = (
  sourceContactId,
  targetContactId,
  keepFieldIds,
  options = {}
) => dispatch => {
  const targetStores = ['pending', 'current']

  const dispatchOptions = {
    searches: {
      additionalActions: [
        {
          type: 'INVALIDATE_ENTITIES',
          entityTypes: [CONTACT_ENTITY_TYPE],
          phases: ['SUCCESS'],
        },
      ],
    },
    moduleOptions: {
      toasts: {
        enabled: true,
        started: {
          enabled: true,
          content: 'Customers Merged',
        },
        success: {
          enabled: false,
        },
        failed: {
          content: 'Customer merge failed',
          onClickAction: () => {
            dispatch(
              doMergeContactFromForm(
                sourceContactId,
                targetContactId,
                keepFieldIds,
                options
              )
            )
          },
        },
      },
    },
    entities: {
      additionalActions: [
        {
          entityType: CONTACT_ENTITY_TYPE,
          entityId: sourceContactId,
          stores: targetStores,
          operation: 'remove',
          phases: ['SUCCESS'],
        },
        {
          entityType: CONTACT_ENTITY_TYPE,
          entityId: targetContactId,
          stores: targetStores,
          operation: 'remove',
          phases: ['SUCCESS'],
        },
      ],
    },
    ...options,
  }

  return dispatch(
    doMergeContact(
      sourceContactId,
      targetContactId,
      keepFieldIds,
      dispatchOptions
    )
  )
}

export const doResolveEmailConflict = (
  sourceContact,
  targetContactEmail
) => async (dispatch, getState) => {
  const targetContact = await dispatch(
    doFetchContactForPrimaryEmail(targetContactEmail)
  )

  if (targetContact) {
    // When the existing contact already has an email address it means that we're
    // essentially attemping to change the contact. The way to resolve this is to
    // remove the matrix chat id from the current contact and add it to the target
    // contact
    if (sourceContact.email) {
      await dispatch(
        doAppGraphqlRequest(
          BULK_UPDATE_CUSTOM_FIELD_VALUE,
          bulkUpdateCustomFieldValueMutation,
          {
            sync: true,
            items: [
              // Remove contact_matrix_id from the source
              {
                subjectId: sourceContact.id,
                customFieldList: [
                  {
                    customFieldIdOrKey: 'contact_matrix_id',
                    remove: true,
                  },
                ],
              },
              // Add contact_matrix_id from the target
              {
                subjectId: targetContact.id,
                customFieldList: sourceContact.customFieldValues.contact_matrix_id.value.map(
                  ({ content: matrixEnduserId }) => ({
                    customFieldIdOrKey: 'contact_matrix_id',
                    value: matrixEnduserId,
                    mode: CUSTOM_FIELD_VALUE_UPDATE_MODE.APPEND,
                    remove: false,
                  })
                ),
              },
            ],
          },
          {}
        )
      )
      // If the current contact doesnt have an email address and that email address
      // already exists in the system, then it simply means we want to get rid of the
      // current contact and merge any identifyable information into the target contact
    } else {
      const state = getState()
      const handleCustomFields = selectCustomFieldWithHandleTypes(state)
      const handleCustomFieldsIds = handleCustomFields.map(cf => cf.id)

      await dispatch(
        doMergeContact(
          sourceContact.id,
          targetContact.id,
          handleCustomFieldsIds
        )
      )
    }

    // After we've changed the contact or merged the contact we need to
    // refetch the current pages contact to make sure we get the updated
    // version
    let attempt = 0
    while (attempt < 30) {
      // eslint-disable-next-line no-await-in-loop
      const fetchedContactId = await dispatch(
        doFetchCurrentContact({
          allowCreateContact: false,
          disableSuccessActions: true,
        })
      )
      if (fetchedContactId === targetContact.id) break

      // eslint-disable-next-line no-await-in-loop
      await sleep(5000)
      attempt += 1
    }

    // Pull the updated contact from state and return it
    return selectContactById(getState(), targetContact.id)
  }

  // If we cant resolve the conflict, return null to signal to the upstream method that it couldnt be fixed
  return null
}

const doHandleCustomFieldValuesUpdateErrors = (
  subject,
  fields,
  response,
  options = {}
) => async dispatch => {
  const { resolveEmailConflict = false } = options || {}

  const isSubjectContact = isContactId(subject.id)
  if (
    isSubjectContact &&
    response.customFieldValuesUpdate.errors &&
    response.customFieldValuesUpdate.errors.length > 0
  ) {
    const errors = response.customFieldValuesUpdate.errors
    const contactEmailFieldError = errors.find(error => {
      const fieldIndex = error.path[2]
      const field = fields[fieldIndex]
      return (
        field.key === 'contact_email' &&
        error.message.endsWith(': has already been taken')
      )
    })

    if (contactEmailFieldError && resolveEmailConflict) {
      const fieldIndex = contactEmailFieldError.path[2]
      const {
        value: { content: email },
      } = fields[fieldIndex]

      await dispatch(doResolveEmailConflict(subject, email))
    }
  }
}

const updateCustomFieldValueQuery = `
mutation UpdateCustomFieldValue(
  $input: CustomFieldValuesUpdateInput!,
  $customFieldIds: [ID!]
) {
  customFieldValuesUpdate(input: $input) {
    subject {
      ... on Node {
        id
        __typename
      }
      ... on CustomFieldValuesField {
        customFieldValues(filter: { customFieldIds: $customFieldIds }) {
          edges {
            node {
              ...customFieldValueFragment
            }
          }
        }
      }
      ${allConversationFragments()}
    }

    errors {
      message
      path
      type
    }
  }
}
${conversationFragment({ enableCustomFieldFilter: true })}
${widgetConversationFragment()}
${customFieldValueFragment()}
`

export function doUpdateCustomFieldValues(
  inputSubjectId,
  fields,
  options = {}
) {
  return async (dispatch, getState) => {
    const { requestOptions = {}, resolveEmailConflict = false } = options || {}

    const state = getState()
    const fileFields = fields.filter(
      ({ isFileField, remove }) => isFileField && !remove
    )
    const nonFileFields = fields.filter(
      ({ isFileField, remove }) => !isFileField || remove
    )
    const subjectId = inputSubjectIdToSubjectId(inputSubjectId)
    const subject = selectSubjectById(state, inputSubjectId) || {
      id: inputSubjectId,
    }
    const subjectKey = subjectIdToEntityType(inputSubjectId)
    const fileFieldsWithDataUrls = await Promise.all(
      fileFields.map(field => {
        return readAsDataURL(field.value).then(dataUrl => ({
          ...field,
          dataUrl,
        }))
      })
    )
    // FIXME: Using this function here is a major codesmell. The underlying issue is
    // that entities are not being normalized which means that the entity processing
    // strategies are not being called. This needs to be fixed, but that will cause
    // breaking changes with any code that was passing in "denormalized" entities
    const optimisticSubject = processStrategyCustomFieldValuesToProperties({
      ...subject,
      customFieldValues: [
        ...nonFileFields.map(({ key, isArray, mode, value, valueId }) => ({
          id: valueId,
          customField: { isArray },
          key,
          value,
          mode,
        })),
        ...fileFieldsWithDataUrls.map(({ dataUrl, key, valueId }) => ({
          id: valueId,
          key,
          value: { dataUrl },
        })),
      ],
    })
    const optimist = {
      entities: {
        [subjectKey]: {
          [subjectId]: {
            ...optimisticSubject,
            customFieldValues: {
              ...omit(
                [
                  ...nonFileFields.map(f => f.key),
                  ...fileFieldsWithDataUrls.map(f => f.key),
                ],
                subject.customFieldValues
              ),
              ...optimisticSubject.customFieldValues,
            },
          },
        },
      },
    }
    const attachments = await (fileFields.length > 0
      ? dispatch(
          doUpdateCustomFieldFileValues(fileFields, {
            optimist,
            meta: {
              mergeEntities: true,
            },
          })
        )
      : Promise.resolve([]))
    const customFieldList = [
      ...nonFileFields.map(({ id, mode, value }) => {
        const newValue =
          value && deepEqual(Object.keys(value), ['content'])
            ? value.content
            : value
        return {
          customFieldIdOrKey: id,
          ...(['', undefined, null].includes(newValue)
            ? { remove: true }
            : { value: newValue, mode }),
        }
      }),
      ...attachments.map(({ id, value }) => {
        return {
          customFieldIdOrKey: id,
          value: {
            content_type: value.attachment_content_type,
            file_name: value.attachment_file_name,
            file_size: value.attachment_file_size,
            attachment_id: value.id,
          },
        }
      }),
    ]
    if (customFieldList.length === 0) return null
    const variables = {
      input: {
        subjectId: inputSubjectId,
        customFieldList,
      },
      customFieldIds: fields.map(({ id }) => id),
    }
    const response = await dispatch(
      doAppGraphqlRequest(
        UPDATE_CUSTOM_FIELD_VALUE,
        updateCustomFieldValueQuery,
        variables,
        {
          optimist,
          meta: {
            mergeEntities: true,
            attachments,
          },
          normalizationSchema: customFieldValueUpdateNormalizationScheme,
          ...requestOptions,
        }
      )
    )

    await dispatch(
      doHandleCustomFieldValuesUpdateErrors(subject, fields, response, {
        resolveEmailConflict,
      })
    )

    dispatch(doSyncReplyDraftWithContacts())

    return response
  }
}

function valueIsEmpty(value) {
  return (
    !value || all(x => ['', undefined, null].includes(x), Object.values(value))
  )
}

export function doUpdateCustomFieldValuesFromForm(subjectId, formData) {
  return (dispatch, getState) => {
    const state = getState()
    const doneKeys = []

    const newValues = Object.keys(formData)
      .reduce((result, key) => {
        const { id: valueId, value: existingValue } =
          selectCustomFieldValueForCustomFieldKeyAndSubjectId(state, {
            customFieldKey: key,
            subjectId,
          }) || {}
        const { id, isArray, type } =
          selectCustomFieldForKey(state, { key }) || {}

        const newValue = formData[key]
        if (isArray) {
          const filteredNewValue = newValue.filter(
            ({ content } = {}) => !!content
          )
          return result.concat(
            filteredNewValue.length > 0
              ? filteredNewValue.map(individualValue => {
                  return {
                    key,
                    id,
                    isArray: true,
                    isFileField: false,
                    mode: CUSTOM_FIELD_VALUE_UPDATE_MODE.REPLACE,
                    valueId,
                    value: individualValue,
                  }
                })
              : [
                  {
                    key,
                    id,
                    isArray: true,
                    isFileField: false,
                    mode: CUSTOM_FIELD_VALUE_UPDATE_MODE.REPLACE,
                    valueId,
                    remove: true,
                  },
                ]
          )
        }
        const isFileField = type === 'FILE' && newValue instanceof File
        const bothEmpty = valueIsEmpty(newValue) && valueIsEmpty(existingValue)
        // If new and existing values are equivalent return early
        const equalValues = deepEqual(newValue, existingValue)
        const unchangedFile =
          isFileField && newValue.remove === 'false' && newValue.size === 0
        const isFileRemoved = newValue.remove === 'true'
        delete newValue.remove
        if ((equalValues || bothEmpty) && !isFileField) return result
        if (unchangedFile) return result
        const firstForKey = !doneKeys.includes(key)
        doneKeys.push(key)
        result.push({
          key,
          id,
          isFileField,
          firstForKey,
          valueId,
          value: isFileRemoved ? null : newValue,
          remove: isFileRemoved,
        })
        return result
      }, [])
      .filter(x => x !== null)

    return dispatch(
      doUpdateCustomFieldValues(subjectId, newValues, {
        resolveEmailConflict: true,
      })
    )
  }
}

const resyncContactMutation = `
mutation resyncContactMutation($contactId: ID!, $integrationId: ID!) {
  contactResync(input: { contactId: $contactId, integrationId: $integrationId,  }) {
    contact {
      id
    }
    errors {
      message
      path
    }
  }
}
`

export const doContactResyncIntegrationData = (
  contactId,
  integrationId,
  // eslint-disable-next-line no-unused-vars
  options = {}
) => async dispatch => {
  await dispatch(
    doAppGraphqlRequest(
      RESYNC_CONTACT,
      resyncContactMutation,
      {
        contactId,
        integrationId,
      },
      {}
    )
  )
  return dispatch(doFetchCurrentContact())
}

const doDeleteContact = (id, options = {}) => dispatch => {
  return dispatch(
    doAppGraphqlRequest(
      DELETE_CONTACT,
      deleteContactMutation,
      {
        contactId: id,
      },
      {
        app: true,
        optimist: {},
        normalizationSchema: contactsListNormalizationSchema,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: [CONTACT_ENTITY_TYPE],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Customer deleted',
            },
            failed: {
              content: 'Customer deletion failed',
              onClickAction: () => {
                dispatch(doDeleteContact(id, options))
              },
            },
          },
        },
        entities: {
          targetOperation: 'remove',
          additionalActions: [
            {
              entityType: CONTACT_ENTITY_TYPE,
              entityId: id,
              stores: ['pending', 'current'],
              operation: 'remove',
              phases: ['SUCCESS'],
            },
          ],
        },
      }
    )
  )
}

export const doDeleteContacts = (ids, options = {}) => dispatch => {
  return Promise.all(ids.map(id => dispatch(doDeleteContact(id, options))))
}
