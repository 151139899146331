import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'

export function selectBase(state) {
  return state?.integrations?.thirdPartyData?.customProfile || emptyObj
}
export const selectCustomProfileContentByContactId = createBasicSelector(
  selectBase,
  (_state, contactId) => contactId,
  (base, contactId) => base[contactId]
)
