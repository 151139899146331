import { getRawId } from 'util/globalId'

export const isCompanyId = subjectId => subjectId.match(/^cm_/)
export const isContactId = subjectId => subjectId.match(/^co_/)
export const isConversationId = subjectId => subjectId.match(/^cnv_/)

export const subjectIdToEntityType = subjectId => {
  if (isCompanyId(subjectId)) return 'companies'
  if (isConversationId(subjectId)) return 'conversation'
  return 'contacts'
}

export const inputSubjectIdToSubjectId = subjectId => {
  if (isConversationId(subjectId)) return getRawId(subjectId)
  return subjectId
}
