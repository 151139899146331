import { UPDATE_DRAFT } from 'ducks/drafts2/constants'
import { doFetchCurrentContact } from 'ducks/crm/contacts/operations/fetching/doFetchCurrentContact'
import { doCreateDraft } from './doCreateDraft'
import { buildSharedDraftActionMetadata } from '../util'

export function doUpsertReplyDraft(
  draftId,
  draftType,
  ticketId,
  messageId,
  fields
) {
  return (dispatch, getState) => {
    const state = getState()
    let retVal
    if (draftId) {
      retVal = dispatch({
        type: UPDATE_DRAFT,
        payload: { draftType, draftId, ticketId, fields },
        meta: { ...buildSharedDraftActionMetadata(state) },
      })
    } else {
      retVal = dispatch(doCreateDraft(ticketId, draftType, fields))
    }
    // This works because only the field that are different come through. If you
    // change that, this will start to fetch too often.
    const toEmail = (fields?.to || [])[0]?.email
    if (toEmail) {
      dispatch(doFetchCurrentContact())
    }
    return retVal
  }
}
