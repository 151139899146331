import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import { _selectSortedIds } from './_selectSortedIds'
import { selectMailboxesById } from './selectMailboxesById'

export const selectKnownMailboxes = createSelector(
  selectMailboxesById,
  _selectSortedIds,
  (byId, sortedIds) => {
    if (sortedIds == null) {
      return emptyArr
    }
    const mailboxes = []
    sortedIds.forEach(id => {
      if (byId[id].hasAccess) mailboxes.push(byId[id])
    })

    return mailboxes
  }
)
