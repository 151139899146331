import { connect } from 'react-redux'

import { doFetchKnowledgeBases } from 'subapps/kb/actions/knowledge_bases'
import {
  selectActiveKnowledgeBaseId,
  selectActiveMailboxId,
} from 'ducks/reports/controls'
import { selectKnowledgeBases } from 'subapps/kb/selectors/knowledge_bases'
import { selectMailboxesIncludingInaccessible } from 'ducks/mailboxes/selectors/selectMailboxesIncludingInaccessible'
import { selectHasRealConfirmedMailboxes } from 'selectors/trial_setup'

import ReportsSidebarView from './ReportsSidebarView'

const select = state => ({
  activeKnowledgeBaseId: selectActiveKnowledgeBaseId(state),
  activeMailboxId: selectActiveMailboxId(state),
  activePage: state.ducksPage.page,
  knowledgeBases: selectKnowledgeBases(state),
  mailboxes: selectMailboxesIncludingInaccessible(state),
  hasRealConfirmedMailboxes: selectHasRealConfirmedMailboxes(state) > 0,
})

const perform = {
  doFetchKnowledgeBases,
}

export default connect(select, perform)(ReportsSidebarView)
