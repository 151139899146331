import { BOOTSTRAP_APP_DATA_SUCCESS } from 'ducks/bootstrap/types'
import { FETCH_ALL_CUSTOM_FIELDS_SUCCESS } from '../types'

export default function isLoading(state = false, { type }) {
  if (
    [FETCH_ALL_CUSTOM_FIELDS_SUCCESS, BOOTSTRAP_APP_DATA_SUCCESS].includes(type)
  )
    return true
  return state
}
