import {
  BOOTSTRAP_APP_DATA_FAILED,
  BOOTSTRAP_APP_DATA_STARTED,
  BOOTSTRAP_APP_DATA_SUCCESS,
} from 'ducks/bootstrap/types'
import {
  FETCH_ALL_CUSTOM_FIELDS_STARTED,
  FETCH_ALL_CUSTOM_FIELDS_FAILED,
  FETCH_ALL_CUSTOM_FIELDS_SUCCESS,
  FETCH_CUSTOM_FIELDS_FAILED,
} from '../types'

export default function isLoading(state = false, { type }) {
  if (
    [BOOTSTRAP_APP_DATA_STARTED, FETCH_ALL_CUSTOM_FIELDS_STARTED].includes(type)
  )
    return true
  if (
    [
      FETCH_ALL_CUSTOM_FIELDS_SUCCESS,
      FETCH_ALL_CUSTOM_FIELDS_FAILED,
      FETCH_CUSTOM_FIELDS_FAILED,
      BOOTSTRAP_APP_DATA_FAILED,
      BOOTSTRAP_APP_DATA_SUCCESS,
    ].includes(type)
  ) {
    return false
  }
  return state
}
