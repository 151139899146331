// Constants for imap and smpt server details
export const IMAP_SERVER = 'incoming_email_server'
export const SMTP_SERVER = 'outgoingEmailServer'
export const EMAIL_SERVER_DOMAIN = 'host'
export const EMAIL_SERVER_DOMAIN_PORT = 'port'
export const EMAIL_SERVER_LOGIN_EMAIL = 'username'
export const IMAP_EMAIL_SERVER_LOGIN_EMAIL = 'user_name'
export const EMAIL_SERVER_LOGIN_PASSWORD = 'password'
export const EMAIL_SERVER_AUTH_SSL = 'encryption'

export const EMAIL_SERVER_IS_UPDATE_PASSWORD = 'is_update_password'

export const CHANNEL_STATE = Object.freeze({
  UNCONFIRMED: 'UNCONFIRMED',
  DEMO: 'DEMO',
  CONFIRMED: 'CONFIRMED',
  SYNCING: 'SYNCING',
  ACTIVE: 'ACTIVE',
  ERROR: 'ERROR',
  DISCONNECTED: 'DISCONNECTED',
  MIGRATING: 'MIGRATING',
})

export const CHANNEL_TYPE = Object.freeze({
  FORWARDING: 'FORWARDING',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  OFFICE365: 'OFFICE365',
  TWITTER: 'TWITTER',
  IMAP: 'IMAP',
})
