import { BEGIN, COMMIT, REVERT } from 'redux-optimist'

export default function optimistActionModule(phase, action, _payload, options) {
  if (!options.optimist) return action

  const {
    optimist = null,
    optimist: { id: inputOptimistId, payload: optimistPayload = optimist } = {},
  } = options

  const optimistId = inputOptimistId || action?.request?.id

  if (phase === 'STARTED') {
    Object.assign(action, {
      optimist: { type: BEGIN, id: optimistId, payload: optimistPayload },
    })
  } else if (phase === 'SUCCESS') {
    Object.assign(action, {
      optimist: { type: COMMIT, id: optimistId },
    })
  } else if (phase === 'FAILED') {
    Object.assign(action, {
      optimist: { type: REVERT, id: optimistId },
    })
  }

  return action
}
