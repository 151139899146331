import createCachedSelector from 're-reselect'

import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { createBasicSelector } from 'util/redux'

const selectIntegrationData = state =>
  state?.integrations?.thirdPartyData?.recharge || emptyObj

export const selectOrders = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.orders
)

export const selectOrdersBySubscriptionId = createBasicSelector(
  selectOrders,
  subscriptions => subscriptions.bySubscriptionId || emptyObj
)

export const selectOrdersForSubscriptionId = createBasicSelector(
  selectOrdersBySubscriptionId,
  (state, subscriptionId) => subscriptionId,
  (bySubscriptionId, subscriptionId) =>
    bySubscriptionId[subscriptionId] || emptyObj
)

export const selectOrdersIsLoading = createBasicSelector(
  selectOrdersForSubscriptionId,
  orders => orders?.isLoading
)

export const selectOrderForId = createCachedSelector(
  (state, { subscriptionId }) =>
    selectOrdersForSubscriptionId(state, subscriptionId),
  (state, { orderId }) => orderId,
  (bySubscriptionId, orderId) => {
    const orders = bySubscriptionId.orders || emptyArr
    return orders.find(order => order.id === parseInt(orderId, 10))
  }
)((state, { subscriptionId }) => subscriptionId || 'unknown')

export const selectOrdersForStatus = createCachedSelector(
  (state, { subscriptionId }) =>
    selectOrdersForSubscriptionId(state, subscriptionId),
  (state, { status }) => status,
  (bySubscriptionId, status) => {
    const orders = bySubscriptionId.orders || emptyArr
    return orders.filter(order => order.status === status)
  }
)((state, { subscriptionId }) => subscriptionId || 'unknown')
