import { schema } from 'normalizr'

export function buildEntityLookup(schemas) {
  const lookup = {}

  const getEntityDependencies = (entity, visited = new Set()) => {
    if (visited.has(entity.key)) return []
    visited.add(entity.key)

    const dependencies = [entity.key]
    const definition = entity.schema

    Object.entries(definition).forEach(([, child]) => {
      if (child instanceof schema.Entity) {
        dependencies.push(...getEntityDependencies(child, visited))
      } else if (Array.isArray(child) && child[0] instanceof schema.Entity) {
        dependencies.push(...getEntityDependencies(child[0], visited))
      } else if (child instanceof schema.Union) {
        // Handle Union types
        Object.values(child.schema).forEach(unionEntity => {
          if (unionEntity instanceof schema.Entity) {
            dependencies.push(...getEntityDependencies(unionEntity, visited))
          }
        })
      } else if (child && typeof child === 'object') {
        Object.values(child).forEach(nestedEntity => {
          if (nestedEntity instanceof schema.Entity) {
            dependencies.push(...getEntityDependencies(nestedEntity, visited))
          }
        })
      }
    })

    return [...new Set(dependencies)]
  }

  schemas.forEach(entity => {
    lookup[entity.key] = getEntityDependencies(entity)
  })

  return lookup
}
