import { createSelector } from 'reselect'
import { selectMailboxesById } from 'ducks/mailboxes/selectors/selectMailboxesById'
import { selectCurrentMailboxId } from 'ducks/mailboxes/selectors/selectCurrentMailboxId'

export const selectCurrentMailbox = createSelector(
  selectMailboxesById,
  selectCurrentMailboxId,
  (byId, mailboxId) => {
    return byId[mailboxId] || null
  }
)
