import { doGraphqlRequest } from 'ducks/requests/operations'
import { camelize } from 'util/strings'
import { FETCH_ATTACHMENTS_REQUEST } from './types'
import { attachmentsQuery } from './queries'
import { attachmentsGraphQlResponseSchema } from './schema'
import { buildConversationAttachmentsRequestKey } from './utils'

export function doFetchAttachments(ticketId, type) {
  return doGraphqlRequest(
    FETCH_ATTACHMENTS_REQUEST,
    attachmentsQuery,
    {
      conversationId: ticketId,
      conversationType: type.toUpperCase(),
    },
    {
      app: true,
      normalizationSchema: attachmentsGraphQlResponseSchema,
      meta: {
        requestKey: camelize(buildConversationAttachmentsRequestKey(ticketId)),
        mergeEntities: true,
      },
    }
  )
}
