import { basicFields as integrationFields } from 'api/integrations'
import { EXTENDED_BILLING_FIELDS_V2 } from 'ducks/billing/api'
import { basicChannelFields } from 'ducks/mailboxes/api'
import { BASIC_TEMPLATE_FIELDS as FOLDER_BASIC_TEMPLATE_FIELDS } from 'ducks/folders/queries/folders/queries.v2'
import { customFieldFields } from 'ducks/crm/customFields/operations/fetching'
import { customFieldCategoryFields } from 'ducks/crm/customFieldCategories'

export const bootstrapQuery = () => `
  query AppBootstrapQuery($agentId: ID!) {
    account {
      id
      subdomain
      state
      expiresAt
    }
    billing {
      ${EXTENDED_BILLING_FIELDS_V2}
    }
    integrations {
      ${integrationFields}
    }
    channels {
      edges {
        node {
          ... on EmailChannel {
            ${basicChannelFields()}
          }
          ... on DemoEmailChannel {
            ${basicChannelFields()}
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      totalPageCount
    }
    folders(first: 1000, filter: { state: ALL, scope: ALL }) {
      nodes {
        ${FOLDER_BASIC_TEMPLATE_FIELDS}
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
    customFields(first: 1000, filter: { deleted: false }) {
      edges {
        node {
          ${customFieldFields({ includeFullCategory: false })}
        }
      }
    }
    customFieldCategories(first: 20, ) {
      edges {
        node {
          ${customFieldCategoryFields}
        }
      }
    }
    teams {
      edges {
        node {
          id
          agents {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`
