import { convertGidToRaw, hash } from 'util/scatterSwap'
import { buildId } from 'util/globalId'
import {
  GLOBAL_MAILBOX_CUSTOM_FIELD_PREFIX,
  MAILBOX_CUSTOM_FIELD_PREFIX,
} from './constants'

export const buildChannelCategoryKey = channel => {
  return `cfm_${convertGidToRaw(channel.id)}`
}

export function getChannelIdFromKey(key) {
  if (!key || !key.startsWith(MAILBOX_CUSTOM_FIELD_PREFIX)) return null

  const rawId = key.split('_')[1]
  return buildId('Channel', hash(rawId))
}

export function isGlobalKey(key) {
  return key.startsWith(GLOBAL_MAILBOX_CUSTOM_FIELD_PREFIX)
}

export function isChannelKey(key) {
  return key.startsWith(MAILBOX_CUSTOM_FIELD_PREFIX)
}

export function isChannelCustomFieldKey(key) {
  return isGlobalKey(key) || isChannelKey(key)
}
