import { createSelector } from 'reselect'

import { selectCurrentContact } from 'ducks/crm/contacts/selectors/current'

import { COMPANY_PAGE } from '../../pages'
import { selectCompaniesById, selectCompanyStatesById } from './base'

export function selectCompanyPageCompanyId(state) {
  if (state.location.type === COMPANY_PAGE) {
    return state.location.payload.id || null
  }
  return null
}

export const selectCurrentCompanyId = createSelector(
  selectCompanyPageCompanyId,
  selectCurrentContact,
  (companyPageCompanyId, contact) => {
    return (
      companyPageCompanyId ||
      (contact && contact.companies && contact.companies[0])
    )
  }
)
export const selectCurrentCompany = createSelector(
  selectCurrentCompanyId,
  selectCompaniesById,
  (id, byId) => {
    return id && (byId[id] || { id })
  }
)

export const selectCurrentCompanyStates = createSelector(
  selectCurrentCompanyId,
  selectCompanyStatesById,
  (id, byId) => {
    return id && (byId[id] || { id })
  }
)
