import { doAppGraphqlRequest } from 'ducks/requests/operations'
import {
  FETCH_CONTACT_CUSTOM_FIELD_VALUES_FAILED,
  FETCH_CONTACT_CUSTOM_FIELD_VALUES_PAGE,
  FETCH_CONTACT_CUSTOM_FIELD_VALUES_STARTED,
  FETCH_CONTACT_CUSTOM_FIELD_VALUES_SUCCESS,
} from 'ducks/crm/contacts/types'
import { contactNormalizationSchema } from 'ducks/crm/contacts/schema'
import { isDefined } from 'util/nullOrUndefinedChecks'
import { camelize } from 'util/strings'
import { fetchContactCustomFieldValuesForIdQuery } from './queries'
import { makeEntitiesAvailable } from './makeEntitiesAvailable'

export const doFetchContactCustomFieldValuesForId = (
  id,
  options = {}
) => async dispatch => {
  const { targetStore, requestKey } = options || {}

  let hasNextPage
  let after
  let page = 0

  try {
    dispatch({
      type: FETCH_CONTACT_CUSTOM_FIELD_VALUES_STARTED,
      meta: {
        id,
        page,
        requestKey: camelize(requestKey),
      },
    })

    do {
      const variables = {
        id,
        after,
        first: 20,
      }

      // eslint-disable-next-line no-await-in-loop
      const result = await dispatch(
        doAppGraphqlRequest(
          FETCH_CONTACT_CUSTOM_FIELD_VALUES_PAGE,
          fetchContactCustomFieldValuesForIdQuery,
          variables,
          {
            throwOnError: true,
            meta: { mergeEntities: true },
            normalizationSchema: contactNormalizationSchema,
            moduleOptions: {
              entities: {
                targetStore,
                missingEntityActions: [
                  {
                    entityType: 'contacts',
                    entityId: id,
                    phases: ['SUCCESS'],
                  },
                ],
              },
            },
            transformEntities: makeEntitiesAvailable,
          }
        )
      )

      const { node: contact } = result || {}

      if (!contact) {
        hasNextPage = false
        break
      }

      const { customFieldValues } = contact || {}
      const { pageInfo } = customFieldValues || {}
      hasNextPage = isDefined(pageInfo?.hasNextPage)
        ? pageInfo?.hasNextPage
        : false
      after = pageInfo?.endCursor
      page += 1
    } while (hasNextPage)

    dispatch({
      type: FETCH_CONTACT_CUSTOM_FIELD_VALUES_SUCCESS,
      meta: {
        id,
        page,
        requestKey: camelize(requestKey),
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_CONTACT_CUSTOM_FIELD_VALUES_FAILED,
      meta: {
        id,
        page,
        requestKey: camelize(requestKey),
      },
    })
  }
}
