import { install, uninstall } from 'util/windowUnload'
import { batchActions } from 'util/redux'
import { getLength } from 'util/arrays'

export const activeRequests = new Map()
export const requestQueue = new Map()
export const actionsQueue = new Map()
export const activeRequestCount = new Map()

const createQueueAction = key => action => {
  const queue = actionsQueue.get(key) || []
  queue.push(action)
  actionsQueue.set(key, queue)
}

const executeActions = (key, dispatch) => {
  const actions = actionsQueue.get(key) || []
  actionsQueue.delete(key)
  if (getLength(actions) <= 0) return false
  if (getLength(actions) === 1) return dispatch(actions[0])
  return dispatch(batchActions(actions))
}

export function anyRequestsLeft() {
  return (
    activeRequests.size > 0 ||
    Array.from(requestQueue.values()).some(queue => queue.length > 0)
  )
}

export async function manageConcurrency(key, dispatch, promiseFn, options) {
  const {
    parentKey = null,
    maxConcurrency = 1,
    waitForParent = false,
    queueActions = false,
    message: inputMessage,
  } =
    options || {}

  const queueKey = queueActions && waitForParent && parentKey ? parentKey : key

  const message =
    inputMessage ||
    'You have unsaved changes. If you close the window, you might lose your changes'

  // Function to execute the promise and handle the queue
  const execute = async () => {
    try {
      const result = await promiseFn(createQueueAction(queueKey))
      return result
    } finally {
      // Process the next request in the queue
      const queue = requestQueue.get(key)
      if (queue && queue.length > 0) {
        if (!queueActions) {
          executeActions(key, dispatch)
        }
        const {
          resolve,
          message: nextMessage,
          execute: nextRequest,
        } = queue.shift()
        if (queue.length === 0) {
          requestQueue.delete(key) // Clear the queue for the key if empty
        }
        install(nextMessage) // Install the next message
        resolve(await nextRequest(createQueueAction(queueKey))) // Execute the next request
      } else {
        executeActions(key, dispatch)
        if (parentKey) {
          const parentCount = activeRequestCount.get(parentKey) - 1
          activeRequestCount.set(parentKey, parentCount)
          if (parentCount === 0) {
            activeRequests.delete(key)
            activeRequestCount.delete(parentKey)
          }
        } else {
          activeRequests.delete(key)
        }

        if (!anyRequestsLeft()) {
          uninstall() // Uninstall onbeforeunload when no more requests
        }
      }
    }
  }

  // Queue handling
  if (activeRequests.has(key)) {
    const parentCount = activeRequestCount.get(parentKey)
    if (parentKey && parentCount < maxConcurrency) {
      activeRequestCount.set(parentKey, parentCount + 1)

      return execute()
    }
    return new Promise(resolve => {
      const queue = requestQueue.get(key) || []
      queue.push({ resolve, message, execute })
      requestQueue.set(key, queue)
    })
  }
  activeRequests.set(key, true)
  if (parentKey) {
    activeRequestCount.set(parentKey, 1)
  }

  if (activeRequests.size === 1) {
    // This is the first active request, install the onbeforeunload handler
    install(message)
  }

  return execute()
}
