import { selectCurrentUserId } from 'ducks/currentUser/selectors/selectCurrentUserId'
import { changeEntity, mergeEntityChanges } from 'ducks/entities/actionUtils'
import { selectPendingMessageTemplateAttachmentIdsById } from 'ducks/messageTemplates/selectors'
import { UPDATE_MESSAGE_TEMPLATE_OPTIMISTIC } from 'ducks/messageTemplates/types'
import { normalize } from 'normalizr'
import { attachment as attachmentSchema } from 'ducks/entities/schema'

export const doMessageTemplateAddPendingAttachment = ({
  id,
  entityType,
  attachmentId,
  editorFile,
}) => (dispatch, getState) => {
  const state = getState()
  const creatorId = selectCurrentUserId(state)
  const attachmentIds =
    selectPendingMessageTemplateAttachmentIdsById(state, entityType, id) || {}

  const attachment = {
    id: attachmentId,
    attachment_file_name: editorFile.name,
    attachment_file_size: editorFile.size,
    attachment_content_type: editorFile.type,
    token_url: '',
    token_download_url: '',
    s3_key: '',
    creator: creatorId,
  }

  const entities = normalize(attachment, attachmentSchema).entities
  const normalizedAttachment = Object.values(entities.attachment)[0]

  dispatch({
    type: UPDATE_MESSAGE_TEMPLATE_OPTIMISTIC,
    ...mergeEntityChanges([
      changeEntity(
        'attachment',
        attachmentId,
        normalizedAttachment,
        'update',
        'pending'
      ),
      changeEntity(
        entityType,
        id,
        {
          attachments: [...attachmentIds, attachmentId],
        },
        'update',
        'pending'
      ),
    ]),
  })
}

export const doMessageTemplateRemovePendingAttachment = ({
  id,
  entityType,
  attachmentId,
}) => (dispatch, getState) => {
  const state = getState()
  const attachmentIds =
    selectPendingMessageTemplateAttachmentIdsById(state, entityType, id) || {}

  dispatch({
    type: UPDATE_MESSAGE_TEMPLATE_OPTIMISTIC,
    ...mergeEntityChanges([
      changeEntity(
        'attachment',
        attachmentId,
        { id: attachmentId },
        'remove',
        'pending'
      ),
      changeEntity(
        entityType,
        id,
        {
          attachments: attachmentIds.filter(atId => atId !== attachmentId),
        },
        'update',
        'pending'
      ),
    ]),
  })
}
