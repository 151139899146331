export default function extractGraphqlPagination(payload, { options }) {
  const {
    searches: { idAttribute = object => object.id, cursor } = {},
  } = options

  return Object.keys(payload).reduce((paginations, type) => {
    const {
      nodes: inputNodes,
      edges,
      pageInfo: { startCursor, endCursor, hasNextPage, hasPreviousPage } = {},
      totalCount: responseTotalCount,
      totalPageCount: responsePageCount,
    } = payload[type]

    let nodes = inputNodes || edges?.map(item => item.node) || []

    nodes = nodes.map(item => {
      return {
        ...item,
        id: idAttribute(item),
      }
    })
    let totalCount = responseTotalCount
    let totalPageCount = responsePageCount

    if (cursor === 'all' && totalCount === undefined) {
      totalCount = nodes.length
      totalPageCount = 1
    }

    paginations.push({
      type,
      nodes,
      startCursor,
      endCursor,
      hasNextPage,
      hasPreviousPage,
      totalCount,
      totalPageCount,
    })
    return paginations
  }, [])[0]
}
