import { oauthTokenSelector } from 'selectors/app'

import { selectCurrentContactName } from 'ducks/crm/contacts/selectors'
import { selectIsInChat } from 'selectors/location'
import {
  selectCurrentTicketTitle,
  selectCurrentConversationUrl,
} from 'selectors/tickets'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import integrationApi from '../../api'

export default function doPostTicketLinkOnGitHubIssue(issueId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const ticketUrl = selectCurrentConversationUrl(state)
    const ticketTitle = selectCurrentTicketTitle(state)
    const isChat = selectIsInChat(state)
    const ticketId = selectCurrentTicketId(state)
    const contactName = selectCurrentContactName(state)
    const title = isChat ? `Chat with ${contactName}` : ticketTitle

    integrationApi.post(
      token,
      `proxy/jira-server/rest/api/2/issue/${issueId}/comment`,
      {},
      JSON.stringify({
        body: `[#${ticketId}: ${title}|${ticketUrl}]`,
      })
    )
  }
}
