import { createBasicSelector } from 'util/redux'

import selectIntegrationCredentialsById from './selectIntegrationCredentialsById'

export default createBasicSelector(
  selectIntegrationCredentialsById,
  (_state, { id } = {}) => id,
  (integrationCredentials, id) => {
    const forId = integrationCredentials[id]
    if (forId) return forId
    return undefined
  }
)
