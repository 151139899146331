import { doRequest } from 'ducks/requests/operations'
import { doFetchTickets } from 'ducks/tickets/actions'

import {
  CHANGE_MERGE_DIRECTION,
  UPDATE_MERGING_MERGE_TICKET,
  UPDATE_MERGING_SEARCH_TERM,
  RESET_TO_DEFAULTS,
  MERGE_SEARCH,
} from './types'
import { selectMergingSearchQueryId } from './selectors'

export const doUpdateMergeSearchTerm = search => dispatch => {
  return dispatch({
    type: UPDATE_MERGING_SEARCH_TERM,
    payload: { search },
  })
}

export const doUpdateMergeTicket = mergeTicket => dispatch => {
  return dispatch({
    type: UPDATE_MERGING_MERGE_TICKET,
    data: { mergeTicket },
  })
}

export const doResetToDefaults = () => dispatch => {
  return dispatch({
    type: RESET_TO_DEFAULTS,
  })
}

export const doChangeMergeDirection = direction => dispatch => {
  return dispatch({
    type: CHANGE_MERGE_DIRECTION,
    data: { direction },
  })
}

export const doFetchMergeSearchByQueryId = (newQueryId = null) => (
  dispatch,
  getState
) => {
  const state = getState()
  const mergeQueryId = selectMergingSearchQueryId(state)
  const queryId = newQueryId || mergeQueryId
  const loadFirst = !!newQueryId

  if (!queryId) return null

  return dispatch(
    doRequest(
      MERGE_SEARCH,
      async () => {
        return dispatch(doFetchTickets({ queryId, loadFirst }))
      },
      {
        queryId,
        loadFirst,
      }
    )
  )
}
