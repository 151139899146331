import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

import { makeSelectRequest } from 'ducks/requests/selectors'
import { selectCustomFieldCategoriesById } from 'ducks/crm/customFieldCategories/selectors'
import { selectCustomFields } from './base'

const selectTrashedCustomFieldRequest = makeSelectRequest(
  'crm/customfields/fetchTrashedCustomFields'
)

export const selectIsTrashedCustomFieldsLoading = createSelector(
  selectTrashedCustomFieldRequest,
  ({ loading }) => loading
)

export const selectIsTrashedCustomFieldsErrored = createSelector(
  selectTrashedCustomFieldRequest,
  ({ errored }) => errored
)
export const selectIsTrashedCustomFieldsForType = createCachedSelector(
  selectCustomFields,
  selectCustomFieldCategoriesById,
  (state, { type }) => type,
  (customFields, categories, type) => {
    return customFields
      .filter(({ deletedAt, category }) => {
        return !!deletedAt && categories[category].type === type
      })
      .sort(({ name: a }, { name: b }) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1
        if (a.toLowerCase() > b.toLowerCase()) return 1
        return 0
      })
  }
)((state, { type }) => type)
