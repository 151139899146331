import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectCurrentTicketReplyDraft } from 'ducks/drafts2/selectors'
import { selectAccount } from 'selectors/app'
import { selectCurrentTicketMailbox } from 'selectors/tickets'
import { emptyArr } from 'util/arrays'
import { selectCurrentDenormalizedConversation } from 'ducks/tickets/selectors/selectCurrentDenormalizedConversation'
import { selectCurrentTicketDenormalizedEventGroups } from 'ducks/tickets/selectors/selectCurrentTicketEventGroups'
import { selectBase } from './selectBase'
import { interpolateAiDraft } from '../utils'
import { selectDraftReplySourcesStreamingResult } from './streamingResults'

export const selectAiConversationRequest = createSelector(
  selectBase,
  selectCurrentUser,
  selectCurrentTicketReplyDraft,
  selectCurrentDenormalizedConversation,
  selectAccount,
  selectCurrentTicketDenormalizedEventGroups,
  selectCurrentTicketMailbox,
  (base, currentUser, draft, ticket, account, eventGroups, mailbox) => {
    const requestState = base.conversationRequest || emptyObj
    if (!requestState.response) {
      return requestState
    }

    return {
      ...requestState,
      response: {
        ...requestState.response,
        answer: interpolateAiDraft({
          currentUser,
          draft,
          ticket,
          reply: requestState.response?.answer,
          account,
          eventGroups,
          mailbox,
        }),
      },
    }
  }
)

export const selectAiConversationSourceIds = createSelector(
  selectDraftReplySourcesStreamingResult,
  selectAiConversationRequest,
  (sourcesFromStreamingResult, aiConversationRequest) => {
    const sourcesFromResponse =
      aiConversationRequest.response?.sources || emptyArr
    return sourcesFromStreamingResult.length
      ? sourcesFromStreamingResult
      : sourcesFromResponse
  }
)
