import { createSelector } from 'reselect'

import { selectRoomFromUrl } from 'ducks/chat/selectors/rooms'
import { camelizeObjectKeys } from 'util/objects'
import { sortByKey } from 'util/arrays'
import { selectCurrentTicketLinkedResources } from './selectCurrentTicketLinkedResources'

export default createSelector(
  selectCurrentTicketLinkedResources,
  selectRoomFromUrl,
  (ticketLinkedResources, room) => {
    const linkedResources =
      ticketLinkedResources || room?.linkedExternalResources?.records
    if (linkedResources) {
      return sortByKey(linkedResources.map(camelizeObjectKeys), 'linkedAt')
    }
    return null
  }
)
