export default function modalActionModule(phase, action, _payload, options) {
  const {
    moduleOptions: {
      modal: {
        enabled = false,
        type: modalType,
        props: modalProps,
        open: modalOpen,
        phases: modalPhases = ['STARTED', 'SUCCESS', 'FAILED'],
      } = {},
    } = {},
  } =
    options || {}

  if (!enabled) return action

  if (modalPhases.includes(phase)) {
    Object.assign(action, {
      modal: {
        type: modalType,
        props: modalProps,
        open: modalOpen,
      },
    })
  }

  return action
}
