import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { RESTORE_CUSTOM_FIELD, TRASH_CUSTOM_FIELD } from '../types'

const trashCustomField = `
  mutation CustomFieldDelete($customFieldId: ID!, $mode: DeleteMode) {
    customFieldDelete(input: { customFieldId: $customFieldId, deleteMode: $mode }) {
      errors {
        message
        path
      }
      deletedCustomFieldId
    }
  }
`
export function doTrashCustomField(customFieldId, mode = 'SOFT') {
  const additionalActions = []
  if (mode === 'SOFT') {
    additionalActions.push({
      entityType: 'customFields',
      entityId: customFieldId,
      stores: ['current'],
      operation: 'update',
      phases: ['SUCCESS'],
      entity: {
        deletedAt: true,
      },
    })
  } else {
    additionalActions.push({
      entityType: 'customFields',
      entityId: customFieldId,
      stores: ['current'],
      operation: 'remove',
      phases: ['SUCCESS'],
    })
  }

  return doAppGraphqlRequest(
    TRASH_CUSTOM_FIELD,
    trashCustomField,
    { customFieldId, mode },
    {
      moduleOptions: {
        entities: {
          additionalActions,
        },
      },
    }
  )
}

export function doPermanentlyDeleteCustomField(customFieldId) {
  return doTrashCustomField(customFieldId, 'HARD')
}

const restoreCustomField = `
  mutation CustomFieldRestore($customFieldId: ID!) {
    customFieldRestore(input: { customFieldId: $customFieldId }) {
      errors {
        message
        path
      }
      customField {
        id
      }
    }
  }
`
export function doRestoreCustomField(customFieldId) {
  return doAppGraphqlRequest(
    RESTORE_CUSTOM_FIELD,
    restoreCustomField,
    { customFieldId },
    {
      moduleOptions: {
        entities: {
          additionalActions: [
            {
              entityType: 'customFields',
              entityId: customFieldId,
              stores: ['current'],
              operation: 'update',
              phases: ['SUCCESS'],
              entity: {
                deletedAt: false,
              },
            },
          ],
        },
      },
    }
  )
}
