import { convertHexToRGBA } from 'util/colors'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { channel } from 'ducks/entities/schema'
import { selectCurrentChannelById } from 'ducks/channels/selectors'
import { customFieldFields } from 'ducks/crm/customFields/operations/fetching'
import { getRawId } from 'util/globalId'
import { CREATE_MAILBOX } from './actionTypes'

export const basicChannelFields = (options = {}) => `
  id
  name
  senderName
  email
  forwardEmailAddress
  fromName
  folders {
    nodes {
      id
      name
      type
    }
  }
  type
  state
  color
  restriction {
    type
    agents{
      id
    }
    teams {
      id
    }
  }
  permittedAgents(agentIds: [$agentId] ) {
    edges {
        node {
          id
        }
    }
  }
  createdAt
  position
  errors {
    ... on EmailEngineError {
      error_type: type
      error_message: message
    }
  }
  signature
  customFields {
    edges {
        node {
            ${
              options.loadFullCustomFields
                ? customFieldFields()
                : `
              id
              key
              `
            }
        }
    }
  }
`
export const allChannelFields = (options = {}) => `
  ${basicChannelFields(options)}
  aiDraftsKnowledgeBases {
    id
  }
  aiDraftsChannels {
    id
  }
  aiQualityRating
  outgoingEmailServer {
    ... on SmtpServer {
      id
      authentication
      username
      verified
      port
      protocol
      createdAt
      updatedAt
      encryption
      host
      verified
    }
  }
  incomingEmailServer {
    ... on ImapServer {
      id
      host
      verified
      username
      createdAt
      updatedAt
      protocol
      port
      host
      encryption
    }
  }
  useAgentName
  autoBccAddress
  emailTemplates {
    state
    subject
    body
    trigger
  }
`

export const channelQuery = `
  query ChannelQuery($id: ID!, $agentId: ID!) {
   node(id: $id) {
      ... on EmailChannel {
        ${allChannelFields()}
      }
      ... on DemoEmailChannel {
        ${allChannelFields()}
      }
    }
  }
`

export const collectionQuery = () => `
  query MailboxesQuery($agentId: ID!, $after: String) {
    channels(first: 10000, after: $after) {
      edges {
        node {
          ... on EmailChannel {
            ${basicChannelFields()}
          }
          ... on DemoEmailChannel {
            ${basicChannelFields()}
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      totalPageCount
    }
  }
`

export const channelCreateMutation = `
 mutation channelEmailCreate($input: EmailChannelCreateInput!, $agentId: ID!) {
    channelEmailCreate(input: $input) {
      channel {
        ${allChannelFields()}
      }
    }
  }
  `

export function generateUpdateMutation(responseFields) {
  return `
    mutation updateEmailChannel($input: EmailChannelUpdateInput!, $agentId: ID!) {
      channelEmailUpdate(input: $input) {
        channel {
          ${responseFields}
        }
      }
    }
  `
}

export const DESTROY_MUTATION = `
  mutation channelDelete($id: ID!) {
    channelDelete(input: { channelId: $id }) {
      deletedChannelId
      errors {
        path
        message
      }
    }
}
`
const doCreateChannel = (mailbox, queryId) => async (dispatch, getState) => {
  const agentId = selectCurrentUserGlobalId(getState())
  const mailboxInput = { ...mailbox }
  if (mailboxInput.color) {
    mailboxInput.color = convertHexToRGBA(mailboxInput.color)
  }
  if (!mailboxInput.senderName) {
    mailboxInput.senderName = mailboxInput.name
  }

  return dispatch(
    doGraphqlRequest(
      CREATE_MAILBOX,
      channelCreateMutation,
      {
        input: mailboxInput,
        agentId,
      },
      {
        app: true,
        throwOnError: true,
        normalizationSchema: channel,
        ...(queryId && {
          searches: {
            additionalActions: [
              {
                queryId,
                type: 'ADD_CURSOR_ENTITY_IDS',
                entityTypes: ['channel'],
                phases: ['SUCCESS'],
              },
            ],
          },
        }),
        transformError: () => ({
          error: `Unable to create ${app.t('mailbox')}, please try again`,
        }),
        transformResponse: data => {
          return data?.channelEmailCreate?.channel
        },
      }
    )
  )
}

export const doCreateAndFetchChannel = (data, queryId) => async (
  dispatch,
  getState
) => {
  const newChannel = await dispatch(doCreateChannel(data, queryId))
  const newChannelId = getRawId(newChannel?.id)
  if (newChannelId) return selectCurrentChannelById(getState(), newChannelId)
  return null
}
