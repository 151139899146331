import { createSelector } from 'reselect'
import { selectCurrentChannelsById } from 'ducks/channels/selectors'

// Our main selector. This returns a denormalized collection of mailboxes
// (keyed by ID), with each mailbox object having all the
// transformation/decorations applied.

export const selectMailboxesById = createSelector(
  selectCurrentChannelsById,
  mailboxes => mailboxes
)
