import { createSelector } from 'reselect'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'
import { getRawId } from 'util/globalId'
import { selectCurrentTicketMailboxId } from './selectCurrentTicketMailboxId'

export const selectCurrentMailboxId = createSelector(
  selectCurrentQuery,
  selectCurrentTicketMailboxId,
  (query, ticketMailboxId) => {
    const { channel: urlMailboxIds } = query
    const urlMailboxId = urlMailboxIds ? urlMailboxIds[0] : null

    return getRawId(urlMailboxId || ticketMailboxId || null)
  }
)
