import appGraphql from 'api/app_graphql'
import { STORED_DRAFT, FAILED_DRAFT } from 'ducks/drafts2/constants'
import { selectDraftById, selectDraftByTicketId } from 'ducks/drafts2/selectors'
import { isSaveable } from 'ducks/drafts2/util'
import { oauthTokenSelector } from 'selectors/app'
import windowUnload from 'util/windowUnload'
import { saveRequests } from './saveRequests'

export function doSendDraftToServer(inputDraftId, ticketId, draftType) {
  return (dispatch, getState) => {
    const state = getState()
    let draft
    if (!inputDraftId) {
      draft = selectDraftByTicketId(state, ticketId, draftType)
    } else {
      draft = selectDraftById(state, inputDraftId)
    }
    if (!draft) return
    const draftId = draft.id
    if (!isSaveable(draft)) {
      // eslint-disable-next-line import/no-named-as-default-member
      windowUnload.uninstall()
      return
    }

    const mutation = `
      mutation DraftSave(
        $draftId: ID!,
        $conversationId: ID!,
        $version: Int!,
        $payload: String!,
        $draftType: String!,
      ) {
        draftSave( input: {
          draftId: $draftId,
          conversationId: $conversationId,
          version: $version,
          payload: $payload,
          draftType: $draftType,
        }) {
          draftId
          version
          success
          payload
          errors {
            type
          }
        }
      }
    `

    const variables = {
      draftId,
      draftType: draft.draftType,
      conversationId: draft.ticketId,
      version: draft.version,
      payload: JSON.stringify(draft),
    }
    if (!variables.conversationId) return
    if (!variables.draftType) return
    if (!variables.version) return

    const token = oauthTokenSelector(state)
    saveRequests[draftId] = appGraphql(token, mutation, variables)
      .then(() => {
        const serverVersion = draft.version
        // eslint-disable-next-line import/no-named-as-default-member
        windowUnload.uninstall()
        dispatch({
          type: STORED_DRAFT,
          payload: { draftId, serverVersion },
        })
      })
      .catch(error => {
        // eslint-disable-next-line import/no-named-as-default-member
        windowUnload.uninstall()
        dispatch({
          type: FAILED_DRAFT,
          payload: { draftId, draft, error, variables },
        })
      })
  }
}
