import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectCurrentContact } from 'ducks/crm/contacts'

import { FETCH_CUSTOMER_ID_REQUEST, FETCH_CUSTOMER_REQUEST } from '../types'
import graphql from '../api'
import { selectCustomerIdByIntegrationIdAndEmail } from '../selectors'

const customerFragment = `
  id
  createdAt
  email
  taxExempt
  lastOrder {
    id
  }
  taxExemptions
  productSubscriberStatus
  numberOfOrders
  state
  tags
  lifetimeDuration
  amountSpent {
    amount
    currencyCode
  }
  addresses {
    formatted
    street: address1
    street2: address2
    city
    country
    postalCode: zip
    state: province
  }
  emailMarketingConsent {
    marketingOptInLevel
  }
  locale
  note
`

const searchQuery = `
  query ShopifySearchCustomers(
    $query: String!
  ) {
    customers(first: 1, query: $query) {
      edges {
        node {
          ${customerFragment}
        }
      }
    }
  }
`

const customerQuery = `
  query getShopifyCustomerQuery($customerId: ID!) {
    customer(id: $customerId) {
      ${customerFragment}
    }
  }
`

export const doFetchCustomerByEmail = (integrationId, email) => dispatch => {
  const variables = { query: `email:"${email}"` }

  return dispatch(
    doGraphqlRequest(FETCH_CUSTOMER_ID_REQUEST, searchQuery, variables, {
      graphqlFunc: graphql(integrationId),
      meta: {
        email,
        integrationId,
      },
    })
  ).then(data => {
    if (data?.customers?.edges?.length > 0) {
      const first = data.customers.edges[0]
      return first?.node?.id
    }
    return null
  })
}

export const doFetchCustomerById = integrationId => (dispatch, getState) => {
  const contact = selectCurrentContact(getState())
  if (!contact) return null

  const customer = selectCustomerIdByIntegrationIdAndEmail(
    getState(),
    integrationId,
    contact.email
  )
  const variables = { customerId: customer.id }

  return dispatch(
    doGraphqlRequest(FETCH_CUSTOMER_REQUEST, customerQuery, variables, {
      graphqlFunc: graphql(integrationId),
      meta: {
        integrationId,
      },
    })
  ).then(data => {
    if (data?.customer) {
      return data?.customer
    }
    return null
  })
}
