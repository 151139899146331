import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { uniq } from 'util/arrays'

import { ADD_CONTACTS_TO_COMPANY, REMOVE_CONTACTS_FROM_COMPANY } from '../types'

const addContactsToCompanyMutation = `
  mutation addContactsToCompany($input: CompanyAddContactsInput!) {
    companyAddContacts(input: $input) {
      clientMutationId
    }
  }
`

export function doAddContactsToCompany(companyId, contactIds) {
  return (dispatch, getState) => {
    if (!companyId || !contactIds || contactIds.length <= 0) return null
    const state = getState()
    const contactsById = state.crm.contacts.byId
    return dispatch(
      doAppGraphqlRequest(
        ADD_CONTACTS_TO_COMPANY,
        addContactsToCompanyMutation,
        { input: { companyId, contactIds } },
        {
          optimist: {
            entities: {
              contacts: contactIds.reduce((result, id) => {
                // eslint-disable-next-line no-param-reassign
                result[id] = {
                  companies: uniq([
                    ...(contactsById[id].companies || []),
                    companyId,
                  ]),
                  id,
                }
                return result
              }, {}),
            },
          },
          meta: {
            mergeEntities: true,
            requestKey: JSON.stringify([...(contactIds || []), companyId]),
          },
        }
      )
    )
  }
}

const removeContactsFromCompanyMutation = `
  mutation removeContactsFromCompany($input: CompanyRemoveContactsInput!) {
    companyRemoveContacts(input: $input) {
      clientMutationId
    }
  }
`

export function doRemoveContactsFromCompany(companyId, contactIds) {
  return (dispatch, getState) => {
    if (!companyId || !contactIds || contactIds.length <= 0) return null
    const state = getState()
    const contactsById = state.crm.contacts.byId
    return dispatch(
      doAppGraphqlRequest(
        REMOVE_CONTACTS_FROM_COMPANY,
        removeContactsFromCompanyMutation,
        { input: { companyId, contactIds } },
        {
          optimist: {
            entities: {
              contacts: contactIds.reduce((result, id) => {
                // eslint-disable-next-line no-param-reassign
                result[id] = {
                  companies: contactsById[id].companies.filter(
                    x => x !== companyId
                  ),
                  id,
                }
                return result
              }, {}),
            },
          },
          meta: {
            mergeEntities: true,
            requestKey: JSON.stringify([...contactIds, companyId]),
          },
        }
      )
    )
  }
}

export function doMoveContactToCompany(contactId, oldId, newId) {
  return dispatch => {
    if (oldId) {
      dispatch(doRemoveContactsFromCompany(oldId, [contactId]))
    }
    dispatch(doAddContactsToCompany(newId, [contactId]))
  }
}
