import { createBasicSelector } from 'util/redux'

import { selectCustomerIsReloading } from './customers'
import { selectSubscriptionsIsLoading } from './subscriptions'

export const selectIsLoading = createBasicSelector(
  selectCustomerIsReloading,
  (state, integrationId, { customerId }) =>
    selectSubscriptionsIsLoading(state, customerId),
  (customerReloading, subscriptionsLoading) => {
    return customerReloading || subscriptionsLoading
  }
)
