import { createBasicSelector } from 'util/redux'
import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'

import selectJiraProjectsById from './selectJiraProjectsById'

export default createBasicSelector(
  selectJiraProjectsById,
  (state, { projectId, siteId }) =>
    siteId ? `${siteId}:${projectId}` : projectId,
  (projects, projectId) => {
    const { issueTypes } = projects[projectId] || emptyObj
    return issueTypes || emptyArr
  }
)
