import { createBasicSelector } from 'util/redux'
import selectJiraProjectsById from './selectJiraProjectsById'

export default createBasicSelector(
  selectJiraProjectsById,
  (state, { projectId }) => projectId,
  (projectsById, projectId) => {
    const currentProject = projectsById[projectId]
    return currentProject && currentProject.site.id
  }
)
