import { createBasicSelector } from 'util/redux'

import selectTrelloBoardsById from './selectTrelloBoardsById'

export default createBasicSelector(
  selectTrelloBoardsById,
  (_, boardId) => boardId,
  (boards, boardId) => {
    const board = boards && boards[boardId]
    return board && board.loading
  }
)
