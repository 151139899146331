import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { customFields as customFieldNormalizationSchema } from 'ducks/entities/schema'
import { CREATE_CUSTOM_FIELD, DROPDOWN, UPDATE_CUSTOM_FIELD } from '../types'

import { customFieldFields } from './fetching'

const createQuery = `
  mutation CustomFieldCreate(
    $input: CustomFieldCreateInput!
  ) {
    customFieldCreate(input: $input) {
      customField {
        ${customFieldFields()}
      }
    }
  }
`

export function doCreateCustomField(input) {
  return async dispatch => {
    const { options, ...rest } = input // options currently unsupported due to GQL bug
    const mappedInput = input.type === DROPDOWN ? input : rest
    dispatch(
      doAppGraphqlRequest(
        CREATE_CUSTOM_FIELD,
        createQuery,
        { input: { ...mappedInput, isArray: false } },
        {
          normalizationSchema: {
            customFieldCreate: {
              customField: customFieldNormalizationSchema,
            },
          },
        }
      )
    )
  }
}

const updateQuery = `
  mutation CustomFieldUpdate(
    $input: CustomFieldUpdateInput!
  ) {
    customFieldUpdate(input: $input) {
      customField {
        ${customFieldFields()}
      }
    }
  }
`
export function doUpdateCustomField(input) {
  return async dispatch => {
    const { isArray, deletable, ...rest } = input
    dispatch(
      doAppGraphqlRequest(
        UPDATE_CUSTOM_FIELD,
        updateQuery,
        { input: rest },
        {
          normalizationSchema: {
            customFieldUpdate: {
              customField: customFieldNormalizationSchema,
            },
          },
        }
      )
    )
  }
}
