import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { selectCustomFields } from 'ducks/crm/customFields/selectors/base'
import { DELETE_CUSTOM_FIELD_CATEGORY } from '../types'

const deleteQuery = `
  mutation CustomFieldCategoryDelete(
    $input: CustomFieldCategoryDeleteInput!
  ) {
    customFieldCategoryDelete(input: $input) {
      deletedCategoryId
    }
  }
`
export function doDeleteCustomFieldCategory(categoryId, newCategoryId) {
  return async (dispatch, getState) => {
    const input = {
      categoryId,
    }
    if (newCategoryId) input.newCategoryId = newCategoryId

    const customFields = selectCustomFields(getState())
    const additionalActions = [
      {
        entityType: 'customFieldCategories',
        entityId: categoryId,
        stores: ['current'],
        operation: 'remove',
        phases: ['SUCCESS'],
      },
    ]
    customFields.forEach(cf => {
      if (cf.category === categoryId) {
        additionalActions.push({
          entityType: 'customFields',
          entityId: cf.id,
          stores: ['current'],
          operation: 'update',
          phases: ['SUCCESS'],
          entity: {
            category: newCategoryId,
            deletedAt: false,
          },
        })
      }
    })

    dispatch(
      doAppGraphqlRequest(
        DELETE_CUSTOM_FIELD_CATEGORY,
        deleteQuery,
        {
          input,
        },
        {
          meta: input,
          moduleOptions: {
            entities: {
              additionalActions,
            },
          },
        }
      )
    )
  }
}
