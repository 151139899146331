import { selectContactsByEmail } from 'ducks/crm/contacts/selectors/base'
import { doFetchCurrentContact } from 'ducks/crm/contacts/operations/fetching/doFetchCurrentContact'

import { doChangeDraft } from '.'

import { selectById as selectDraftsById } from '../selectors'

export const doSyncReplyDraftWithContacts = () => async (
  dispatch,
  getState
) => {
  let state = getState()

  const request = dispatch(doFetchCurrentContact())
  if (request) await request

  state = getState()
  const drafts = selectDraftsById(state)
  Object.keys(drafts).forEach(draftId => {
    const draft = drafts[draftId]

    const updates = {}
    const contactsByEmail = selectContactsByEmail(state)
    ;['to', 'cc', 'bcc'].forEach(recipientType => {
      const draftRecipients = draft[recipientType] || []
      const syncedRecipients = []
      let changed = false
      draftRecipients.forEach(draftRecipient => {
        const contact = contactsByEmail[draftRecipient.email]

        if (contact && contact.email === draftRecipient.email) {
          syncedRecipients.push(contact)
          changed = true
        } else {
          syncedRecipients.push(draftRecipient)
        }
      })
      if (changed) {
        updates[recipientType] = syncedRecipients
      }
    })
    if (Object.keys(updates).length > 0) {
      dispatch(
        doChangeDraft(draftId, draft.draftType, draft.ticketId, null, updates)
      )
    }
  })
}
