/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'

import { without, withPush } from 'util/arrays'
import storage from 'util/storage'
import { SET_GROUPS_BYID, OPEN_GROUP, CLOSE_GROUP } from '../actionTypes/groups'
import {
  BUILD_FROM_WIDGETS,
  BUILD_INBOX_MENU_FROM_MAILBOXES,
} from '../actionTypes/collections'
import {
  AGENT_GROUP_ID,
  GROUPS,
  LABEL_GROUP_ID,
  DEFAULT_OPEN_SECTIONS,
  OPEN_SECTIONS_KEY,
} from '../constants'

const groupsByIdInitialState = {}

export const groupsById = createActionTypeReducer(
  {
    [SET_GROUPS_BYID]: (_, { payload: { groups = [] } }) => {
      return groups.reduce((groupById, group) => {
        groupById[group.id] = group
        return groupById
      }, {})
    },
    [BUILD_FROM_WIDGETS]: draftState => {
      Object.keys(draftState).forEach(key => {
        delete draftState[key]
      })
      return draftState
    },
    [BUILD_INBOX_MENU_FROM_MAILBOXES]: (
      draftState,
      {
        payload: {
          mailboxes = [],
          prefersAllMailboxesSectionVisible = false,
          prefersUnifiedInbox = false,
          channelType: pageChannelType,
          agents,
        },
      }
    ) => {
      // Note the BUILD_INBOX_MENU_FROM_MAILBOXES pre-filters the mailboxes
      // to accessible mailboxes only
      const hasAccessToAtleastOneChannel = mailboxes.length > 0

      const allowedChannels = prefersUnifiedInbox ? [] : [...mailboxes]
      if (
        hasAccessToAtleastOneChannel &&
        (prefersUnifiedInbox ||
          (prefersAllMailboxesSectionVisible && allowedChannels.length > 1))
      ) {
        allowedChannels.unshift({
          gid: '',
          name: `All ${app.t('Mailboxes')}`,
          hasAccess: true,
          channelType: pageChannelType,
        })
      }

      allowedChannels.forEach(channel => {
        const { gid: channelId } = channel

        GROUPS.forEach((group, index) => {
          const { id: groupId, name, icon } = group
          if (groupId === AGENT_GROUP_ID && agents.length === 1) return

          const currentPosition = index + 1001

          const itemId = `${channelId}_${groupId}`
          draftState[itemId] = {
            id: itemId,
            resourceId: groupId,
            name: `${app.t(name)}`,
            icon,
            collectionId: channelId,
            channelType: pageChannelType,
            // Abit of a hack to ensure groups are always at the bottom of the list
            position: currentPosition,
          }
        })
      })
      return draftState
    },
  },
  groupsByIdInitialState
)

const openGroupIdsInitialState = []

export const openGroupIds = createActionTypeReducer(
  {
    [OPEN_GROUP]: (draftState, { payload: { groupId } }) => {
      const currentOpenSections =
        storage.get(OPEN_SECTIONS_KEY) || DEFAULT_OPEN_SECTIONS
      withPush(draftState, groupId)
      if (groupId.includes(LABEL_GROUP_ID)) {
        currentOpenSections.Tags = true
      } else if (groupId.includes(AGENT_GROUP_ID)) {
        currentOpenSections.Team = true
      }
      storage.set(OPEN_SECTIONS_KEY, currentOpenSections)
      return draftState
    },
    [CLOSE_GROUP]: (draftState, { payload: { groupId } }) => {
      const currentOpenSections =
        storage.get(OPEN_SECTIONS_KEY) || DEFAULT_OPEN_SECTIONS
      without(draftState, groupId)
      if (groupId.includes(LABEL_GROUP_ID)) {
        currentOpenSections.Tags = false
      } else if (groupId.includes(AGENT_GROUP_ID)) {
        currentOpenSections.Team = false
      }
      storage.set(OPEN_SECTIONS_KEY, currentOpenSections)
      return draftState
    },
  },
  openGroupIdsInitialState
)
