import createCachedSelector from 're-reselect'
import {
  selectCurrentEntities,
  selectCurrentEntityById,
} from 'ducks/entities/selectors'
import { emptyArr } from 'util/arrays'
import { isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import { createBasicSelector } from 'util/redux'

export const selectCurrentFeatureTemplates = state =>
  selectCurrentEntities(state, 'featureTemplate') || emptyArr

export const selectCurrentFeatureTemplatesForTypeAndCategory = createCachedSelector(
  selectCurrentFeatureTemplates,
  (_state, type) => type,
  (_state, _type, category) => category,
  (_state, _type, _category, popular) => popular,
  (featureTemplates, type, category, popular) => {
    return featureTemplates.filter(
      t =>
        (!type || t.type === type) &&
        (!category || t.categories.includes(category)) &&
        (isNullOrUndefined(popular) || t.popular === popular)
    )
  }
)(
  (_state, type, category, popular = false) =>
    `${type || 'UNKNOWN'}-${category || 'UNKNOWN'}-${popular}`
)

export const selectCurrentFeatureTemplatesForType = createCachedSelector(
  selectCurrentFeatureTemplates,
  (_state, type) => type,
  (featureTemplates, type) => {
    return featureTemplates.filter(t => t.type === type)
  }
)((_state, type) => type || 'UNKNOWN')

export const selectCurrentCategoryKeysForType = createCachedSelector(
  selectCurrentFeatureTemplatesForType,
  featureTemplates => {
    const categories = new Set()

    featureTemplates.forEach(t => {
      t.categories.forEach(categories.add, categories)
    })

    return Array.from(categories)
  }
)((_state, type) => type || 'UNKNOWN')

export const selectCurrentFeatureTemplateById = (state, id) =>
  selectCurrentEntityById(state, 'featureTemplate', id)

export const selectCurrentFeatureTemplateTemplateDataForId = createBasicSelector(
  selectCurrentFeatureTemplateById,
  featureTemplate => featureTemplate?.template || null
)
