import { createBasicSelector } from 'util/redux'
import { selectPreferences } from './selectPreferences'

export const selectBooleanPreferenceByName = createBasicSelector(
  selectPreferences,
  (_state, name) => name,
  (_state, _name, defaultValue) => defaultValue,
  (preferences, name, defaultValue) => {
    const preference = preferences[name]
    return preference !== undefined ? !!preference : defaultValue
  }
)
