export const CUSTOM_FIELD_CATEGORY_TYPE_CHANNEL = 'CHANNEL'
export const CUSTOM_FIELD_CATEGORY_TYPE_CONTACT = 'CONTACT'
export const CUSTOM_FIELD_CATEGORY_TYPE_COMPANY = 'COMPANY'

export const CUSTOM_FIELD_CATEGORY_DISPLAY_EXCLUDE_KEYS = [
  'contact_sessions',
  /^shopify_/,
  /^recharge_/,
]

export const DEFAULT_CATEGORY_KEY_ORDER = [
  'contact_information',
  'contact_social',
  'contact_professional',
  'contact_education',
  'contact_sessions',
]
