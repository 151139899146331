import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'
import { selectSearchQueryTitleByQueryId } from 'ducks/searches/selectors/selectSearchQueryTitleByQueryId'
import { PIN_SEARCH, UNPIN_SEARCH } from './actionTypes'
import { pinSearchGraphQlResponseSchema } from './schema'
import {
  selectPinnedSearchByQueryId,
  selectPinnedSearchesByChannelId,
} from './selectors'
import { toPinnedSearchQueryId } from './utils'

const pinSearchMutation = `
  mutation PinSearch($label: String, $query: String) {
    pinSearch(
      label: $label,
      query: $query,
    ) {
      success
      pinned_search {
        id
        label
        query
        position
      }
    }
  }
`

export const doPinSearch = (fullQueryId, options) => (dispatch, getState) => {
  const label = selectSearchQueryTitleByQueryId(getState(), fullQueryId)
  const queryId = toPinnedSearchQueryId(fullQueryId)

  return dispatch(
    doGraphqlRequest(
      PIN_SEARCH,
      pinSearchMutation,
      {
        label,
        query: queryId,
      },
      {
        ...options,
        app: false,
        normalizationSchema: pinSearchGraphQlResponseSchema,
        concurrency: {
          key: `pinsearch-${fullQueryId}`,
        },
      }
    )
  )
}

const unpinSearchMutation = `
  mutation UnpinSearch($id: String) {
    unpinSearch(
      id: $id,
    ) {
      success
    }
  }
`

export const doUnpinSearch = (queryId, options) => (dispatch, getState) => {
  const pinnedSearch = selectPinnedSearchByQueryId(getState(), queryId)
  if (!pinnedSearch) return null

  const pinnedSearchId = pinnedSearch.id

  return dispatch(
    doGraphqlRequest(
      UNPIN_SEARCH,
      unpinSearchMutation,
      {
        id: pinnedSearchId,
      },
      {
        ...options,
        app: false,
        concurrency: {
          key: `pinsearch-${pinnedSearchId}`,
        },
        moduleOptions: {
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                entityType: 'pinnedSearch',
                entityId: pinnedSearchId,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['STARTED'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doCanPinSearch = queryId => (_, getState) => {
  return selectSearchQueryTitleByQueryId(getState(), queryId)
}

export function doNavigateToPinnedSearchForNumKey(event) {
  return (dispatch, getState) => {
    const state = getState()
    const { channel = [] } = selectCurrentQuery(state)
    const pinnedSearches = selectPinnedSearchesByChannelId(state, channel[0])
    const { key } = event
    const keyOrTen = key === '0' ? 9 : key - 1
    const pinnedSearch = pinnedSearches[keyOrTen]
    if (!pinnedSearch) return false
    return dispatch(pinnedSearch.linkTo)
  }
}
