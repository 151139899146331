import createCachedSelector from 're-reselect'

import applyStateToQuery from './applyStateToQuery'
import selectQueryReadyForState from './selectQueryReadyForState'
import selectStateQuery from './selectStateQuery'
import queryCacheKey from './cacheKeys/queryCacheKey'

export default createCachedSelector(
  selectQueryReadyForState,
  selectStateQuery,
  (queryReadyForState, stateQuery) => {
    return applyStateToQuery(queryReadyForState, stateQuery)
  }
)(queryCacheKey)
