import {
  selectIsChannelOnboardingDrawerOpen,
  selectCurrentOpenDrawerResourceId,
} from 'ducks/drawers/selectors'
import { createSelector } from 'reselect'
import { selectCurrentMailboxId } from './selectCurrentMailboxId'

// Is the current mailbox in creation process

export const selectIsSettingUpTheCurrentMailBox = createSelector(
  selectCurrentMailboxId,
  selectIsChannelOnboardingDrawerOpen,
  selectCurrentOpenDrawerResourceId,
  (mailboxId, isChannelOnboardingDrawerOpen, currentOpenDrawerResourceId) => {
    return (
      isChannelOnboardingDrawerOpen && currentOpenDrawerResourceId === mailboxId
    )
  }
)
