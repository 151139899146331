import {
  selectCurrentEntitiesSortedBy,
  selectCurrentEntitiesById,
} from 'ducks/entities/selectors'
import {
  queryIdToQuery,
  queryStringToQueryId,
} from 'ducks/searches/utils/query'
import createCachedSelector from 're-reselect'
import { emptyArr } from 'util/arrays'
import { buildId } from 'util/globalId'
import { SEARCH_PAGE } from 'constants/pages'
import { createSelector } from 'reselect'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'
import {
  selectCurrentTagsById,
  selectCurrentTagsByName,
} from 'ducks/tags/selectors'
import { toPinnedSearchQueryId } from './utils'

export const selectPinnedSearchesById = state =>
  selectCurrentEntitiesById(state, 'pinnedSearch') || null

export const selectPinnedSearches = state =>
  selectCurrentEntitiesSortedBy(state, 'pinnedSearch', 'position') || emptyArr

export const selectPinnedSearchesByChannelId = createCachedSelector(
  selectPinnedSearches,
  (_state, channelId) => channelId,
  (pinnedSearches, channelId) => {
    if (pinnedSearches.length === 0) return emptyArr

    return pinnedSearches.map(ps => {
      const query = { ...queryIdToQuery(ps.query) }
      query.channel = channelId ? buildId('Channel', channelId) : undefined

      return {
        id: ps.id,
        queryId: queryStringToQueryId(query),
        title: ps.label,
        position: ps.position,
        linkTo: {
          type: SEARCH_PAGE,
          payload: {},
          meta: {
            query,
          },
        },
      }
    })
  }
)((_state, channelId) => channelId || 'unknown')

export const selectIsPinnedByQueryId = createCachedSelector(
  selectPinnedSearches,
  (_state, queryId) => queryId,
  (pinnedSearches, queryId) =>
    pinnedSearches.some(
      ps => toPinnedSearchQueryId(ps.query) === toPinnedSearchQueryId(queryId)
    )
)((_state, queryId) => queryId || 'unknown')

export const selectPinnedSearchByQueryId = createCachedSelector(
  selectPinnedSearches,
  (_state, queryId) => queryId,
  (pinnedSearches, fullQueryId) => {
    const queryId = toPinnedSearchQueryId(fullQueryId)
    return pinnedSearches.find(
      ps => toPinnedSearchQueryId(ps.query) === queryId
    )
  }
)((_state, queryId) => queryId || 'unknown')

export const selectIsPinnableByCurrentQueryId = createSelector(
  selectCurrentQuery,
  query => {
    return Object.keys(query).filter(k => k !== 'search').length > 0
  }
)

export const selectMissingPinnedSearchTagIdsAndNames = createSelector(
  selectPinnedSearches,
  selectCurrentTagsById,
  selectCurrentTagsByName,
  (pinnedSearches, tagsById, tagsByName) => {
    return pinnedSearches.reduce((missing, pinnedSearch) => {
      const { tag } = queryIdToQuery(pinnedSearch.query)
      if (tag && !tagsById[tag] && !tagsByName[tag]) {
        missing.push(tag)
      }
      return missing
    }, [])
  }
)
