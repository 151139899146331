import { basicFields as basicAgentFields } from 'api/agents'

export const signupFields = `
  id
  email
  unconfirmedEmail
  token
  oauthAuthenticatable
  confirmed
  confirmationRequired
  fullAccess
  subdomain
  role
`

// NOTE (jscheel): Not sure this is really "basic" fields, but sticking with
// it for now, for the sake of expediency.
export const basicFields = `
  ${basicAgentFields}
  chatPrivateRoomFullAlias
  preferredMailboxId
  flags
  oauthAuthenticatable
  confirmed
  confirmedAt
  confirmationRequired
  fullAccess
  chatAccessToken
  unreadChannelTypes
  timezone
  alternateEmails
  title
  twitterUsername
  pinned_searches {
    id
    label
    query
    position
  }
  preferences {
    unified_inbox
    default_reply_state
    open_folder_on_note_add
    open_folder_on_ticket_reply
    open_folder_on_ticket_save
    reassign_ticket_on_reply
    reassign_ticket_on_note
    show_avatars_in_messages
    autoload_first_ticket_in_list
    autoadvance_to_next_ticket
    classic_view
    prefers_agents_can_move_to_inaccessible_mailboxes
    undo_send_delay
    prefers_v2_show_all_mailboxes_section
    show_kb_search_on_inbox_editor
    speedy_groove
    visible_in_widget
    chat_linked
    chat_auto_assign_on_reply
    chat_auto_assign_on_note
    chat_auto_advance_on_close
    chat_auto_advance_on_snoozed
    chat_auto_advance_on_open
    chat_auto_advance_on_spam
    chat_auto_advance_on_trash
    chat_auto_advance_on_assign
    chat_auto_advance_on_follow
    availability
    inbox_theme
    conversation_preview_on_hover
    personal_usage_onboarding
    nps_next_timestamp
    default_current_mailbox_search
    timezone {
      account {
        id
        offset
      }
      agent {
        id
        offset
      }
    }
  }
`

export const changePasswordMutation = `
  mutation ChangePassword($input: UserInput!) {
    updateCurrentUser(input: $input) {
      id
    }
  }
`

export const updateCurrentUserMutation = `
  mutation UpdateCurrentUser($input: UserInput!) {
    updateCurrentUser(input: $input) {
      ${basicFields}
    }
  }
`

export const updateCurrentUserSignupMutation = `
  mutation UpdateCurrentUser($input: UserInput!) {
    updateCurrentUser(input: $input) {
      ${signupFields}
    }
  }
`

export const confirmCurrentUserMutation = `
  mutation ConfirmCurrentUser($input: UserConfirmationCodeInput!) {
    confirmCurrentUser(input: $input) {
      ${signupFields}
    }
  }
`
export const currentUserQuery = () => `
  query CurrentUserQuery {
    user {
      ${basicFields}
    }
  }
`
