export const REQUIRED_CUSTOM_FIELD_KEYS = ['contact_email']
export const DEFAULT_CUSTOM_FIELD_KEY_ORDER = [
  'contact_avatar',
  'contact_avatar_url',
  'contact_first_name',
  'contact_last_name',
  'contact_email',
  'contact_secondary_emails',
]

export const HANDLE_TYPE_EMAIL = 'EMAIL'
export const HANDLE_TYPE_FACEBOOK = 'FACEBOOK'
export const HANDLE_TYPE_TWITTER = 'TWITTER'
export const HANDLE_TYPE_LINKEDIN = 'LINKEDIN'
export const HANDLE_TYPE_DOMAIN = 'DOMAIN'
export const HANDLE_TYPE_SHOPIFY = 'SHOPIFY'
export const HANDLE_TYPE_FACEBOOK_PSID = 'FACEBOOK_PSID'
export const HANDLE_TYPE_RECHARGE = 'RECHARGE'
export const HANDLE_TYPE_CUSTOM = 'CUSTOM'

export const DATE_TYPE_STRING_FORMAT = '%Y-%m-%d'

export const CUSTOM_FIELD_VALUE_UPDATE_MODE = {
  APPEND: 'APPEND',
  REPLACE: 'REPLACE',
}
