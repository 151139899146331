import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { capture } from 'ducks/tracking/actions'
import { selectSuggestionForType } from 'ducks/ai/selectors/suggestions'
import { selectAiConversationRequest } from 'ducks/ai/selectors/selectAiConversationRequest'
import { buildId } from 'util/globalId'
import debug from 'util/debug'
import {
  responseQuery,
  getConversationSuggestionsQuery,
  upsertConversationSuggestionQuery,
  getSoucesDataQuery,
} from './queries'
import {
  AI_REQUEST,
  FETCH_AI_JOB_INSTANT_REPLY_RESULT,
  FETCH_AI_JOB_CONVERSATION_RESULT,
  INSTANT_REPLY_TYPE,
  CONVERSATION_TYPE,
  ACTION_RPC_METHOD_MAP,
  FETCH_AI_JOB_COMPOSER_RESULT,
  COMPOSER_TYPE,
  FETCH_AI_SUGGESTIONS,
  UPSERT_AI_SUGGESTION,
  UPDATE_REALTIME_AI_SUGGESTION,
  AI_STREAMING_UPDATE,
  UPDATE_AI_SUGGESTION_AUTO_GENERATED,
  FETCH_AI_SOURCES,
} from './types'

export const getAiType = requestType => {
  switch (requestType) {
    case INSTANT_REPLY_TYPE:
      return FETCH_AI_JOB_INSTANT_REPLY_RESULT
    case CONVERSATION_TYPE:
      return FETCH_AI_JOB_CONVERSATION_RESULT
    case COMPOSER_TYPE:
      return FETCH_AI_JOB_COMPOSER_RESULT
    default:
      throw new Error('Unhandled AI type')
  }
}

export const doFetchJobResult = (jobId, options = {}) => {
  const requestType = getAiType(options.requestType)
  return doAppGraphqlRequest(requestType, responseQuery, { id: jobId }, options)
}

export const doAiFailed = requestType => dispatch =>
  dispatch({
    type: `${getAiType(requestType)}_FAILED`,
  })

export const doCancelAiResult = requestType => dispatch =>
  dispatch({
    type: `${getAiType(requestType)}_CANCEL`,
  })

export const doClearState = requestType => dispatch => {
  dispatch({
    type: `${getAiType(requestType)}_CLEAR`,
  })
}

export const doHidePrompt = requestType => dispatch => {
  dispatch({
    type: `${getAiType(requestType)}_HIDE`,
  })
}

export const doInitializeRequest = (requestType, aiType) => dispatch => {
  dispatch({
    type: `${getAiType(requestType)}_INITIALIZE`,
    payload: {
      aiType,
    },
  })
}

export const doAiEnd = (requestType, retryVariables, retryOptions, value) => (
  dispatch,
  getState
) => {
  const state = getState()
  const { params } = retryVariables

  if (requestType === FETCH_AI_JOB_CONVERSATION_RESULT) {
    const { startDate, aiType } = selectAiConversationRequest(state)

    if (startDate) {
      capture(`ai ${aiType} finished`, {
        ticket_id: params.conversationId,
        totalTime: (new Date() - startDate) / 1000,
      })
    }
  }

  dispatch({
    type: `${requestType}_END`,
    payload: {
      result: value,
      loading: false,
      loaded: true,
      error: null,
      lastRequest: {
        variables: retryVariables,
        options: retryOptions,
      },
    },
  })
}

export const doAiRequest = (query, variables, options = {}) => {
  return doAppGraphqlRequest(AI_REQUEST, query, variables, options)
}

export const getRpcMethodFromActionId = id => {
  return ACTION_RPC_METHOD_MAP[id] || ''
}

export const doFetchAiConversationSuggestions = input => dispatch => {
  const { ticketId } = input

  return dispatch(
    doAppGraphqlRequest(FETCH_AI_SUGGESTIONS, getConversationSuggestionsQuery, {
      conversationId: ticketId,
    })
  )
}

export const doUpsertAiConversationSuggestion = input => dispatch => {
  const { ticketId, suggestionType, suggestionId } = input
  const ticketGid = buildId('Conversation', ticketId)

  return dispatch(
    doAppGraphqlRequest(
      UPSERT_AI_SUGGESTION,
      upsertConversationSuggestionQuery,
      {
        suggestionId,
        conversationId: ticketGid,
        suggestionType: suggestionType.toUpperCase(),
      },
      {
        concurrency: {
          key: ticketGid,
        },
      }
    )
  )
}

export const doUpsertAiConversationSuggestions = input => (
  dispatch,
  getState
) => {
  const state = getState()
  const {
    ticketId,
    suggestionTypes,
    isAutoGenerated = false,
    skipLoaded = false,
  } = input

  const sentimentSuggestion = selectSuggestionForType(state, ticketId, {
    type: 'sentiment',
  })
  const summarySuggestion = selectSuggestionForType(state, ticketId, {
    type: 'summary',
  })
  const tagsSuggestion = selectSuggestionForType(state, ticketId, {
    type: 'tags',
  })
  const suggestions = {
    sentiment: sentimentSuggestion,
    summary: summarySuggestion,
    tags: tagsSuggestion,
  }

  suggestionTypes.forEach(suggestionType =>
    dispatch({
      type: UPDATE_AI_SUGGESTION_AUTO_GENERATED,
      payload: {
        ticketId,
        suggestionType,
        isAutoGenerated,
      },
    })
  )

  const suggestionTypesToFetch = !skipLoaded
    ? suggestionTypes
    : suggestionTypes.filter(
        type => !suggestions[type].suggestion.id && !suggestions[type].isLoading
      )

  return Promise.all(
    suggestionTypesToFetch.map(type =>
      dispatch(
        doUpsertAiConversationSuggestion({
          suggestionType: type,
          suggestionId: suggestions[type].suggestion.id,
          ticketId,
        })
      )
    )
  )
}

export const doRealtimeAiEvent = message => dispatch => {
  const { data } = message

  dispatch({
    type: UPDATE_REALTIME_AI_SUGGESTION,
    payload: data,
  })
}

export const doRealtimeAiStreamingEvent = message => dispatch => {
  const { data, meta } = message
  dispatch({
    type: AI_STREAMING_UPDATE,
    payload: {
      content: data.content,
      conversationId: data.conversation_id,
      action: meta.action,
      sources: data.sources,
    },
  })
}

function logIfNotPermissionError(error) {
  const errorMessage = error.errors?.[0]?.message
  if (!errorMessage?.includes('hidden due to permissions')) {
    debug(errorMessage)
  }
}

export const doFetchSourcesRequest = ids => async dispatch => {
  try {
    await dispatch(
      doAppGraphqlRequest(
        FETCH_AI_SOURCES,
        getSoucesDataQuery,
        { ids },
        // Certain conversation nodes might be hidden due to inbox permissions
        // throw an error to ensure we can still get nodes that aren't hidden
        // otherwise the error will be ignored and the action will be marked as successful with no data.
        { throwOnError: true }
      )
    )
  } catch (error) {
    logIfNotPermissionError(error)
  }
}
