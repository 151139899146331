import { selectCurrentEntitiesById } from 'ducks/entities/selectors'
import { createBasicSelector } from 'util/redux'

export function selectBase(state) {
  return state.crm.companies
}

export function selectCompaniesById(state) {
  return selectCurrentEntitiesById(state, 'companies')
}

export function selectCompanyStatesById(state) {
  return selectBase(state).byId
}

export const selectCompanyById = createBasicSelector(
  selectCompaniesById,
  (_state, companyId) => companyId,
  (companiesById, companyId) => companiesById[companyId]
)

export function selectLatestLoadedRequestKey(state) {
  return selectBase(state).latestLoadedRequestKey
}

export function selectLatestLoadedSearchTerm(state) {
  return selectBase(state).latestLoadedSearchTerm
}

export function selectLatestRequestKey(state) {
  return selectBase(state).latestRequestKey
}

export function selectRequestsByKey(state) {
  return selectBase(state).byRequestKey
}

export function selectPageCursor(state) {
  return selectBase(state).cursor
}

export function selectTotalCompaniesCount(state) {
  return selectBase(state).totalCount
}
