import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { buildId } from 'util/globalId'
import { USERS_RANGES } from 'subapps/settings/components/UserRestrictionTypeDropdown'
import {
  RESTRICTION_TYPE_ME,
  RESTRICTION_TYPE_USERS,
} from 'ducks/teams/constants'
import { getFolderKind } from './getFolderKind'

export function calculateFolderAccess(data, { getState }) {
  const state = getState()
  const currentUser = selectCurrentUser(state)
  const {
    teams: { nodes: teams },
    folders: { nodes: folders },
  } = data

  folders.forEach(folder => {
    const {
      channelRestriction,
      restriction: {
        type: restrictionType,
        agents: folderAgents,
        teams: folderTeams,
      },
    } = folder

    const currentUserGlobalId = buildId('Agent', currentUser.id)
    const groupsWithCurrentUserAccess = teams.filter(team =>
      team.agents.edges.some(ae => ae.node.id === currentUserGlobalId)
    )

    let hasAccess
    if (
      [RESTRICTION_TYPE_USERS, RESTRICTION_TYPE_ME].includes(restrictionType)
    ) {
      hasAccess = folderAgents.some(fa => fa.id === currentUserGlobalId)
    } else if (restrictionType === USERS_RANGES.TEAMS) {
      hasAccess = folderTeams.some(ft =>
        groupsWithCurrentUserAccess.some(g => g.id === ft.id)
      )
    } else {
      hasAccess = true
    }

    Object.assign(folder, {
      channelVisibility: channelRestriction === 'ALL' ? 'all' : 'selected',
      viewAccess: restrictionType,
      hasAccess,
      kind: getFolderKind(folder, { currentUserId: currentUser.id }),
    })
  })

  Object.assign(data, {
    currentUser,
  })

  return data
}
