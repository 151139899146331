import createCachedSelector from 're-reselect'
import { selectCurrentTicketMailboxId } from 'ducks/mailboxes/selectors/selectCurrentTicketMailboxId'
import selectIntegrationSettingsForId from 'ducks/integrations/selectors/selectIntegrationSettingsForId'
import { selectWidgetFromUrl } from 'ducks/widgets/selectors/widgets'
import { selectIsInInbox, selectIsInChat } from 'selectors/location'
import {
  CHATS_PREFIX,
  MAILBOXES_PREFIX,
} from 'ducks/integrations/utils/integrationSettingPrefix'
import { isEmpty } from 'util/objects'

const selectCanDisplayForCurrentConversation = createCachedSelector(
  selectIntegrationSettingsForId,
  selectCurrentTicketMailboxId,
  selectWidgetFromUrl,
  selectIsInChat,
  selectIsInInbox,
  (
    integrationSettingsForId,
    currentTicketMailboxId,
    currentWidget,
    isInChat,
    isInInbox
  ) => {
    if (
      (!currentTicketMailboxId && !currentWidget) ||
      isEmpty(integrationSettingsForId)
    ) {
      return false
    }

    let settingKey
    if (isInInbox) {
      settingKey = `${MAILBOXES_PREFIX}${currentTicketMailboxId}`
    } else if (isInChat) {
      settingKey = `${CHATS_PREFIX}${currentWidget?.id}`
    }

    const setting = integrationSettingsForId[settingKey]

    // not all credentials have the mailbox setting e.g. 'mailboxes.123456: true'
    return setting === undefined ? true : setting
  }
)((_, integrationId) => integrationId || 'unknown')

export default selectCanDisplayForCurrentConversation
