import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectCurrentContactId } from 'ducks/crm/contacts'
import { selectAccountPreferenceSortByCollaboratorCommentEnabled } from 'selectors/app/selectAccountPreferences'
import { selectIsWidgetBootstrapInitial } from 'ducks/widgets/selectors/status'
import { doBootstrapWidgets } from 'ducks/widgets/operations/bootstrap'
import { ROOM } from 'ducks/integrations/recentConversations/types'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import { doFetchRecentConversationsAndRooms } from './operations'
import {
  selectIsOrWillBeLoadingRecentConversationsAndChatsForCurrentContact,
  selectRecentConversationsAndChatsForCurrentContact,
} from './selectors'

export function useRecentConversations() {
  const dispatch = useDispatch()
  const contactId = useSelector(selectCurrentContactId)
  const sortByCollaboratorCommentAtEnabled = useSelector(
    selectAccountPreferenceSortByCollaboratorCommentEnabled
  )
  const isWidgetBootstrapInitial = useSelector(selectIsWidgetBootstrapInitial)
  const currentTicketId = useSelector(selectCurrentTicketId)

  useEffect(
    () => {
      if (contactId)
        dispatch(
          doFetchRecentConversationsAndRooms(
            contactId,
            sortByCollaboratorCommentAtEnabled
          )
        )
    },
    [dispatch, contactId, sortByCollaboratorCommentAtEnabled]
  )
  const conversations = useSelector(
    selectRecentConversationsAndChatsForCurrentContact
  )

  useEffect(
    () => {
      if (!isWidgetBootstrapInitial || !conversations) return

      if (conversations.some(c => c.type === ROOM)) {
        dispatch(doBootstrapWidgets())
      }
    },
    [dispatch, conversations, isWidgetBootstrapInitial]
  )

  return {
    isLoading: useSelector(
      selectIsOrWillBeLoadingRecentConversationsAndChatsForCurrentContact
    ),
    hasConversations: conversations?.length > 0,
    conversations,
    currentTicketId,
  }
}
