import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'
import { emptyArr } from 'util/arrays'

import { selectRoomFromUrl } from './rooms'

export function selectBotStateBase(state) {
  const { chat: { botState = emptyObj } = emptyObj } = state
  return botState
}

export const selectBySkillId = createBasicSelector(
  selectBotStateBase,
  base => base.bySkillId || emptyObj
)

export const selectWidgetIdsRunningOfflineAgents = createBasicSelector(
  selectBySkillId,
  bySkillId => bySkillId.offline_agents || emptyArr
)

export const selectIsOfflineAgentsBotRunningForRoomFromUrl = createSelector(
  selectRoomFromUrl,
  selectWidgetIdsRunningOfflineAgents,
  (room, widgetIds) => widgetIds.includes(room.channelId)
)

export const selectIsOfflineAgentsBotRunningForWidgetId = createBasicSelector(
  selectWidgetIdsRunningOfflineAgents,
  (_, widgetId) => widgetId,
  (widgetIds, widgetId) => widgetIds.includes(widgetId)
)
