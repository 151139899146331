import moment from 'moment-timezone-all'
import React from 'react'

import { relativeToAbsolute } from 'util/date'
import { isDefined, isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import DatePickerView from './DatePickerView'
import { END_DATE, START_DATE } from './constants'

export default class DatePicker extends React.PureComponent {
  // Used by constructor so need to be placed ahead of it
  // eslint-disable-next-line react/sort-comp
  initialMonth = () => {
    const { endDate, startDate } = this.props
    const endMonth = endDate.toLocaleDateString('en-US', {
      month: 'numeric',
      year: 'numeric',
    })
    const startMonth = startDate.toLocaleDateString('en-US', {
      month: 'numeric',
      year: 'numeric',
    })
    if (startMonth === endMonth) {
      const returnDate = new Date(
        startDate.getUTCFullYear(),
        startDate.getMonth(),
        1
      )
      returnDate.setMonth(returnDate.getMonth() - 1)
      return returnDate
    }
    const returnDate = new Date(endDate.getUTCFullYear(), endDate.getMonth(), 1)
    returnDate.setMonth(returnDate.getMonth() - 1)
    return returnDate
  }

  state = {
    month: this.initialMonth(),
  }

  componentDidMount() {
    this.coerceRange()
  }

  componentWillUpdate(prevProps) {
    if (prevProps.maxDays !== this.props.maxDays) {
      this.coerceRange()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.resetMonthTimeout)
  }

  disabledDays = () => {
    const { earliestStartDate, maxDays, minDays } = this.props
    let minDaysNumber = null
    if (isDefined(minDays)) {
      minDaysNumber = Number(minDays)

      if (isNaN(minDaysNumber)) {
        minDaysNumber = null
      }
    }

    let after = new Date()
    if (this.state.focusedInput === END_DATE && maxDays) {
      const startDate = this.state.pendingStartDate || this.state.startDate
      after = moment(startDate)
        .add(maxDays - 1, 'day')
        .toDate()
    }
    const before = isNullOrUndefined(minDaysNumber)
      ? moment(earliestStartDate).toDate()
      : moment(after)
          .subtract(minDays - 1, 'days')
          .toDate()

    return {
      before, // ensure this is a date and not a moment object
      after,
    }
  }

  doChangeFocus = focusedInput => {
    this.setState({
      focusedInput,
    })
  }

  endDate = () => {
    return this.props.endDate || this.state.endDate
  }

  handleApply = timeframe => {
    const { end, start } =
      typeof timeframe === 'string'
        ? relativeToAbsolute(timeframe)
        : timeframe || {}
    const { pendingEndDate, pendingStartDate } = this.state
    const startDateOrPending = start || pendingStartDate
    const endDateOrPending = end || pendingEndDate
    this.setState({
      endDate: endDateOrPending.toDate
        ? endDateOrPending.toDate()
        : endDateOrPending,
      focusedInput: null,
      pendingEndDate: null,
      pendingStartDate: null,
      startDate: startDateOrPending.toDate
        ? startDateOrPending.toDate()
        : startDateOrPending,
    })
    clearTimeout(this.resetMonthTimeout)
    this.resetMonthTimeout = setTimeout(this.resetMonth, 250) // Give animations a chance to complete

    if (this.props.onApply) {
      if (typeof timeframe === 'string') {
        this.props.onApply(timeframe)
      } else {
        this.props.onApply(moment(startDateOrPending), moment(endDateOrPending))
      }
    }
  }

  handleCancel = () => {
    const { onCancel } = this.props
    this.setState({
      focusedInput: null,
      pendingStartDate: null,
      pendingEndDate: null,
    })
    clearTimeout(this.resetMonthTimeout)
    this.resetMonthTimeout = setTimeout(this.resetMonth, 250) // Give animations a chance to complete
    if (onCancel) onCancel()
  }

  handleDatesChange = () => {}

  handleDateClick = (date, modifiers = {}) => {
    const { maxDays } = this.props
    if (modifiers.disabled) {
      return
    }

    let clampedDate = date
    let newState = {}
    // If the user is setting an end date
    if (this.state.focusedInput === END_DATE) {
      const startDate = this.state.pendingStartDate || this.props.startDate
      if (clampedDate <= startDate) {
        // If the user is actually selecting a new start date because they chose
        // a date that is before the first start date, handle this as a new start
        // date instead of the end date.
        newState = {
          focusedInput: END_DATE,
          pendingStartDate: clampedDate,
          pendingEndDate: clampedDate,
        }
      } else {
        // If the date picket has a maximum number of days allowed, clamp end
        // date to the maximum. This is actually not 100% necessary, as we disable
        // the max days later too, but this ensures we are properly clamped.
        if (maxDays) {
          clampedDate = moment
            .min(moment(clampedDate), moment(startDate).add(maxDays - 1, 'day'))
            .toDate()
        }
        newState = {
          focusedInput: START_DATE,
          pendingStartDate: startDate,
          pendingEndDate: clampedDate,
        }
      }
      const currentMonthNum = this.state.month.getMonth()
      const pendingEndMonthNum = newState.pendingEndDate.getMonth()
      // If the clamped end date is somehow out of view, move the view back so
      // that the user can see the selected end date again.
      if (
        pendingEndMonthNum < currentMonthNum ||
        pendingEndMonthNum > currentMonthNum + 2
      ) {
        newState.month = new Date(
          newState.pendingEndDate.getUTCFullYear(),
          pendingEndMonthNum - 1,
          1
        )
      }
    } else {
      const startDate = this.state.pendingStartDate || this.state.startDate
      const endDate = this.state.pendingEndDate || this.state.endDate
      if (
        startDate &&
        endDate &&
        clampedDate > startDate &&
        clampedDate < endDate
      ) {
        newState = {
          focusedInput: START_DATE,
          pendingStartDate: startDate,
          pendingEndDate: clampedDate,
        }
      } else {
        // If the user is selecting a starting date
        newState = {
          focusedInput: END_DATE,
          pendingStartDate: clampedDate,
          pendingEndDate: clampedDate,
        }
      }
    }
    this.setState(newState)
  }

  handleFocusChange = focusedInput => {
    this.setState({
      focusedInput,
    })
  }

  handleMonthChange = month => {
    this.setState({ month })
  }

  pendingOrEndDate = () => {
    const date = this.state.pendingEndDate || this.props.endDate
    if (date && date instanceof Date && date > new Date()) {
      return new Date()
    }

    return date
  }

  pendingOrStartDate = () => {
    return this.state.pendingStartDate || this.props.startDate
  }

  resetMonth = () => {
    this.setState({
      month: this.initialMonth(),
    })
  }

  startDate = () => {
    return this.props.startDate || this.state.startDate
  }

  coerceRange = () => {
    const { maxDays, presets } = this.props
    if (!maxDays) return

    const start = this.startDate()
    const end = this.endDate()
    const msDiff = end.getTime() - start.getTime()
    const dayDiff = msDiff / (1000 * 3600 * 24)
    if (dayDiff > maxDays) {
      if (presets) {
        this.handleApply(Object.keys(presets)[0])
      } else {
        this.handleApply('today')
      }
    }
  }

  render() {
    return (
      <DatePickerView
        {...this.props}
        disabledDays={this.disabledDays()}
        endDate={this.endDate()}
        focusedInput={
          this.props.focusedInput === undefined
            ? this.state.focusedInput
            : this.props.focusedInput
        }
        month={this.state.month}
        onApply={this.handleApply}
        onCancel={this.handleCancel}
        onDayClick={this.handleDateClick}
        onDatesChange={this.handleDatesChange}
        onFocusChange={this.handleFocusChange}
        onMonthChange={this.handleMonthChange}
        pendingOrEndDate={this.pendingOrEndDate()}
        pendingOrStartDate={this.pendingOrStartDate()}
        closedCount={this.state.closedCount}
        startDate={this.startDate()}
      />
    )
  }
}
