import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { calculateFolderAccess } from 'ducks/folders/utils/calculateFolderAccess'
import { doAddCustomFieldsToQueryConfig } from 'ducks/searches/operations/doAddCustomFieldsToQueryConfig'
import { BOOTSTRAP_APP_DATA } from './types'
import { bootstrapQuery } from './queries'
import { bootstrapSchema } from './schema'

const joinTransforms = transformFunctions => {
  return (data, store, options) => {
    const copiedData = { ...data }
    return transformFunctions.reduce((modifiedData, transform) => {
      return transform(modifiedData, store, options)
    }, copiedData)
  }
}

const calculateBootstrapFolderAccess = (data, store, options) => {
  return {
    ...data,
    ...calculateFolderAccess(
      {
        teams: {
          nodes: data?.teams?.edges?.map(edge => edge.node),
        },
        folders: { nodes: data?.folders?.nodes },
      },
      store,
      {
        ...options,
        totalChannels: data?.channels?.totalCount,
      }
    ),
  }
}

// Bootstap function for new GQL API, so we can gradually port types over.
export const doFetchAppBootstrapData = () => async (dispatch, getState) => {
  const agentId = selectCurrentUserGlobalId(getState())
  if (!agentId) return null

  const bootstrapRequest = await dispatch(
    doGraphqlRequest(
      BOOTSTRAP_APP_DATA,
      bootstrapQuery(agentId),
      { agentId },
      {
        normalizationSchema: bootstrapSchema,
        app: true,
        transformResponse: joinTransforms([calculateBootstrapFolderAccess]),
      }
    )
  )
  dispatch(doAddCustomFieldsToQueryConfig())
  return bootstrapRequest
}
