import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_AI_SUGGESTIONS_STARTED,
  FETCH_AI_SUGGESTIONS_SUCCESS,
  FETCH_AI_SUGGESTIONS_FAILED,
  UPSERT_AI_SUGGESTION_STARTED,
  UPDATE_REALTIME_AI_SUGGESTION,
  UPDATE_AI_SUGGESTION_AUTO_GENERATED,
} from 'ducks/ai/types'
import {
  FETCH_TAGS_BY_IDS_NAMES_STARTED,
  FETCH_TAGS_BY_IDS_NAMES_SUCCESS,
} from 'ducks/tags/actionTypes'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { getRawId } from 'util/globalId'

const initialState = {
  byTicketId: {},
}

export const initialSingleState = {
  isLoading: false,
  isLoaded: false,
  tags: {
    isLoading: false,
    isLoaded: false,
    isLoadingTagNames: false,
    isLoadedTagNames: false,
    isAutoGenerated: false,
    suggestion: {},
  },
  sentiment: {
    isLoading: false,
    isLoaded: false,
    isAutoGenerated: false,
    suggestion: {},
  },
  summary: {
    isLoading: false,
    isLoaded: false,
    isAutoGenerated: false,
    suggestion: {},
  },
}

export default createActionTypeReducer(
  {
    [FETCH_AI_SUGGESTIONS_STARTED]: (draftState, { payload }) => {
      const { conversationId } = payload

      if (!draftState.byTicketId[conversationId]) {
        draftState.byTicketId[conversationId] = Object.assign(
          {},
          initialSingleState
        )
      }

      draftState.byTicketId[conversationId].isLoading = true

      return draftState
    },
    [FETCH_AI_SUGGESTIONS_SUCCESS]: (draftState, { payload, meta }) => {
      const { conversationId } = meta.requestParameters
      const aiConversationSuggestions = payload.aiConversationSuggestions.nodes

      draftState.byTicketId[conversationId].isLoading = false
      draftState.byTicketId[conversationId].isLoaded = true

      aiConversationSuggestions.forEach(suggestion => {
        draftState.byTicketId[conversationId][
          suggestion.type
        ].suggestion = suggestion
        draftState.byTicketId[conversationId][suggestion.type].isLoading = false
        draftState.byTicketId[conversationId][suggestion.type].isLoaded = true
      })

      return draftState
    },
    [FETCH_AI_SUGGESTIONS_FAILED]: (draftState, { meta }) => {
      const { conversationId } = meta.requestParameters

      draftState.byTicketId[conversationId].isLoading = false
      draftState.byTicketId[conversationId].isLoaded = true

      return draftState
    },
    [UPDATE_REALTIME_AI_SUGGESTION]: (draftState, { payload }) => {
      const { conversation_id: conversationId } = payload
      const suggestion = {
        conversationId: conversationId.toString(),
        id: payload.id,
        value: payload.value,
        type: payload.type,
      }

      if (!draftState.byTicketId[conversationId]) {
        draftState.byTicketId[conversationId] = {}
        draftState.byTicketId[conversationId] = {
          isLoading: false,
          isLoaded: false,
          tags: {
            isLoading: false,
            isLoaded: false,
            isLoadingTagNames: false,
            isLoadedTagNames: false,
            suggestion: {},
          },
          sentiment: {
            isLoading: false,
            isLoaded: false,
            suggestion: {},
          },
          summary: {
            isLoading: false,
            isLoaded: false,
            suggestion: {},
          },
        }
      }

      draftState.byTicketId[conversationId][suggestion.type].isLoading = false
      draftState.byTicketId[conversationId][suggestion.type].isLoaded = true

      draftState.byTicketId[conversationId][suggestion.type].suggestion = {
        ...suggestion,
      }
      draftState.byTicketId[conversationId][suggestion.type].suggestion = {
        ...suggestion,
      }

      return draftState
    },
    [UPDATE_AI_SUGGESTION_AUTO_GENERATED]: (draftState, { payload }) => {
      const { ticketId, suggestionType, isAutoGenerated } = payload

      const suggestionTypeState =
        draftState.byTicketId[ticketId][suggestionType]

      suggestionTypeState.isAutoGenerated = isAutoGenerated

      return draftState
    },
    [UPSERT_AI_SUGGESTION_STARTED]: (draftState, { payload }) => {
      const { conversationId: conversationGid, suggestionType } = payload
      const conversationId = getRawId(conversationGid)

      draftState.byTicketId[conversationId][
        suggestionType.toLowerCase()
      ].isLoading = true

      return draftState
    },
    [FETCH_TAGS_BY_IDS_NAMES_STARTED]: (draftState, { payload }) => {
      if (!payload.filter || !payload.filter.ids) {
        return draftState
      }

      const conversationId = selectCurrentTicketId(app.store.getState())

      if (!draftState.byTicketId[conversationId]) {
        return draftState
      }

      const tagsSuggestion =
        draftState.byTicketId[conversationId].tags.suggestion
      if (!tagsSuggestion || !tagsSuggestion.id) {
        return draftState
      }

      const isSearchingForAllTags = payload.filter.ids.every(tagId =>
        tagsSuggestion.value.includes(tagId)
      )

      if (!isSearchingForAllTags) {
        return draftState
      }

      draftState.byTicketId[conversationId].isLoadingTagNames = true

      return draftState
    },
    [FETCH_TAGS_BY_IDS_NAMES_SUCCESS]: (draftState, { meta }) => {
      if (
        !meta.requestParameters.filter ||
        !meta.requestParameters.filter.ids
      ) {
        return draftState
      }

      const conversationId = selectCurrentTicketId(app.store.getState())

      if (!draftState.byTicketId[conversationId]) {
        return draftState
      }

      const tagsSuggestion =
        draftState.byTicketId[conversationId].tags.suggestion
      if (!tagsSuggestion || !tagsSuggestion.id) {
        return draftState
      }

      const isSearchingForAllTags = meta.requestParameters.filter.ids.every(
        tagId => tagsSuggestion.value.includes(tagId)
      )

      if (!isSearchingForAllTags) {
        return draftState
      }

      draftState.byTicketId[conversationId].isLoadingTagNames = false
      draftState.byTicketId[conversationId].isLoadedTagNames = true

      return draftState
    },
  },
  initialState
)
