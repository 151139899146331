import { createSelector } from 'reselect'
import { getRawId } from 'util/globalId'
import { selectConversationLinkedResources } from './selectConversationLinkedResources'

export const selectConversationLinkedResourcesByConversationId = createSelector(
  selectConversationLinkedResources,
  linkedResources => {
    return linkedResources.reduce((result, linkedResource) => {
      const ticketId = getRawId(linkedResource.conversationId)

      // eslint-disable-next-line no-param-reassign
      if (!result[ticketId]) result[ticketId] = []

      result[ticketId].push(linkedResource)

      return result
    }, {})
  }
)
