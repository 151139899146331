import { RESTRICTION_TYPE_USERS } from 'ducks/teams/constants'
import { EMAIL_CHANNEL_TYPES } from './channelTypes'

export const getRestrictionType = (channel, currentUserId) => {
  const { restriction_type: restrictionType = null, user_ids: agentIds = [] } =
    channel || {}

  if (
    restrictionType === RESTRICTION_TYPE_USERS &&
    agentIds.length === 1 &&
    agentIds[0] === currentUserId
  )
    return 'me'
  return restrictionType
}

export const getChannelTypeByTypeValue = typeValue => {
  // Find name by value
  return Object.entries(EMAIL_CHANNEL_TYPES).find(
    item => item[1] === typeValue || item[0] === typeValue
  )?.[0]
}

export const channelTypeToV2 = typeValue => {
  // Find name by value
  return Object.entries(EMAIL_CHANNEL_TYPES).find(
    item => item[1] === typeValue || item[0] === typeValue
  )?.[1]
}
