import { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect } from 'redux-first-router'
import useIsMounted from 'util/hooks/useIsMounted'
import {
  selectCurrentChannelById,
  selectPendingChannelById,
} from 'ducks/channels/selectors'
import {
  doAddGmailChannel,
  doOauthGmailChannel,
  doAddMessengerChannel,
  doReconnectMessengerChannel,
  doAddInstagramChannel,
  doReconnectInstagramChannel,
  doOauthOutlookChannel,
  doOauthOffice365Channel,
  doClearChannelDraft,
} from 'ducks/channels/actions'
import {
  DRAWER_TYPE_CHANNELS_MAIL_IMPORT,
  DRAWER_TYPE_CHANNELS_MEMBERS_EDIT,
  DRAWER_TYPE_CHANNELS_MEMBERS_EDIT_MODAL,
} from 'ducks/drawers/types'
import {
  doBuildInboxMenuFromMailboxes,
  doBuildMenuFromWidgets,
  doRedirectToCollectionAndFolderById,
} from 'ducks/folders/operations/collections'
import { doFetchFacebookPages } from 'ducks/facebook/actions'
import { doFetchWidgetById } from 'ducks/widgets/operations'
import { doGmailImport, doFetchMailbox } from 'ducks/mailboxes/actions'
import { isBoostrappedSelector } from 'selectors/app/base'
import { selectAgentCount, selectAgentTotalCount } from 'selectors/agents/base'
import { useFeature } from 'ducks/billing/hooks'
import EDIT_MEMBERS_CONFIG from 'subapps/settings/components/drawers/Channels/EditMembers/config'
import CONFIG from 'subapps/settings/components/drawers/Channels/ChannelDetails/config'
import usePrevious from 'util/hooks/usePrevious'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_ID_FOLDERS_CHANNELS_MAIL_IMPORT,
  DRAWER_ID_FOLDERS_CHANNELS_MEMBERS_EDIT,
} from 'ducks/drawers/ids'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { CHANNEL_TYPES } from 'subapps/settings/components/drawers/Channels/Channels.data'
import { selectIsInInbox } from 'selectors/location'
import { selectCurrentMailboxId } from 'ducks/mailboxes/selectors/selectCurrentMailboxId'
import { selectRequestByType } from 'ducks/requests/selectors'
import { snow } from 'util/ui/confetti'
import { useConfirmHoldsCallback } from 'util/dirtyHolds'
import { SETTINGS_CHANNELS_PAGE } from 'subapps/settings/types'
import { UPDATE_MAILBOX } from 'ducks/mailboxes/actionTypes'
import { isChatChannelType } from './channelTypes'

export const useChannel = (channelId, { useCachedIfAvailable = true } = {}) => {
  const requestKey = `${UPDATE_MAILBOX}_${channelId}`
  const { loading, loaded, error } = useSelector(state =>
    selectRequestByType(state, requestKey)
  )
  const isMounted = useIsMounted()
  const dispatch = useDispatch()

  const isBootstrapped = useSelector(isBoostrappedSelector)

  const channel = useSelector(state =>
    selectCurrentChannelById(state, channelId)
  )

  const channelFetched = useRef(false)

  const loadChannel = useCallback(
    options => {
      const { ignoreCache = false } = options || {}
      if (
        (!channel || ignoreCache || !useCachedIfAvailable) &&
        channelId &&
        channelId !== 'new' &&
        !channelFetched.current
      ) {
        channelFetched.current = true
        dispatch(
          doFetchMailbox(channelId, { skipLoaded: useCachedIfAvailable })
        )
      }
    },
    [dispatch, channel, channelId, useCachedIfAvailable]
  )

  useEffect(
    () => {
      if (isMounted() && (!useCachedIfAvailable || !channel)) {
        loadChannel()
      }
    },
    [isMounted, isBootstrapped, loadChannel, channel, useCachedIfAvailable]
  )

  return {
    channel: loading ? null : channel,
    isLoading: loading,
    loadChannel,
    isMissing: (!channel || error) && loaded,
  }
}

export const useWidgetChannel = (
  channelId,
  { useCachedIfAvailable = true } = {}
) => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const stateChannel = useSelector(state =>
    selectCurrentChannelById(state, channelId)
  )

  // NOTE: make sure to change the useEffect below if the useState default below changes
  const [channel, setChannel] = useState(
    useCachedIfAvailable ? stateChannel : null
  )
  const isBootstrapped = useSelector(isBoostrappedSelector)

  // NOTE: make sure to change the useEffect below if any of the useState defaults below change
  const [isLoading, setIsLoading] = useState(null)
  const [isLoaded, setIsLoaded] = useState(!!channel)
  const [isMissing, setIsMissing] = useState(false)
  const [loadCount, setLoadCount] = useState(0)
  const previousChannelId = usePrevious(channelId)

  const loadChannel = useCallback(
    async options => {
      const { ignoreCache = false } = options || {}
      let loadedChannel = channel
      if (
        (!loadedChannel || !useCachedIfAvailable || ignoreCache) &&
        channelId &&
        channelId !== 'new'
      ) {
        setIsLoading(true)
        setLoadCount(loadCount + 1)
        if (channelId.startsWith('wid')) {
          loadedChannel = await dispatch(
            doFetchWidgetById(channelId, { useCachedIfAvailable })
          )
        }
      }

      if (!isMounted()) return
      if (!loadedChannel) {
        setIsMissing(true)
      } else {
        setIsLoading(false)
        setIsLoaded(true)
      }
    },
    [
      dispatch,
      channel,
      setIsLoading,
      setIsMissing,
      channelId,
      useCachedIfAvailable,
      loadCount,
      isMounted,
    ]
  )

  // Mailboxes are loaded as part of the bootstrap, so technically speaking this load isnt required except
  // for a small edgecase where the owner/admin has setup a mailbox and then denied themselfs access to it.
  // Fetching a mailbox by id however will still returns the mailbox if your are an admin, but dont have permission
  // to it.
  useEffect(
    () => {
      if (isLoading !== null || !isBootstrapped || !isMounted()) return
      loadChannel()
    },
    [loadChannel, isLoading, isBootstrapped, isMounted]
  )

  useEffect(
    () => {
      if (isLoaded && channel !== stateChannel) {
        setChannel(stateChannel)
      }
    },
    [isLoaded, channel, stateChannel]
  )

  useEffect(
    () => {
      // re-initialize if the channelId changes or cached channel = null BUT stateChannel has value
      if (
        (previousChannelId && channelId !== previousChannelId) ||
        (!channel && stateChannel)
      ) {
        const resetChannel = useCachedIfAvailable ? stateChannel : null
        setChannel(resetChannel)
        setIsLoading(null)
        setIsLoaded(!!resetChannel)
        setIsMissing(false)
        setLoadCount(0)
      }
    },
    [channelId, previousChannelId, stateChannel, useCachedIfAvailable, channel]
  )

  return {
    channel,
    isLoading,
    isReloading: isLoading && loadCount > 1,
    isMissing,
    loadChannel,
    loadCount,
  }
}

export const useRebuildLeftNavMenu = ({ channelId, onExit }) => {
  const dispatch = useDispatch()
  const { channel } = useChannel(channelId)
  const { type: channelType } = channel || {}

  const rebuildMenu = useCallback(
    () => {
      if (isChatChannelType(channelType)) {
        dispatch(doBuildMenuFromWidgets({ autoRedirect: false }))
      } else {
        dispatch(doBuildInboxMenuFromMailboxes())
      }
    },
    [dispatch, channelType]
  )

  const rebuildMenuAndExit = useCallback(
    () => {
      rebuildMenu()
      onExit()
    },
    [onExit, rebuildMenu]
  )

  return {
    rebuildMenu,
    rebuildMenuAndExit,
  }
}

export const useRedirectToChannel = ({ channelId, onExit }) => {
  const dispatch = useDispatch()
  const { rebuildMenu } = useRebuildLeftNavMenu({ channelId, onExit })
  const { channel } = useChannel(channelId)
  const { type: channelType } = channel || {}

  const handleRedirect = useCallback(
    () => {
      dispatch(
        doRedirectToCollectionAndFolderById(channelId, null, {
          ignoreLast: true,
          preserveQuery: false,
          query: {
            created: 'inbox',
          },
          channelType: isChatChannelType(channelType) ? channelType : 'mailbox',
        })
      )
    },
    [dispatch, channelId, channelType]
  )

  const handleExitAndRedirect = useCallback(
    () => {
      onExit()
      handleRedirect()
    },
    [onExit, handleRedirect]
  )

  const handleRebuildExitAndRedirect = useCallback(
    () => {
      rebuildMenu()
      handleExitAndRedirect()
    },
    [handleExitAndRedirect, rebuildMenu]
  )

  const handleRebuildAndRedirect = useCallback(
    () => {
      rebuildMenu()
      handleRedirect()
    },
    [handleRedirect, rebuildMenu]
  )

  return {
    redirect: handleRedirect,
    exitAndRedirect: handleExitAndRedirect,
    rebuildAndRedirect: handleRebuildAndRedirect,
    rebuildExitAndRedirect: handleRebuildExitAndRedirect,
  }
}

export const useEstablishChannelConnection = ({
  channelId,
  channelType,
  action,
  source,
}) => {
  const dispatch = useDispatch()

  const pendingChannel = useSelector(state =>
    selectPendingChannelById(state, channelId)
  )
  const currentChannel = useSelector(state =>
    selectCurrentChannelById(state, channelId)
  )

  const handleGmailChannel = useCallback(
    async () => {
      let mailbox = null
      if (!!channelId && channelId !== 'new') {
        mailbox = await dispatch(doOauthGmailChannel(channelId))
        await dispatch(
          doGmailImport(channelId, {
            import_labels: false,
            close_tickets: false,
            pages_to_import: 200,
          })
        )
      } else {
        mailbox = await dispatch(doAddGmailChannel(pendingChannel))
      }
      return mailbox
    },
    [dispatch, pendingChannel, channelId]
  )

  const handleOutlookChannel = useCallback(
    async () => {
      let mailbox = null
      if (source === 'inbox') {
        mailbox = await dispatch(doOauthOutlookChannel(channelId))
      }
      return mailbox
    },
    [dispatch, source, channelId]
  )

  const handleOffice365Channel = useCallback(
    async () => {
      let mailbox = null
      if (source === 'inbox') {
        mailbox = await dispatch(doOauthOffice365Channel(channelId))
      }
      return mailbox
    },
    [dispatch, source, channelId]
  )

  const handleMessengerChannelInstall = useCallback(
    async () => {
      const widget = await dispatch(doAddMessengerChannel(pendingChannel))
      return widget
    },
    [dispatch, pendingChannel]
  )

  const handleInstagramChannelInstall = useCallback(
    async () => {
      const widget = await dispatch(doAddInstagramChannel(pendingChannel))
      return widget
    },
    [dispatch, pendingChannel]
  )

  const handleMessengerChannelReconnect = useCallback(
    async () => {
      await dispatch(doReconnectMessengerChannel(currentChannel))
      const { bridge: { externalId, externalName } = {} } = currentChannel
      if (externalId) {
        const { facebookPages } = await dispatch(
          doFetchFacebookPages({ skipLoaded: false })
        )
        const linkedFacebookPage = facebookPages.find(p => {
          return p.uid === externalId && p.subscribed
        })
        if (!linkedFacebookPage) {
          throw new Error(
            `Please also give permission for the page “${externalName}“.`
          )
        }
      }
      return currentChannel
    },
    [dispatch, currentChannel]
  )

  const handleInstagramChannelReconnect = useCallback(
    async () => {
      await dispatch(doReconnectInstagramChannel(currentChannel))
      return currentChannel
    },
    [dispatch, currentChannel]
  )

  const connectChannel = useCallback(
    async () => {
      switch (channelType) {
        case 'google':
          return handleGmailChannel()
        case 'outlook':
          return handleOutlookChannel()
        case 'office365':
          return handleOffice365Channel()
        case 'facebook':
          if (action === 'install') {
            return handleMessengerChannelInstall()
          }
          return handleMessengerChannelReconnect()
        case 'instagram':
          if (action === 'install') {
            return handleInstagramChannelInstall()
          }
          return handleInstagramChannelReconnect()
        default:
          return null
      }
    },
    [
      channelType,
      handleGmailChannel,
      handleOutlookChannel,
      handleOffice365Channel,
      handleMessengerChannelInstall,
      handleMessengerChannelReconnect,
      handleInstagramChannelInstall,
      handleInstagramChannelReconnect,
      action,
    ]
  )

  return { connectChannel }
}

export const useChannelDetailsConfig = channelType => {
  const agentsTotalCount = useSelector(selectAgentTotalCount)
  const { drawer: nextDrawer, ...rest } = CONFIG[channelType]
  const { feature } = rest
  const { canUseFeature } = useFeature(feature)

  // next drawer after edit members drawer
  const { drawer: nextDrawerAfterEditMembersStep } = EDIT_MEMBERS_CONFIG[
    channelType
  ]

  const skipMembersEditDrawer =
    agentsTotalCount <= 1 &&
    nextDrawer?.type === DRAWER_TYPE_CHANNELS_MEMBERS_EDIT

  // There are no other teammates, skip the edit team members drawer and rather open the next drawer
  const drawer = skipMembersEditDrawer
    ? nextDrawerAfterEditMembersStep
    : nextDrawer

  return {
    ...rest,
    drawer,
    canUseFeature,
  }
}

export const useExitMailboxCreationIfFeatureIsDisabled = ({
  channelType,
  newChannelId,
  onExit,
}) => {
  const { canUseFeature } = useChannelDetailsConfig(channelType)
  const dispatch = useDispatch()

  const handleOnExit = useConfirmHoldsCallback(
    null,
    () => {
      dispatch(doClearChannelDraft(newChannelId || 'new'))
      onExit()
    },
    [dispatch, onExit, newChannelId]
  )

  useEffect(
    () => {
      if (canUseFeature) return
      if (newChannelId) return

      handleOnExit()
      dispatch(
        redirect({
          type: SETTINGS_CHANNELS_PAGE,
        })
      )
    },
    [handleOnExit, dispatch, canUseFeature, channelType, newChannelId]
  )
}

export const useGoNextStepAfterMailboxDetails = ({
  newChannelId,
  channelType,
  onExit,
}) => {
  const dispatch = useDispatch()
  const [completed, setCompleted] = useState(false)
  const { importHistory } = CHANNEL_TYPES[channelType]
  const hasMultipleActiveAgents = useSelector(selectAgentCount) > 1
  const isInInbox = useSelector(selectIsInInbox)
  const currentMailboxId = useSelector(selectCurrentMailboxId)
  const { rebuildExitAndRedirect } = useRedirectToChannel({
    channelId: newChannelId, // channel id after mailbox is created
    onExit,
  })
  const {
    drawerId: editMembersDrawerId,
    openDrawer: openEditMembers,
  } = useDrawer({
    id: DRAWER_ID_FOLDERS_CHANNELS_MEMBERS_EDIT,
    type: DRAWER_TYPE_CHANNELS_MEMBERS_EDIT_MODAL,
    closeIgnoresStack: false,
  })

  const {
    drawerId: mailImportDrawerId,
    openDrawer: openChannelsMailImportDrawer,
  } = useDrawer({
    id: DRAWER_ID_FOLDERS_CHANNELS_MAIL_IMPORT,
    type: DRAWER_TYPE_CHANNELS_MAIL_IMPORT,
    closeIgnoresStack: false,
  })

  const handleFinishCreation = useCallback(
    () => {
      dispatch(doClearChannelDraft(newChannelId))
      rebuildExitAndRedirect()

      if (isInInbox) {
        if (currentMailboxId === newChannelId) {
          snow({ duration: 1 })
        }
      }
    },
    [
      isInInbox,
      rebuildExitAndRedirect,
      currentMailboxId,
      newChannelId,
      dispatch,
    ]
  )

  useEffect(
    () => {
      if (!newChannelId) return
      if (hasMultipleActiveAgents) {
        openEditMembers(newChannelId, {
          query: {
            ...buildDrawerQueryParam(
              editMembersDrawerId,
              'drawerChannelType',
              channelType
            ),
          },
        })
        return
      }
      if (!importHistory) {
        handleFinishCreation()
        setCompleted(true)
        return
      }
      openChannelsMailImportDrawer(newChannelId, {
        query: {
          ...buildDrawerQueryParam(
            mailImportDrawerId,
            'drawerChannelType',
            channelType
          ),
        },
      })
    },
    [
      newChannelId,
      hasMultipleActiveAgents,
      channelType,
      openEditMembers,
      editMembersDrawerId,
      mailImportDrawerId,
      openChannelsMailImportDrawer,
      handleFinishCreation,
      importHistory,
    ]
  )

  return { completed }
}
