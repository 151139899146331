import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'

const selectIntegrationData = state =>
  state?.integrations?.thirdPartyData?.stripe || emptyObj

export const selectUpcomingInvoices = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.upcomingInvoices
)

const selectUpcomingInvoicesBySubscriptionId = createBasicSelector(
  selectUpcomingInvoices,
  customers => customers.bySubscriptionId || emptyObj
)

export const selectUpcomingInvoiceForSubscriptionId = createBasicSelector(
  selectUpcomingInvoicesBySubscriptionId,
  (state, subscriptionId) => subscriptionId,
  (invoicesBySubscriptionId, subscriptionId) =>
    invoicesBySubscriptionId[subscriptionId] || emptyObj
)
