import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'

const selectIntegrationData = state =>
  state?.integrations?.thirdPartyData?.recharge || emptyObj

export const selectCustomers = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.customers
)

const selectCustomersByIntegrationId = createBasicSelector(
  selectCustomers,
  customers => customers.byIntegrationId || emptyObj
)

export const selectCustomersForIntegrationId = createBasicSelector(
  selectCustomersByIntegrationId,
  (state, integrationId) => integrationId,
  (customersByIntegrationId, integrationId) =>
    customersByIntegrationId[integrationId] || emptyObj
)

export const selectCustomersByCustomerId = createBasicSelector(
  selectCustomersForIntegrationId,
  customersForIntegrationId =>
    customersForIntegrationId.byCustomerId || emptyObj
)

export const selectCustomerForCustomerId = createBasicSelector(
  selectCustomersByCustomerId,
  (state, integrationId, { customerId }) => customerId,
  (byCustomerId, customerId) => byCustomerId[customerId] || emptyObj
)

export const selectCustomerIsReloading = createBasicSelector(
  selectCustomerForCustomerId,
  customer => customer?.isReloading
)

export const selectCustomerIsError = createBasicSelector(
  selectCustomerForCustomerId,
  customer => customer?.isError
)
