import { createSelector } from 'reselect'
import { selectInaccessibleMailboxesRaw } from './selectInaccessibleMailboxesRaw'

export const selectInaccessibleMailboxes = createSelector(
  selectInaccessibleMailboxesRaw,
  inaccessibleMailboxes => {
    if (inaccessibleMailboxes) {
      return inaccessibleMailboxes
    }
    return null
  }
)
