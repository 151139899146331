import { isBoostrappedSelector } from 'selectors/app'
import { createBasicSelector } from 'util/redux'
import { selectFetchAppBootstrapRequest } from './selectFetchAppBootstrapRequest'

export const selectIsBootstrapped = createBasicSelector(
  isBoostrappedSelector,
  selectFetchAppBootstrapRequest,
  (isBoostrapped, appBootstrapRequest) =>
    isBoostrapped && appBootstrapRequest.loaded
)
