import {
  selectCurrentContactIdOrTemp,
  selectCurrentContactIsCreating,
} from 'ducks/crm/contacts/selectors/current'
import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { selectCurrentMatrixUserId } from 'ducks/chat/selectors/users'
import { selectRoomIdFromUrl } from 'ducks/chat/selectors/rooms'
import { selectAccountId } from 'ducks/accounts/selectors/selectAccountId'
import {
  buildRoomAliasFromId,
  getRoomVerifiedIdentityEmail,
} from 'ducks/chat/utils/rooms'
import { getMatrixClient } from 'ducks/chat/utils/client'
import { changeEntity, mergeEntityChanges } from 'ducks/entities/actionUtils'
import { contactsNormalizationSchema } from '../../schema'
import {
  FETCH_CONTACT_FAILED,
  FETCH_CONTACT_PAGE,
  FETCH_CONTACT_STARTED,
  FETCH_CONTACT_SUCCESS,
} from '../../types'
import { doCreateAndChangeContactForConversation } from '../updating/doCreateAndChangeContactForConversation'
import { fetchContactCustomFieldsByCustomFieldQuery } from './fetchContactCustomFieldsByCustomFieldQuery'
import { fetchContactCustomFieldsByCustomFieldFirstPageQuery } from './fetchContactCustomFieldsByCustomFieldFirstPageQuery'

export function doFetchContactForCustomField(
  key,
  value,
  conversationId,
  isInChat = false,
  isForNewTicket,
  options = {}
) {
  return async (dispatch, getState) => {
    const {
      allowCreateContact = true,
      disableSuccessActions = false,
      requestKey,
    } =
      options || {}
    let hasNextPage
    let after
    let firstLoop = true
    const state = getState()
    const contactId = selectCurrentContactIdOrTemp(state)
    const isCreatingContact = selectCurrentContactIsCreating(state)

    const customFields = [
      {
        key,
        values: [value],
      },
    ]
    let fetchedContactId

    dispatch({
      type: FETCH_CONTACT_STARTED,
      ...mergeEntityChanges([
        changeEntity('contacts', contactId, {
          customFields,
          isLoading: true,
          isCreating: false,
        }),
        {
          entities: {
            contacts: {
              [contactId]: {
                customFields,
                isLoading: true,
                isCreating: false,
              },
            },
          },
        },
      ]),
    })
    let noContactFound = false
    do {
      const variables = {
        after,
        filter: { customFields },
      }
      // eslint-disable-next-line no-await-in-loop
      const result = await dispatch(
        doAppGraphqlRequest(
          FETCH_CONTACT_PAGE,
          firstLoop
            ? fetchContactCustomFieldsByCustomFieldFirstPageQuery
            : fetchContactCustomFieldsByCustomFieldQuery,
          variables,
          {
            meta: {
              mergeEntities: true,
              identifyingKey: key,
              identifyingValue: value,
              requestKey,
            },
            normalizationSchema: contactsNormalizationSchema,
          }
        )
      )
      const { contacts: { nodes: [contact] = [] } = {} } = result
      if (!contact) {
        if (!disableSuccessActions) {
          dispatch({
            type: FETCH_CONTACT_SUCCESS,
            meta: { mergeEntities: true, isForNewTicket },
            ...mergeEntityChanges([
              changeEntity('contacts', contactId, {
                id: contactId,
                isPartiallyLoaded: true,
                isLoaded: true,
                isLoading: false,
                isMissing: true,
                isCreating: false,
              }),
              {
                entities: {
                  contacts: {
                    [contactId]: {
                      id: contactId,
                      isPartiallyLoaded: true,
                      isLoaded: true,
                      isLoading: false,
                      isMissing: true,
                      isCreating: false,
                    },
                  },
                },
              },
            ]),
          })
        }

        noContactFound = true
        break
      }
      const { customFieldValues } = contact || {}
      const { pageInfo } = customFieldValues || {}

      fetchedContactId = contact.id
      hasNextPage = pageInfo && pageInfo.hasNextPage
      after = pageInfo && pageInfo.endCursor
      firstLoop = false
    } while (hasNextPage)

    if (
      allowCreateContact &&
      noContactFound &&
      !isCreatingContact &&
      isInChat &&
      conversationId &&
      value
    ) {
      // it might be possible to self heal and create this contact
      const client = getMatrixClient()
      const accountId = selectAccountId(state)
      const roomId = selectRoomIdFromUrl(state)
      const roomAlias = buildRoomAliasFromId(roomId, accountId)
      const { room_id: matrixRoomId } = await client.resolveRoomAlias(roomAlias)
      const matrixRoom = client.getRoom(matrixRoomId)
      if (matrixRoom) {
        const userId =
          key === 'contact_matrix_id' && !!value
            ? value
            : selectCurrentMatrixUserId(state)

        // if verfied email exists for this chat, send to create contact
        const verifiedEmail = getRoomVerifiedIdentityEmail(matrixRoom)

        // create contact
        return dispatch(
          doCreateAndChangeContactForConversation(
            contactId,
            `cnv_${conversationId}`,
            conversationId,
            '',
            verifiedEmail,
            userId
          )
        )
      }
    }

    if (fetchedContactId && !disableSuccessActions) {
      try {
        const contact = {
          id: contactId,
          isPartiallyLoaded: true,
          isLoaded: true,
          isLoading: false,
        }
        dispatch({
          type: FETCH_CONTACT_SUCCESS,
          meta: {
            mergeEntities: true,
            contactId: fetchedContactId,
            isForNewTicket,
          },
          ...mergeEntityChanges([
            contactId && changeEntity('contacts', contactId, contact),
            contactId && {
              entities: {
                contacts: {
                  [contactId]: contact,
                  [fetchedContactId]: {
                    ...contact,
                    id: fetchedContactId,
                  },
                },
              },
            },
          ]),
        })
      } catch (error) {
        dispatch({
          type: FETCH_CONTACT_FAILED,
          error: true,
          payload: error,
        })
      }
    }

    return fetchedContactId
  }
}
