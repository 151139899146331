import { selectBase } from './base'

export function selectAllCustomFieldCategoriesIsLoading(state) {
  return selectBase(state).isLoading
}

export function selectAllCustomFieldCategoriesIsLoaded(state) {
  return selectBase(state).isLoaded
}

export function selectAllCustomFieldCategoriesHasErrored(state) {
  return selectBase(state).hasErrored
}
