// Kevin R (2021-11-30)
// Its possible for the same "type" of drawer to be instantiated multiple times on the page.
// The issue then becomes that if you refresh the page, which of the drawers on the current page
// should be reopend. You might think that it is ok to open any drawer because they are the same "type",
// but weallow drawers to have custom props passed into from the contexts where they are intantiated.
// This means knowing which drawer to open is important. Currently we have a system that generates
// a drawer id based on the order that the drawers are rendered in. Unfortunately this order isnt
// always guarenteed. This file is the start of getting rid of that system and giving each unique
// instead of a drawer a specific id. These ids do display in the url, so try and keep them as short
// as possible while keeping them unique.
export const DRAWER_ID_FOLDERS_CHANNEL_ADD = 'fca'
export const DRAWER_ID_FOLDERS_SELECT_EMAIL = 'fse'
export const DRAWER_ID_FOLDERS_CHANNEL_DETAILS = 'fmn'
export const DRAWER_ID_FOLDERS_CHANNEL_DETAILS_OAUTH = 'fmno'
export const DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE = 'fcauth'
export const DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE_SUCCESS = 'fcauths'
export const DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE_FAILED = 'fcauthf'
export const DRAWER_ID_FOLDERS_CHANNELS_MEMBERS_EDIT = 'fcme'
export const DRAWER_ID_FOLDERS_CHANNELS_MEMBERS_ADD = 'fcma'
export const DRAWER_ID_FOLDERS_CHANNELS_MEMBERS_ADD_MORE = 'fcmam'
export const DRAWER_ID_FOLDERS_CHANNELS_MAIL_IMPORT = 'fcmi'
export const DRAWER_ID_FOLDERS_CHANNELS_CREATION_FEEDBACK = 'fccf'
export const DRAWER_ID_FOLDERS_CHANNELS_EMAIL_FORWARD = 'fcef'
export const DRAWER_ID_FOLDERS_CHANNELS_FORWRADING_TEST = 'fceft'
export const DRAWER_ID_FOLDERS_CHANNELS_FORWRADING_FAILED = 'fceff'
export const DRAWER_ID_FOLDERS_CHANNELS_FORWRADING_INSTRUCTIONS_SENT = 'fcefis'
export const DRAWER_ID_FOLDERS_CHANNELS_ADD_LIVE_CHAT = 'fcalc'
export const DRAWER_ID_FOLDERS_CHANNELS_SELECT_WIDGET = 'fcsw'
export const DRAWER_ID_FOLDERS_CHANNELS_CHANNEL_VOTE = 'fccv'
export const DRAWER_ID_CRM_CUSTOM_FIELDS_CREATE = 'ccfc'
export const DRAWER_ID_CRM_CUSTOM_FIELDS_UPDATE = 'ccfu'
