import { DEFAULT_CUSTOM_FIELD_KEY_ORDER } from '../constants'

export const sortCustomFieldsForSettingsDisplay = fields => {
  // Create a Set for quick lookup of ordered keys
  const orderSet = new Set(DEFAULT_CUSTOM_FIELD_KEY_ORDER)

  // Initialize a Map to store ordered fields and an array for others
  const fieldMap = new Map()
  const otherFields = []

  // Partition fields into ordered and others in a single pass
  fields.forEach(field => {
    if (orderSet.has(field.key)) {
      fieldMap.set(field.key, field)
    } else {
      otherFields.push(field)
    }
  })

  // Build the ordered fields array based on DEFAULT_CUSTOM_FIELD_KEY_ORDER
  const orderedFields = DEFAULT_CUSTOM_FIELD_KEY_ORDER.map(key =>
    fieldMap.get(key)
  ).filter(Boolean) // Remove undefined if the key wasn't found

  // Combine the ordered and other fields
  return [...orderedFields, ...otherFields]
}
