import { createSelector } from 'reselect'
import { FOLDER_TYPE } from 'ducks/folders/constants'
import { selectCurrentFolders } from '.'

export const selectTicketFolders = createSelector(
  selectCurrentFolders,
  folders =>
    folders.filter(folder =>
      [FOLDER_TYPE.ALL, FOLDER_TYPE.TICKET].includes(folder.type)
    )
)
