import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { sortByKey } from 'util/arrays/sorting'
import { sortByName, withPush } from 'util/arrays'
import createCachedSelector from 're-reselect'
import storage from 'util/storage'
import { selectCurrentAgents } from 'ducks/agents/selectors'
import selectBase from './selectBase'
import { selectItems } from './items'
import {
  AGENT_GROUP_ID,
  DEFAULT_OPEN_SECTIONS,
  LABEL_GROUP_ID,
  OPEN_SECTIONS_KEY,
} from '../constants'

export const selectGroupsById = createSelector(
  selectBase,
  base => base.groupsById
)

export const selectGroupIds = createSelector(selectGroupsById, groupsById =>
  Object.keys(groupsById)
)

export const selectGroups = createSelector(selectGroupsById, groupsById =>
  Object.values(groupsById)
)

export const selectGroupsSortedByName = createSelector(selectGroups, groups => {
  return sortByName(groups)
})

export const selectOpenGroupIds = createSelector(
  selectBase,
  selectGroupIds,
  selectCurrentAgents,
  (base, groupIds, agents) => {
    const currentOpenSections =
      storage.get(OPEN_SECTIONS_KEY) || DEFAULT_OPEN_SECTIONS

    const teamOpen =
      (currentOpenSections.Team === undefined && agents.length <= 1) ||
      currentOpenSections.Team
    const tagsOpen = currentOpenSections.Tags

    if (!tagsOpen && !teamOpen) return base.openGroupIds

    const openGroupIds = [...base.openGroupIds]
    groupIds.forEach(gid => {
      if (
        (tagsOpen && gid.includes(LABEL_GROUP_ID)) ||
        (teamOpen && gid.includes(AGENT_GROUP_ID))
      ) {
        withPush(openGroupIds, gid)
      }
    })
    return openGroupIds
  }
)

export function selectGroupIdFromProps(_, props) {
  return props.groupId || null
}

export const selectGroupById = createCachedSelector(
  selectGroupsById,
  selectItems,
  (_state, groupId) => groupId,
  (groupsById, folders, groupId) => {
    const group = groupsById[groupId]
    if (!group) return emptyObj
    const groupFolderIds = sortByKey(
      folders.filter(folder => folder.groupId === groupId),
      'position'
    ).map(folder => folder.id)
    return { ...groupsById[groupId], folderIds: groupFolderIds }
  }
)((_state, groupId) => groupId || 'unknown')
