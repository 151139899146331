import createCachedSelector from 're-reselect'
import { any } from 'util/arrays'
import {
  makeSelectRequest,
  selectBase as selectRequests,
} from 'ducks/requests/selectors'
import { createBasicSelector } from 'util/redux'
import { selectContactsByEmail } from './base'

function getEmailFromProps(state, { email } = {}) {
  return email || 'unknown'
}

const selectContactForEmail = createBasicSelector(
  selectContactsByEmail,
  getEmailFromProps,
  (byEmail, email) => {
    return byEmail[email]
  }
)

const selectIsContactPropertiesRequest = makeSelectRequest(
  'integrations/hubspot/fetchContactProperties'
)
const selectIsCompanyPropertiesRequest = makeSelectRequest(
  'integrations/hubspot/fetchCompanyProperties'
)
const selectIsDealPropertiesRequest = makeSelectRequest(
  'integrations/hubspot/fetchDealProperties'
)

function makeSelectIsRequestForEmail(requestKeyBase) {
  return createCachedSelector(
    selectRequests,
    getEmailFromProps,
    (requests, email) =>
      requests[`integrations/hubspot/${requestKeyBase}:${email}`]
  )(getEmailFromProps)
}

const selectIsContactRequestForEmail = makeSelectIsRequestForEmail(
  'fetchContact'
)
const selectIsContactCompanyRequestForEmail = makeSelectIsRequestForEmail(
  'fetchContactCompany'
)
const selectIsContactListsRequestForEmail = makeSelectIsRequestForEmail(
  'fetchContactLists'
)
const selectIsContactDealsRequestForEmail = makeSelectIsRequestForEmail(
  'fetchContactDeals'
)
const selectIsOwnersRequestForEmail = createCachedSelector(
  selectRequests,
  selectContactForEmail,
  (requests, contact) => {
    const owner =
      contact && contact.properties && contact.properties.hubspot_owner_id
    const ownerId = owner && owner.value
    return requests[`integrations/hubspot/fetchOwner:${ownerId}`]
  }
)(getEmailFromProps)

function makeSelectRequestPropertyForEmail(key) {
  return createCachedSelector(
    selectIsContactPropertiesRequest,
    selectIsCompanyPropertiesRequest,
    selectIsDealPropertiesRequest,
    selectIsContactRequestForEmail,
    selectIsContactCompanyRequestForEmail,
    selectIsContactListsRequestForEmail,
    selectIsContactDealsRequestForEmail,
    selectIsOwnersRequestForEmail,
    (...loadings) => {
      const result =
        loadings && any(request => request && request[key], loadings)
      return result
    }
  )(getEmailFromProps)
}

export const selectIsCustomerLoadingForEmail = makeSelectRequestPropertyForEmail(
  'loading'
)
export const selectIsCustomerErroredForEmail = makeSelectRequestPropertyForEmail(
  'error'
)
