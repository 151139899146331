import { isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import { FETCH_CONTACTS_STARTED } from '../types'

export default function reduceLatestRequestKey(
  state = null,
  { type, meta: { requestKey } = {} }
) {
  if (isNullOrUndefined(requestKey)) return state

  if (type === FETCH_CONTACTS_STARTED) return requestKey
  return state
}
