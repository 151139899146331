import { selectCurrentContactName } from 'ducks/crm/contacts/selectors'
import { oauthTokenSelector } from 'selectors/app'
import { selectIsInChat } from 'selectors/location'
import {
  selectCurrentTicketTitle,
  selectCurrentConversationUrl,
} from 'selectors/tickets'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import integrationApi from '../../api'

export default function doPostTicketLinkOnGitHubIssue(cloudId, issueId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const ticketUrl = selectCurrentConversationUrl(state)
    const ticketTitle = selectCurrentTicketTitle(state)
    const isChat = selectIsInChat(state)
    const ticketId = selectCurrentTicketId(state)
    const contactName = selectCurrentContactName(state)
    const title = isChat ? `Chat with ${contactName}` : ticketTitle
    integrationApi.post(
      token,
      `proxy/atlassian/ex/jira/${cloudId}/rest/api/3/issue/${issueId}/comment`,
      {},
      JSON.stringify({
        body: {
          version: 1,
          type: 'doc',
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: `#${ticketId}: ${title}`,
                  marks: [
                    {
                      type: 'link',
                      attrs: {
                        href: ticketUrl,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      })
    )
  }
}
