import { BEGIN, COMMIT, REVERT } from 'redux-optimist'
import { doSdkRequest, doGraphqlRequest } from 'ducks/requests/operations'
import { selectAccountId } from 'ducks/accounts/selectors/selectAccountId'
import {
  selectSearchNextPageCursorByQueryId,
  selectSearchPreviousEntityIdByQueryIdAndEntityId,
  selectSearchNextEntityIdByQueryIdAndEntityId,
  selectSearchHasMoreByQueryId,
  selectSearchIsLoadingMoreByQueryId,
  selectSearchByQueryId,
  selectByQueryId,
  selectUnreadByChannelType,
} from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import selectCurrentConversationId from 'ducks/searches/selectors/selectCurrentConversationId'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectAutoAdvancePreferences } from 'ducks/currentUser/selectors/preferences/selectAutoAdvancePreferences'
import { queryIdToQuery } from 'ducks/searches/utils/query'
import { doFetchConversations } from 'ducks/searches/operations/doFetchConversations'
import { doShowSnackbar } from 'actions/snackbar'
import uuid from 'util/uuid'
import { capitalize } from 'util/strings'
import { selectIsInChat } from 'selectors/location'
import { selectIsWebPushSupported } from 'selectors/webPush'

import { asyncForEach } from 'util/functions'
import { isGranted } from 'util/webPush/permissions'
import { playAudioNotification } from 'util/audio'
import {
  selectWidgetById,
  selectWidgetsById,
} from 'ducks/widgets/selectors/widgets'
import { selectChannelTypeFromUrl } from 'ducks/channels/selectors'
import { mapChannelTypeToPageType } from 'ducks/folders/utils'
import { getRawId } from 'util/globalId'
import { selectCurrentFoldersById } from 'ducks/folders/selectors/folders'
import { selectEntities } from 'ducks/entities/selectors'

import { getMatrixClient, fireEvents } from '../utils/client'
import {
  transformRoom,
  buildEvents,
  buildRoomAliasFromId,
  recomputeFields,
  loadRoomUntilLastMessage,
} from '../utils/rooms'
import {
  ROOMS_FETCH,
  ROOMS_FETCH_SET_LOADING_IDS,
  ROOMS_CREATE,
  ROOMS_BYID_FETCH,
  ROOMS_BYIDS_FETCH,
  ROOMS_UPDATED,
  ROOM_OPTIMISTIC_UPDATED,
  ROOM_OPTIMISTIC_COMPLETE,
  ROOM_OPTIMISTIC_FAILED,
  ROOM_DELETE,
  ROOMS_REALTIME_SYNC_UNREAD,
} from '../actionTypes/rooms'
import {
  selectRoomById,
  selectRoomsById,
  selectLoadingMatrixRoomIds,
  selectRoomFromUrl,
} from '../selectors/rooms'

export const doFetchRooms = (opts = {}) => {
  const { isReload, loadFirst } = opts
  return doSdkRequest(
    ROOMS_FETCH,
    async ({ dispatch, getState }) => {
      const state = getState()
      const pageChannelType = selectChannelTypeFromUrl(state)
      const queryId = selectCurrentQueryId(state)
      const { type: channelType = pageChannelType } =
        queryIdToQuery(queryId) || {}
      const nextPageCursor = loadFirst
        ? null
        : selectSearchNextPageCursorByQueryId(state, queryId)

      // Load just the room ids from elastic search to provide us with folder
      // pagination information
      const rooms = []
      await dispatch(
        doFetchConversations({
          channelType,
          queryId,
          cursor: nextPageCursor,
          size: 20,
          options: {
            onBeforeSuccessAction: async response => {
              // Ensure that the latest room events from the matrix chat server is loaded
              if (!response?.conversations?.nodes) return

              const roomIds = response.conversations.nodes.map(
                ({ internalId }) => internalId
              )

              await dispatch({
                type: ROOMS_FETCH_SET_LOADING_IDS,
                payload: {
                  roomIds,
                },
              })

              await asyncForEach(
                roomIds,
                async roomId => {
                  const room = await loadRoomUntilLastMessage(roomId)
                  if (room) {
                    rooms.push(room)
                  }
                },
                { concurrency: 10 }
              )
            },
          },
        })
      )
      return {
        rooms: rooms || [],
      }
    },
    {},
    { skipStarted: !!isReload }
  )
}

export const doCreateRoom = parameters => {
  return doSdkRequest(
    ROOMS_CREATE,
    async ({ variables: { visibility, name, invite, autoJoin } }) => {
      const client = getMatrixClient()
      const createRoomRequest = client.createRoom({
        visibility,
        name,
        invite,
      })
      const data = await createRoomRequest
      if (autoJoin) {
        await client.joinRoom(data.room_id)
      }
      return data
    },
    parameters
  )
}

export const doFetchRoomById = ({ id, isReload }) => {
  return doSdkRequest(
    ROOMS_BYID_FETCH,
    async ({ getState }) => {
      const client = getMatrixClient()
      const state = getState()
      const accountId = selectAccountId(state)
      const roomAlias = buildRoomAliasFromId(id, accountId)
      const { room_id: roomId } = await client.resolveRoomAlias(roomAlias)
      const room = await client.getRoom(roomId)
      return {
        room,
      }
    },
    { id, isReload },
    { skipStarted: isReload }
  )
}

export const doFetchRoomsByIds = ({ ids, isReload }) => {
  return doSdkRequest(
    ROOMS_BYIDS_FETCH,
    async ({ dispatch, getState }) => {
      const client = getMatrixClient()
      const state = getState()
      const accountId = selectAccountId(state)

      const roomAlias = ids.map(id => buildRoomAliasFromId(id, accountId))
      const roomIds = []
      await asyncForEach(
        roomAlias,
        async alias => {
          const { room_id: roomId } = await client.resolveRoomAlias(alias)
          roomIds.push(roomId)
        },
        { concurrency: 5 }
      )

      await dispatch({
        type: ROOMS_FETCH_SET_LOADING_IDS,
        payload: {
          roomIds,
        },
      })

      const rooms = []
      await asyncForEach(
        roomIds,
        async roomId => {
          const room = await loadRoomUntilLastMessage(roomId)
          if (room) {
            rooms.push(room)
          }
        },
        { concurrency: 5 }
      )

      return {
        rooms,
      }
    },
    { ids, isReload },
    { skipStarted: isReload }
  )
}

export function doOpenRoomPageById(roomId, options = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const currentQueryId = selectCurrentQueryId(state)
    const { viaKeyboard } = options
    const currentChannelType = selectChannelTypeFromUrl(state)
    const { channelId } = selectRoomById(state, roomId)
    const { channelType } = selectWidgetById(state, getRawId(channelId)) || {}

    return dispatch({
      type: mapChannelTypeToPageType(channelType || currentChannelType),
      payload: {
        viaKeyboard,
      },
      meta: {
        query: {
          ...queryIdToQuery(currentQueryId),
          conversationId: roomId,
        },
      },
    })
  }
}

export function doOpenNextRoomPage() {
  return async (dispatch, getState) => {
    const state = getState()
    const queryId = selectCurrentQueryId(state)
    const hasMore = selectSearchHasMoreByQueryId(state, queryId)
    const isLoadingMore = selectSearchIsLoadingMoreByQueryId(state, queryId)
    const currentConversationId = selectCurrentConversationId(state)
    const nextConversationId = selectSearchNextEntityIdByQueryIdAndEntityId(
      state,
      queryId,
      currentConversationId
    )
    if (nextConversationId)
      return dispatch(doOpenRoomPageById(nextConversationId))
    if (hasMore && !isLoadingMore) {
      await dispatch(doFetchRooms())
      dispatch(doOpenNextRoomPage())
    }
    return null
  }
}

export function doOpenPreviousRoomPage() {
  return (dispatch, getState) => {
    const state = getState()
    const queryId = selectCurrentQueryId(state)
    const currentConversationId = selectCurrentConversationId(state)
    const previousConversationId = selectSearchPreviousEntityIdByQueryIdAndEntityId(
      state,
      queryId,
      currentConversationId
    )
    if (previousConversationId)
      dispatch(doOpenRoomPageById(previousConversationId))
  }
}

function getAutoAdvancePreferenceName(
  { state, assignedType, assignedAgentId },
  { currentUser }
) {
  if (state) {
    return `autoAdvanceOn${capitalize(state)}`
  }
  if (assignedType && assignedType !== 'none') {
    // If you are assigning it to your self, then dont auto advance
    if (assignedType === 'agent' && currentUser.id === assignedAgentId)
      return null
    return 'autoAdvanceOnAssign'
  }
  return null
}

export function doUpdateRoom(roomId, changes, options = {}) {
  return async (dispatch, getState) => {
    // eslint-disable-next-line no-param-reassign
    changes.id = roomId
    let state = getState()
    const autoAdvancePreferences = selectAutoAdvancePreferences(state)
    const currentUser = selectCurrentUser(state)
    const foldersById = selectCurrentFoldersById(state)
    const widgetsById = selectWidgetsById(state)
    const entities = selectEntities(state)
    const roomsById = selectRoomsById(state)
    const oldRoom = roomsById[roomId]
    const transformedChanges = transformRoom(changes, currentUser)
    const updatedRoom = recomputeFields({
      ...oldRoom,
      ...transformedChanges,
    })

    const matrixRoomId = oldRoom.matrixRoomId
    const changeEvents = buildEvents(transformedChanges, state)
    const delay = changeEvents.length === 1 ? 0 : 50
    const transactionId = uuid()
    const queryId = selectCurrentQueryId(state)
    const currentConversationId = selectCurrentConversationId(state)
    const nextConversationId = selectSearchNextEntityIdByQueryIdAndEntityId(
      state,
      queryId,
      currentConversationId
    )

    dispatch({
      type: ROOM_OPTIMISTIC_UPDATED,
      payload: {
        roomId,
        room: updatedRoom,
        oldRoom,
        foldersById,
        widgetsById,
      },
      optimist: { type: BEGIN, id: transactionId },
      entities: {
        state: entities,
      },
    })
    const completeAction = {}
    try {
      await fireEvents(matrixRoomId, changeEvents, delay)
      state = getState()
      const searchesById = selectByQueryId(state)
      const unreadByChannelType = selectUnreadByChannelType(state)
      Object.assign(completeAction, {
        type: ROOM_OPTIMISTIC_COMPLETE,
        payload: {
          roomId,
          room: updatedRoom,
          oldRoom,
          foldersById,
          searchesById,
          unreadChannelTypes: unreadByChannelType,
        },
        optimist: { type: COMMIT, id: transactionId },
      })
      dispatch(completeAction)
    } catch (error) {
      dispatch({
        type: ROOM_OPTIMISTIC_FAILED,
        payload: {
          roomId,
          room: updatedRoom,
          oldRoom,
          error,
          foldersById,
        },
        optimist: { type: REVERT, id: transactionId },
        originalAction: completeAction,
      })
    }
    if (![false, null].includes(options.afterUpdateMessage)) {
      dispatch(
        doShowSnackbar(options.afterUpdateMessage || 'Conversation updated')
      )
    }
    const autoAdvancePreferenceName = getAutoAdvancePreferenceName(changes, {
      currentUser,
    })
    // This if looks a little weird, but it essentially boils down to.
    // Auto advance if the preferences is set and the afterUpdateAutoAdvance was not specified
    // OR
    // Auto advance if the afterUpdateAutoAdvance setting is truthy
    // This means we also cater for a situation where the auto advance preference is true but its
    // its been specifically disabled via the options provided
    if (
      (options.afterUpdateAutoAdvance === undefined &&
        autoAdvancePreferences[autoAdvancePreferenceName]) ||
      (!!options.afterUpdateAutoAdvance && nextConversationId)
    ) {
      dispatch(doOpenRoomPageById(nextConversationId))
    }
    return updatedRoom
  }
}

export function doToggleRoomState(roomId, options = {}) {
  const { state } = options
  return doUpdateRoom(
    roomId,
    {
      state: state === 'open' ? 'closed' : 'open',
    },
    options
  )
}

export function doSetRoomStateToClosed(roomId, options = {}) {
  const updates = {
    state: 'closed',
  }
  return doUpdateRoom(roomId, updates, options)
}

export function doSetRoomStateToOpen(roomId, options = {}) {
  return doUpdateRoom(
    roomId,
    {
      state: 'open',
    },
    options
  )
}

export function doSetRoomStateToSpam(roomId, options = {}) {
  return doUpdateRoom(
    roomId,
    {
      state: 'spam',
    },
    options
  )
}

export function doSetRoomStateToSnoozed(roomId, options = {}) {
  const { snoozedUntil } = options
  return doUpdateRoom(
    roomId,
    {
      state: 'snoozed',
      snoozedUntil,
    },
    options
  )
}

export function doSetRoomStateToTrash(roomId, options = {}) {
  return doUpdateRoom(
    roomId,
    {
      state: 'trash',
    },
    options
  )
}

export function doDeleteRoom(roomId, options = {}) {
  return async (dispatch, getState) => {
    const state = getState()
    const queryId = selectCurrentQueryId(state)
    const currentConversationId = selectCurrentConversationId(state)
    const nextConversationId = selectSearchNextEntityIdByQueryIdAndEntityId(
      state,
      queryId,
      currentConversationId
    )

    const query = `
      mutation RoomDelete($conversationId: ID!) {
        roomDelete(input: { conversationId: $conversationId }) {
          deletedConversationId
          errors {
            message
            path
          }
        }
      }
    `

    try {
      dispatch(doOpenRoomPageById(nextConversationId))
      const response = await dispatch(
        doGraphqlRequest(
          ROOM_DELETE,
          query,
          {
            conversationId: roomId,
          },
          {
            app: true,
            // Abit of a undescriptive api, but simply having this empty object adds optimist transaction handling
            optimist: {},
            throwOnError: true,
          }
        )
      )
      if (![false, null].includes(options.afterUpdateMessage)) {
        dispatch(
          doShowSnackbar(options.afterDeleteMessage || 'Conversation deleted')
        )
      }
      return response
    } catch {
      if (![false, null].includes(options.afterDeleteFailureMessage)) {
        dispatch(
          doShowSnackbar(
            options.afterDeleteFailureMessage || 'Conversation delete failed'
          )
        )
      }
      return false
    }
  }
}

export function doStarRoom(roomId, options = {}) {
  return doUpdateRoom(
    roomId,
    {
      isStarred: true,
    },
    options
  )
}

export function doUnStarRoom(roomId, options = {}) {
  return doUpdateRoom(
    roomId,
    {
      isStarred: false,
    },
    options
  )
}

export function doSyncRooms(roomUpdates) {
  return (dispatch, getState) => {
    const state = getState()
    const loadingMatrixIds = selectLoadingMatrixRoomIds(state)
    const queryId = selectCurrentQueryId(state)
    const currentSearch = selectSearchByQueryId(state, queryId)
    return dispatch({
      type: ROOMS_UPDATED,
      payload: {
        roomUpdates,
        loadingMatrixIds,
        currentSearch,
      },
    })
  }
}

export function doReadRoom(roomId, options = {}) {
  return doUpdateRoom(
    roomId,
    {
      isRead: true,
      ignoreNextClose: false,
    },
    options
  )
}

export function doUnreadRoom(roomId, options = {}) {
  return doUpdateRoom(
    roomId,
    {
      isRead: false,
      // Should we ignore the next mark as read when conversation
      // is getting closed (opening a conversation always marks it
      // as read)
      ignoreNextClose: options.ignoreNextClose || false,
    },
    options
  )
}

export function doUnassignRoom(roomId, options = {}) {
  return doUpdateRoom(
    roomId,
    {
      assignedType: 'none',
      assignedAgentId: null,
      assignedTeamId: null,
    },
    { afterUpdateMessage: 'Conversation unassigned', ...options }
  )
}

export function doAssignRoom(roomId, options = {}) {
  const { agentId, teamId } = options
  let assignedType = 'none'
  if (teamId) assignedType = 'team'
  if (agentId) assignedType = 'agent'
  return doUpdateRoom(
    roomId,
    {
      assignedType,
      assignedAgentId: agentId,
      assignedTeamId: teamId,
    },
    { afterUpdateMessage: 'Conversation reassigned', ...options }
  )
}

export function doAssignCurrentOrSelectedRoomsToCurrentUser(options = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const room = selectRoomFromUrl(state)
    const currentUser = selectCurrentUser(state)
    if (room.assignedAgentId !== currentUser.id) {
      return dispatch(
        doAssignRoom(room.id, { ...options, agentId: currentUser.id })
      )
    }
    return room
  }
}

export function doRealtimeRoomEvent(message, fromServiceWorker = false) {
  return async (dispatch, getState) => {
    const {
      data: { unread, createdAt },
    } = message

    const state = getState()
    const isInChat = selectIsInChat(state)
    const currentUser = selectCurrentUser(state)
    const isWebPushSupported = selectIsWebPushSupported(state)

    if (!fromServiceWorker && isWebPushSupported && isGranted()) {
      // NOTE (jscheel): Assumes we will be reacting to sw messages instead.
      return
    }

    if (currentUser && unread[`ag_${currentUser?.id}`]) {
      playAudioNotification(createdAt)
    }

    // eslint-disable-next-line consistent-return
    return dispatch({
      type: ROOMS_REALTIME_SYNC_UNREAD,
      payload: {
        unread,
        isInChat,
        currentUser,
      },
    })
  }
}
