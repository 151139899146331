import { selectCurrentConversationsById } from 'ducks/tickets/selectors'
import { createSelector } from 'reselect'
import { selectSelectedTicketIds } from 'selectors/ticket_list'
import { isEmpty, emptyArr, uniq, compact } from 'util/arrays'

export const selectSelectedTicketsMailboxIds = createSelector(
  selectSelectedTicketIds,
  selectCurrentConversationsById,
  (ids, byId) => {
    if (!ids || isEmpty(ids)) return emptyArr
    return uniq(
      compact(
        ids.filter(id => {
          return byId[id] ? byId[id].mailboxId : undefined
        })
      )
    )
  }
)
