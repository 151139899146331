import { customFieldValueNodeQuery } from 'ducks/crm/customFields/operations/fetching'

export const fetchContactCustomFieldValuesForIdQuery = `
  query ContactCustomFieldValuesPage($id: ID!, $first: Int, $after: String) {
    node(id: $id) {
      ... on Contact {
        id
        email
        name
        avatarUrl
        customFieldValues(first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          ${customFieldValueNodeQuery}
        }
      }
    }
  }
`
