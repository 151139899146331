import merge from 'deepmerge'

const validOperations = ['update', 'replace', 'remove']
const validStores = ['current', 'pending']

export function changeEntity(
  entityType,
  entityId,
  entity,
  operation = 'update',
  store = 'current'
) {
  if (!validOperations.includes(operation)) {
    throw new Error(
      `changeEntity operation ${operation} is not a supported type`
    )
  }
  if (!validStores.includes(store)) {
    throw new Error(`changeEntity store ${store} is not a supported type`)
  }

  return {
    entities: {
      [store]: {
        [operation]: {
          [entityType]: { [entityId]: entity },
        },
      },
    },
  }
}

const validSyncOperations = ['merge', 'replace', 'move']

export function syncEntity(
  entityType,
  entityId,
  operation = 'merge',
  store = 'current'
) {
  if (!validSyncOperations.includes(operation)) {
    throw new Error(`syncEntity operation ${operation} is not a supported type`)
  }
  if (!validStores.includes(store)) {
    throw new Error(`syncEntity store ${store} is not a supported type`)
  }

  const fullOperation = `${operation}Sync`
  const destinationStore = store === 'current' ? 'pending' : 'current'

  if (operation === 'move') {
    return {
      entities: {
        [store]: {
          remove: {
            [entityType]: { [entityId]: { id: entityId } },
          },
        },
        [destinationStore]: {
          replaceSync: {
            [entityType]: { [entityId]: { id: entityId } },
          },
        },
      },
    }
  }

  return {
    entities: {
      [destinationStore]: {
        [fullOperation]: {
          [entityType]: { [entityId]: { id: entityId } },
        },
      },
    },
  }
}

export function clearEntities(entityTypes, store = 'current') {
  return {
    entities: {
      [store]: {
        clear: [entityTypes],
      },
    },
  }
}

export function mergeEntityChanges(entityChanges) {
  return merge.all([...entityChanges.filter(x => !!x)])
}

export const entityAdditionalActionToActions = (
  currentPhase,
  additionalActions
) => {
  const entityChanges = []
  additionalActions.forEach(entityAction => {
    const {
      phases = ['STARTED', 'SUCCESS', 'FAILED'],
      entityId,
      entityType,
      operation,
      entity = {}, // not needed for remove operations
      type = 'change',
    } =
      entityAction || {}

    if (phases.includes(currentPhase)) {
      entityAction.stores.forEach(store => {
        if (type === 'sync') {
          entityChanges.push(syncEntity(entityType, entityId, operation, store))
        } else if (type === 'change') {
          entityChanges.push(
            changeEntity(entityType, entityId, entity, operation, store)
          )
        } else if (type === 'clear') {
          entityChanges.push(clearEntities(entityType, store))
        }
      })
    }
  })
  return entityChanges
}

/* Usefull during debugging
window.changeEntity = changeEntity
window.mergeEntityChanges = mergeEntityChanges
window.syncEntity = syncEntity
window.clearEntities = clearEntities
*/
