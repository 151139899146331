import ai from './ai'
import batchJobs from './batchJobs/reducers'
import billing from './billing'
import bootstrap from './bootstrap/reducers'
import cannedReplies from './cannedReplies'
import chat from './chat'
import collisions from './collisions'
import crm from './crm'
import currentUser from './currentUser'
import customers from './customers'
import customerRatingSettings from './customerRatingSettings'
import drawers from './drawers'
import editor from './editor'
import entities from './entities'
import events from './events'
import flags from './flags/reducers'
import folders from './folders'
import undoSend from './undoSend/reducers'
import inAppCards from './inAppCards'
import integrations from './integrations/reducers'
import merging from './merging'
import modals from './modals'
import notifications from './notifications'
import reports from './reports'
import requests from './requests'
import rules from './rules'
import searches from './searches'
import spammers from './spammers'
import tables from './tables/reducers'
import ticketing from './ticketing'
import tracking from './tracking'
import translations from './translations'
import wallets from './wallets'
import widgets from './widgets'
import starredCannedReplies from './starredCannedReplies'

const ducks = {
  ai,
  batchJobs,
  billing,
  bootstrap,
  cannedReplies,
  chat,
  collisions,
  crm,
  currentUser,
  customers,
  customerRatingSettings,
  drawers,
  editor,
  entities,
  events,
  flags,
  foldersV2: folders,
  undoSend,
  inAppCards,
  integrations,
  merging,
  modals,
  notifications,
  reports,
  request: requests,
  rules,
  searchV2: searches,
  spammers,
  starredCannedReplies,
  tables,
  ticketing,
  tracking: {
    reducer: tracking,
    includes: ['app', 'billing', 'entities', 'request'],
  },
  translations,
  wallets,
  widgets,
}

const validDucks = Object.keys(ducks).reduce((result, duckName) => {
  const duck = ducks[duckName]
  if (typeof duck === 'function' || typeof duck === 'object') {
    // eslint-disable-next-line no-param-reassign
    result[duckName] = duck
  }
  return result
}, {})

export default validDucks

export * as reports from './reports'
