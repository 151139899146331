const startedSnackbarDefaults = {
  enabled: false,
  message: 'Action started',
  link: undefined,
  duration: 3000,
}

const successSnackbarDefaults = {
  enabled: true,
  message: 'Action completed',
  link: undefined,
  duration: 3000,
}

const failedSnackbarDefaults = {
  enabled: true,
  message: 'Action failed',
  link: undefined,
  duration: 3000,
}

export default function snackbarActionModule(phase, action, _payload, options) {
  const {
    moduleOptions: {
      snackbar: {
        enabled = false,
        started: startedSnackbar = startedSnackbarDefaults,
        success: successSnackbar = successSnackbarDefaults,
        failed: failedSnackbar = failedSnackbarDefaults,
      } = {},
    } = {},
  } =
    options || {}

  if (!enabled) return action

  if (phase === 'STARTED') {
    const startedSnackbarFinal = {
      ...startedSnackbarDefaults,
      ...startedSnackbar,
    }
    if (startedSnackbarFinal.enabled) {
      Object.assign(action, {
        snackbar: startedSnackbarFinal,
      })
    }
  } else if (phase === 'SUCCESS') {
    const successSnackbarFinal = {
      ...successSnackbarDefaults,
      ...successSnackbar,
    }
    if (successSnackbarFinal.enabled) {
      Object.assign(action, {
        snackbar: successSnackbarFinal,
      })
    }
  } else if (phase === 'FAILED') {
    const failedSnackbarFinal = {
      ...failedSnackbarDefaults,
      ...failedSnackbar,
    }
    if (failedSnackbarFinal.enabled) {
      Object.assign(action, {
        snackbar: failedSnackbarFinal,
      })
    }
  }

  return action
}
