import { createBasicSelector } from 'util/redux'

import selectTrelloListsById from './selectTrelloListsById'

export default createBasicSelector(
  selectTrelloListsById,
  (_, listId) => listId,
  (lists, listId) => {
    const list = lists && lists[listId]
    return list && list.loading
  }
)
