import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { buildConversationOptimistRequestOptions } from 'ducks/tickets/utils/optimistic'
import { onUpdateAttachEventGroupIdToEvents } from 'ducks/tickets/utils/request'
import { changeContactMutationSchema } from '../../schema'
import { CHANGE_CONVERSATION_CONTACT } from '../../types'
import { changeContactMutation } from './changeContactMutation'

export const doChangeContactForConversation = (
  conversationId,
  conversationNumber,
  contactId
) => async (dispatch, getState) => {
  const variables = {
    conversationId,
    contactId,
  }
  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    conversationNumber,
    {
      conversationId: conversationNumber,
      contactId,
    },
    {}
  )

  return dispatch(
    doAppGraphqlRequest(
      CHANGE_CONVERSATION_CONTACT,
      changeContactMutation,
      variables,
      {
        optimist,
        meta: {
          mergeEntities: true,
        },
        normalizationSchema: changeContactMutationSchema,
        transformResponse: onUpdateAttachEventGroupIdToEvents(
          'conversationChangeContact'
        ),
        moduleOptions: {
          entities: {
            additionalActions,
          },
        },
      }
    )
  )
}
