import { createActionTypeReducer } from 'util/reducers'
import {
  MERGE_AND_RELOAD_CONVERSATION_FAILED,
  MERGE_AND_RELOAD_CONVERSATION_STARTED,
  MERGE_AND_RELOAD_CONVERSATION_SUCCESS,
} from 'ducks/tickets/actionTypes'
import {
  CHANGE_MERGE_DIRECTION,
  UPDATE_MERGING_MERGE_TICKET,
  RESET_TO_DEFAULTS,
  MERGE_SEARCH_STARTED,
  UPDATE_MERGING_SEARCH_TERM,
} from './types'

const defaultState = {
  direction: 'normal',
  mergeTicket: {
    id: null,
    title: null,
  },
  searchTerm: '',
  isMerging: false,
  queryId: null,
  initialQueryId: null,
}

function resetToDefaults(draftState) {
  draftState.direction = 'normal'
  draftState.mergeTicket = { id: null, title: null }
  draftState.searchTerm = ''
  draftState.queryId = null
  draftState.initialQueryId = null
  draftState.isMerging = false
}

export default createActionTypeReducer(
  {
    [UPDATE_MERGING_SEARCH_TERM]: (draftState, action) => {
      const {
        payload: { search },
      } = action

      draftState.searchTerm = search
      if (search?.trim() === '') {
        draftState.queryId = draftState.initialQueryId
      }
      return draftState
    },
    [MERGE_SEARCH_STARTED]: (draftState, action) => {
      const {
        request: {
          parameters: { queryId },
        },
      } = action

      draftState.queryId = queryId
      draftState.initialQueryId = draftState.initialQueryId || queryId
      return draftState
    },
    [UPDATE_MERGING_MERGE_TICKET]: (draftState, action) => {
      const { mergeTicket } = action.data

      if (!mergeTicket) {
        draftState.mergeTicket = { id: null, title: null }
        return draftState
      }

      const { id, title } = mergeTicket
      draftState.mergeTicket = { id, title }

      return draftState
    },
    [CHANGE_MERGE_DIRECTION]: (draftState, action) => {
      const { direction } = action.data

      draftState.direction = direction
      return draftState
    },
    [RESET_TO_DEFAULTS]: draftState => {
      resetToDefaults(draftState)
      return draftState
    },
    [MERGE_AND_RELOAD_CONVERSATION_STARTED]: draftState => {
      draftState.isMerging = true
      return draftState
    },
    [MERGE_AND_RELOAD_CONVERSATION_FAILED]: draftState => {
      resetToDefaults(draftState)
      return draftState
    },
    [MERGE_AND_RELOAD_CONVERSATION_SUCCESS]: draftState => {
      resetToDefaults(draftState)
      return draftState
    },
  },
  defaultState
)
