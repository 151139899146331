import requestActionModule from './requestActionModule'
import metaActionModule from './metaActionModule'
import optimistActionModule from './optimistActionModule'
import entitiesActionModule from './entitiesActionModule'
import searchesActionModule from './searchesActionModule'
import toastActionModule from './toastActionModule'
import snackbarActionModule from './snackbarActionModule'
import modalActionModule from './modalActionModule'

export const DEFAULT_MODULES = [
  requestActionModule,
  metaActionModule,
  optimistActionModule,
  entitiesActionModule,
  searchesActionModule,
  toastActionModule,
  snackbarActionModule,
  modalActionModule,
]
