import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'
import { selectCurrentPlansByType } from './selectCurrentPlansByType'

export const selectCurrentPlanByType = createBasicSelector(
  selectCurrentPlansByType,
  plans => {
    return plans[0] || emptyObj
  }
)
