import { selectPrefers2FAEnforced } from 'selectors/app/selectAccountPreferences'
import { createBasicSelector } from 'util/redux'
import { selectCurrentUser2faEnabled } from './selectCurrentUser2faEnabled'
import { selectCurrentUserId } from './selectCurrentUserId'

export const selectCurrentUser2faEnforced = createBasicSelector(
  selectPrefers2FAEnforced,
  selectCurrentUser2faEnabled,
  selectCurrentUserId,
  (prefers2FAEnforced, currentUser2faEnabled, id) =>
    prefers2FAEnforced && !currentUser2faEnabled && !!id
)
