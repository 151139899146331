import { selectCurrentEntityById } from 'ducks/entities/selectors'
import { selectRequestByType } from 'ducks/requests/selectors'
import { createBasicSelector } from 'util/redux'
import { buildConversatioRawEmailRequestKey } from './utils'

export const selectRawEmailById = (
  state,
  conversationId,
  isLoadedFn,
  shouldDenormalize
) =>
  selectCurrentEntityById(
    state,
    'rawEmail',
    conversationId,
    isLoadedFn,
    shouldDenormalize
  ) || null

export const selectRawEmailRequestById = createBasicSelector(
  (state, rawEmailId) => {
    return selectRequestByType(
      state,
      buildConversatioRawEmailRequestKey(rawEmailId)
    )
  }
)
