import { OPEN_GROUP, CLOSE_GROUP } from '../actionTypes/groups'
import { selectOpenGroupIds } from '../selectors/groups'

export function doOpenGroup({ groupId }) {
  return {
    type: OPEN_GROUP,
    payload: {
      groupId,
    },
  }
}

export function doCloseGroup({ groupId }) {
  return {
    type: CLOSE_GROUP,
    payload: {
      groupId,
    },
  }
}

export function doToggleGroup({ groupId }) {
  return (dispatch, getState) => {
    const state = getState()
    const openGroupsIds = selectOpenGroupIds(state)
    const changeFunction = openGroupsIds.includes(groupId)
      ? doCloseGroup
      : doOpenGroup
    return dispatch(changeFunction({ groupId }))
  }
}
