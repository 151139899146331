import { doAppGraphqlRequest } from 'ducks/requests/operations'
import {
  allConversationFragments,
  conversationFragment,
  widgetConversationFragment,
} from 'ducks/tickets/fragments'

import { FETCH_RECENT_CONVERSATIONS } from './types'
import { conversationsGraphQlResponseSchema } from './schema'

const fetchRecentConversationsQuery = `
  query FetchRecentConversations($contactId: ID!, $orderBy: ConversationOrder) {
    rooms(first: 10, filter: { contactId: $contactId }) {
      nodes {
        agentId
        id
        isRead
        name
        number
        snoozedUntilTimestamp
        state
        updatedTimestamp
        channelId
      }
    }
    conversations(first: 10, filter: { contactId: $contactId }, orderBy: $orderBy) {
      nodes {
        ${allConversationFragments()}
      }
    }
  }
  ${conversationFragment({ basic: true })}
  ${widgetConversationFragment()}
`

export function doFetchRecentConversationsAndRooms(
  contactId,
  sortByCollaboratorCommentAtEnabled
) {
  const requestKey = `fetchRecentConversations-${contactId}`

  const variables = {
    contactId,
  }

  if (sortByCollaboratorCommentAtEnabled) {
    // otherwise use default sort field
    variables.orderBy = {
      field: 'LATEST_COLLABORATOR_COMMENT_AT',
      direction: 'DESC',
    }
  }

  return doAppGraphqlRequest(
    FETCH_RECENT_CONVERSATIONS,
    fetchRecentConversationsQuery,
    variables,
    {
      normalizationSchema: conversationsGraphQlResponseSchema,
      meta: { contactId, requestKey, mergeEntities: true },
    }
  )
}
