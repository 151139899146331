import { createBasicSelector } from 'util/redux'
import { emptyObj } from 'util/objects'

import selectIntegrationSettingsByProvider from './selectIntegrationSettingsByProvider'

export default createBasicSelector(
  selectIntegrationSettingsByProvider,
  (_, { provider }) => provider,
  (byProvider, provider) => {
    const settings = byProvider[provider] || byProvider[provider.toUpperCase()]
    if (!settings) return emptyObj

    return settings
  }
)
