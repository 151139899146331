import { MAIN_PAGE, SEARCH_PAGE } from 'constants/pages'
import { isLegacySearch } from 'ducks/searches/utils/query'
import { createSelector } from 'reselect'
import { selectCurrentPage, selectCurrentPayload } from 'selectors/location'
import { createBasicSelector } from 'util/redux'

export const selectIsOnSearchPageType = createSelector(
  selectCurrentPage,
  pageType => pageType === SEARCH_PAGE
)

export const selectIsOnMainPage = createBasicSelector(
  selectCurrentPage,
  pageType => pageType === MAIN_PAGE
)

export const selectIsOnSearchPageWithLegacySearch = createSelector(
  selectIsOnSearchPageType,
  selectCurrentPayload,
  (isSearch, payload) => {
    return isSearch && isLegacySearch(payload)
  }
)
