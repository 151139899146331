import { createSelector } from 'reselect'

import { selectCurrentContactId } from 'ducks/crm/contacts'
import { selectCurrentConversationsById } from 'ducks/tickets/selectors'
import { selectAgentsById } from 'selectors/agents/base'
import { selectAccountPreferenceSortByCollaboratorCommentEnabled } from 'selectors/app/selectAccountPreferences'
import { emptyObj } from 'util/objects'
import { selectBase as selectRequests } from 'ducks/requests/selectors'
import { selectRoomsById } from 'ducks/chat/selectors/rooms'
import { selectWidgetsById } from 'ducks/widgets/selectors/widgets'
import { getRawId } from 'util/globalId'
import { SNOOZED } from 'ducks/tickets/constants'

import { CONVERSATION, ROOM } from './types'

function selectBase(state) {
  return state?.integrations?.recentConversations || emptyObj
}

export const selectRecentConversationsAndChatsForCurrentContact = createSelector(
  selectBase,
  selectCurrentContactId,
  selectCurrentConversationsById,
  selectAgentsById,
  selectRoomsById,
  selectWidgetsById,
  selectAccountPreferenceSortByCollaboratorCommentEnabled,
  (
    base,
    contactId,
    ticketsById,
    agentsById,
    roomsById,
    widgetsById,
    sortByCollaboratorCommentAtEnabled
  ) => {
    const recentConversations = base[contactId]
    return recentConversations?.map(
      ({
        assignee: recentAssignee,
        id,
        isRead,
        name,
        number,
        state: recentState,
        subject: recentSubject,
        type,
        updatedAt: recentUpdatedAt,
        channelId,
      }) => {
        if (type === CONVERSATION) {
          // We have to extract and merge in data from the redux
          // store to ensure optimistic updates are reflected
          const reduxTicket = ticketsById[number]
          const agent = agentsById[reduxTicket?.assigned?.agent]
          const reduxState = reduxTicket?.snoozed ? SNOOZED : reduxTicket?.state
          const state = reduxTicket
            ? reduxState.toLowerCase()
            : recentState?.toLowerCase()

          const {
            updatedAt: latestCollaboratorCommentAt,
            systemUpdatedAt: ticketUpdatedAt,
          } =
            reduxTicket || {}

          const updatedAt =
            (sortByCollaboratorCommentAtEnabled
              ? latestCollaboratorCommentAt
              : ticketUpdatedAt) || recentUpdatedAt

          return {
            assignee: agent ? agent?.username : recentAssignee,
            id,
            number,
            state,
            title: name || reduxTicket ? reduxTicket?.subject : recentSubject,
            type,
            updatedAt,
            from: 'email',
          }
        } else if (type === ROOM) {
          let from = 'chat'

          const widgetId = getRawId(channelId)
          const { kind } = widgetsById[widgetId] || {}

          const reduxRoom = roomsById[number]
          const agent =
            agentsById[
              getRawId(reduxRoom?.agentId) || getRawId(recentAssignee?.id)
            ]
          const state = reduxRoom
            ? reduxRoom?.state
            : recentState?.toLowerCase()

          if (kind === 'facebook') {
            from = 'messenger'
          } else if (kind === 'instagram') {
            from = 'instagram'
          }

          return {
            assignee: agent?.username,
            id,
            number,
            state: isRead === false ? 'unread' : state,
            title: name,
            type,
            updatedAt: reduxRoom?.updatedAt || recentUpdatedAt,
            from,
          }
        }
        return null
      }
    )
  }
)

export const selectIsOrWillBeLoadingRecentConversationsAndChatsForCurrentContact = createSelector(
  selectCurrentContactId,
  selectRequests,
  (contactId, requests) => {
    return (
      requests && requests[`fetchRecentConversations-${contactId}`]?.loading
    )
  }
)
