import toast from '@groovehq/internal-design-system/lib/components/Toast'
import Bugsnag from '@bugsnag/js'
import doFetchAllAgents from 'actions/agents/doFetchAllAgents'
import {
  doApiWriteRequest,
  doAppGraphqlRequest,
} from 'ducks/requests/operations'
import { agent as agentEntity } from 'ducks/entities/schema'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import {
  AGENT_DATA_TABLE_QUERY_ID,
  LEGACY_AGENT_ROLE_ADMIN,
  LEGACY_AGENT_ROLE_AGENT,
  LEGACY_AGENT_ROLE_OWNER,
  LEGACY_AGENT_ROLE_VIEWER,
} from 'ducks/agents/constants'
import {
  filterEntities as defaultFilterEntities,
  sortEntities as defaultSortEntities,
} from 'ducks/searches/utils/memory'
import {
  selectCurrentAgentsById,
  selectPendingAgentsByIds,
} from 'ducks/agents/selectors'
import {
  changeEntity,
  mergeEntityChanges,
  clearEntities,
} from 'ducks/entities/actionUtils'
import { asyncForEach } from 'util/functions'
import { searchInvalidateEntity } from 'ducks/searches/utils/action'
import { TABLE_CACHE_INVALIDATE } from 'ducks/tables/actionTypes'
import { doTryFetchAccountUsageOnboardingForOnboarding } from 'ducks/accountPreferences/operations'
import { selectFeatureBasedOnboardingWorkflowData } from 'subapps/onboarding/selectors'
import { omit } from 'util/objects'
import { buildId } from 'util/globalId'
import {
  agentSendAdminEmailNotification,
  resetLoginAttemptsMutation,
  resetPasswordMutation,
} from './queries'
import { compareRole } from './utils'
import {
  RESEND_INVITATION,
  REVOKE_INVITATION,
  CREATE_INVITATION,
  CREATE_INVITATION_BULK,
  UPDATE_USER,
  RESET_PASSWORD,
  SAVE_AGENT_DRAFTS,
  CLEAR_AGENTS_DRAFTS,
  RESET_LOGIN_ATTEMPTS,
  CHANGE_PASSWORD,
  AGENT_SEND_ADMIN_EMAIL_NOTIFICATION,
} from './types'

export const doFetchAgentsInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: AGENT_DATA_TABLE_QUERY_ID,
  entityType: 'agent',
  doLoadAllFn: doFetchAllAgents,
  STARTED_ACTION_TYPE: 'FETCH_AGENTS_INMEMORY',
  SUCCESS_ACTION_TYPE: 'FETCH_AGENTS_INMEMORY',
  sortEntities: (orderBy, entities) => {
    if (!orderBy) return entities
    if (orderBy.field === 'role') {
      return entities.sort((a, b) => compareRole(a, b, orderBy.direction))
    }
    return defaultSortEntities(orderBy, entities)
  },
  filterEntities: (filterBy, entities) => {
    const filterByCopy = { ...filterBy }
    let filtered = entities
    const scope = filterByCopy.scope
    if (scope) {
      filtered = filtered.filter(entity => {
        if (scope === 'active') {
          return !entity.archived && entity.hasAcceptedInvitation
        } else if (scope === 'invited') {
          return !entity.hasAcceptedInvitation
        } else if (scope === 'archived') {
          return entity.archived
        }
        return false
      })
    }
    delete filterByCopy.scope
    return defaultFilterEntities(filterByCopy, filtered)
  },
})

// eslint-disable-next-line no-unused-vars
export const doResendInvitation = (id, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const agents = selectCurrentAgentsById(state)
  const agent = agents[id]
  if (!agent) return Promise.resolve()

  return dispatch(
    doApiWriteRequest(
      RESEND_INVITATION,
      `v1/agents/${agent.id}/reinvite`,
      {},
      {
        method: 'GET',
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Invitation email resent',
            },
            failed: {
              content: 'Invitation sending failed',
              onClickAction: () => doResendInvitation(id, options),
            },
          },
        },
      }
    )
  )
}

// eslint-disable-next-line no-unused-vars
export const doCreateInvitation = (user, options = {}) => dispatch => {
  const apiOptions = {
    method: 'POST',
    moduleOptions: {
      toasts: {
        enabled: true,
        started: {
          enabled: false,
        },
        success: {
          enabled: true,
          content: `Invitation sent to ${user.email}`,
        },
        failed: {
          content: 'Invitation sending failed',
          onClickAction: () => dispatch(doCreateInvitation(user, options)),
        },
      },
    },
  }
  if (options.invalidateEntities !== false) {
    apiOptions.searches = {
      additionalActions: [
        {
          type: 'INVALIDATE_ENTITIES',
          entityTypes: ['agent'],
          phases: ['SUCCESS'],
        },
      ],
    }
  }
  return dispatch(
    doApiWriteRequest(
      CREATE_INVITATION,
      'v2/invitations',
      {
        email: user.email,
        role: user.role,
      },
      apiOptions
    )
  )
}

// eslint-disable-next-line no-unused-vars
export const doCreateInvitations = (users, options = {}) => dispatch => {
  asyncForEach(users, user => {
    return dispatch(doCreateInvitation(user, { invalidateEntities: false }))
  }).then(() => {
    dispatch({
      type: TABLE_CACHE_INVALIDATE,
      ...searchInvalidateEntity('agent'),
    })
  })
}

export const doCreateBulkInvitations = (
  users,
  mailboxIds,
  options = {}
) => async (dispatch, getState) => {
  // delete the temp entities after invitation is sent
  const additionalActions = users.map(u => {
    return {
      entityType: 'agent',
      entityId: u.id,
      stores: ['pending', 'current'],
      operation: 'remove',
      phases: ['SUCCESS'],
    }
  })

  const apiOptions = {
    method: 'POST',
    moduleOptions: {
      entities: {
        additionalActions,
      },
      toasts: {
        enabled: true,
        started: {
          enabled: false,
        },
        success: {
          enabled: true,
          content: 'Invitations sent',
        },
        failed: {
          content: 'Invitations sending failed',
          onClickAction: () =>
            dispatch(doCreateBulkInvitations(users, mailboxIds, options)),
        },
      },
    },
  }

  if (options.invalidateEntities !== false) {
    apiOptions.searches = {
      additionalActions: [
        {
          type: 'INVALIDATE_ENTITIES',
          entityTypes: ['agent'],
          phases: ['SUCCESS'],
        },
      ],
    }
  }

  if (options.shouldFetchAllAgents) {
    // Fetch agents if use the agent creaction drawers outside of the UsersDataTable
    apiOptions.onBeforeSuccessAction = () => dispatch(doFetchAllAgents())
  }

  // remove ids from agents as it's a create
  const usersPayload = users.map(u => omit(['id'], u))
  const onboardingWorkflowData = selectFeatureBasedOnboardingWorkflowData(
    getState()
  )

  const response = await dispatch(
    doApiWriteRequest(
      CREATE_INVITATION_BULK,
      'v1/bulk_invitations',
      {
        users: usersPayload,
        mailbox_ids: mailboxIds,
      },
      apiOptions
    )
  )
  dispatch(
    doTryFetchAccountUsageOnboardingForOnboarding(
      onboardingWorkflowData.teammates?.usageKey
    )
  )
  return response
}

export const doCreateBulkInvitationsByDraftUserIds = (
  draftIds,
  mailboxIds,
  options = {}
) => (dispatch, getState) => {
  const state = getState()
  const agents = selectPendingAgentsByIds(state, draftIds)

  return dispatch(doCreateBulkInvitations(agents, mailboxIds, options))
}

// eslint-disable-next-line no-unused-vars
export const doRevokeInvitation = (id, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const agents = selectCurrentAgentsById(state)
  const agent = agents[id]
  if (!agent) return Promise.resolve()

  return dispatch(
    doApiWriteRequest(
      REVOKE_INVITATION,
      `v1/agents/${agent.id}/revoke_invitation`,
      {},
      {
        method: 'GET',
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['agent'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Invitation revoked',
            },
            failed: {
              content: 'Invitation revoke failed',
              onClickAction: () => doResendInvitation(id, options),
            },
          },
        },
      }
    )
  )
}

const get2faStatus = object => {
  if (object.otp_confirmed) return 'confirmed'
  if (object.otp_enabled) return 'enabled'
  return 'disabled'
}

const translateAgentV1ResponseToGql = response => {
  if (response.errors) {
    const error = new Error(
      `Received Error Response From Server: ${JSON.stringify(response.errors)}`
    )
    error.response = response
    throw error
  }

  const entity = response.agent
  if (!entity) return response

  return {
    id: entity.id,
    role: entity.role,
    archived: entity.archived,
    invitationAccepted: entity.invitation_accepted,
    status2fa: get2faStatus(entity),
  }
}

export const doUpdateUser = (id, data, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const agents = selectCurrentAgentsById(state)
  const agent = agents[id]

  if (!agent) return Promise.resolve()

  const payload = {
    agent: { ...data },
  }

  if (options.password) {
    payload.password = options.password
  }

  const entityId = agent.id
  const entityType = 'agent'
  const entityData = { ...agent, ...data }

  const optimist = {
    entities: {
      [entityType]: {
        [entityId]: entityData,
      },
    },
  }

  return dispatch(
    doApiWriteRequest(UPDATE_USER, `v1/agents/${agent.id}`, payload, {
      method: 'PUT',
      optimist,
      normalizationSchema: agentEntity,
      transformResponse: translateAgentV1ResponseToGql,
      throwOnError: options.throwOnError,
      searches: {
        additionalActions: [
          {
            type: 'INVALIDATE_ENTITIES',
            entityTypes: ['agent'],
            phases: ['SUCCESS'],
          },
        ],
      },
      moduleOptions: {
        toasts: {
          enabled: options.toastsEnabled !== false,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: options.successToastMessage || `${app.t('Agent')} updated`,
          },
          failed: {
            content:
              options.failureToastMessage || `${app.t('Agent')} update failed`,
            onClickAction: () => dispatch(doUpdateUser(id, data, options)),
          },
        },
      },
    })
  )
}

export const doChangeToViewer = (id, options = {}) =>
  doUpdateUser(
    id,
    { role: LEGACY_AGENT_ROLE_VIEWER },
    {
      successToastMessage: `${app.t('Agent')} role updated`,
      failureToastMessage: `${app.t('Agent')} role change failed`,
      ...options,
    }
  )

export const doChangeToAgent = (id, options = {}) =>
  doUpdateUser(
    id,
    { role: LEGACY_AGENT_ROLE_AGENT },
    {
      successToastMessage: `${app.t('Agent')} role updated`,
      failureToastMessage: `${app.t('Agent')} role change failed`,
      ...options,
    }
  )

export const doChangeToAdmin = (id, options = {}) =>
  doUpdateUser(
    id,
    { role: LEGACY_AGENT_ROLE_ADMIN },
    {
      successToastMessage: `${app.t('Agent')} changed to admin`,
      failureToastMessage: `${app.t('Agent')} role change failed`,
      ...options,
    }
  )

export const doChangeToOwner = (id, password, options = {}) =>
  doUpdateUser(
    id,
    { role: LEGACY_AGENT_ROLE_OWNER },
    {
      password,
      successToastMessage: `${app.t('Agent')} changed to owner`,
      failureToastMessage: `${app.t('Agent')} role change failed`,
      ...options,
    }
  )

export const doArchiveAgent = (id, options = {}) =>
  doUpdateUser(
    id,
    { archived: true },
    {
      successToastMessage: `${app.t('Agent')} archived`,
      failureToastMessage: (phase, action, payload) => {
        const defaultDescription = `${app.t('Agent')} archive failed`
        const activity = payload?.error?.response?.errors?.activity

        return (activity && activity[0]) || defaultDescription
      },
      ...options,
    }
  )

export const doUnarchiveAgent = (id, options = {}) =>
  doUpdateUser(
    id,
    { archived: false },
    {
      successToastMessage: `${app.t('Agent')} unarchived`,
      failureToastMessage: `${app.t('Agent')} unarchive failed`,
      ...options,
    }
  )

export const doResetPassword = (id, options = {}) => (dispatch, getState) => {
  const state = getState()
  const agents = selectCurrentAgentsById(state)
  const agent = agents[id]
  if (!agent) return Promise.resolve()

  return dispatch(
    doApiWriteRequest(
      RESET_PASSWORD,
      `v1/agents/${agent.id}/forgot_password`,
      {},
      {
        method: 'POST',
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Password reset email sent',
            },
            failed: {
              content: 'Password reset email sending failed',
              onClickAction: () => doResetPassword(id, options),
            },
          },
        },
      }
    )
  )
}

export const doSaveAgentDrafts = agents => {
  return {
    type: SAVE_AGENT_DRAFTS,
    ...mergeEntityChanges([
      ...agents.map(agent =>
        changeEntity('agent', agent.id, agent, 'update', 'pending')
      ),
    ]),
  }
}

export const doClearAllAgentDrafts = () => {
  return {
    type: CLEAR_AGENTS_DRAFTS,
    ...clearEntities('agent', 'pending'),
  }
}

export const doResetLoginAttempts = agentId => dispatch => {
  const agentGid = buildId('Agent', agentId)

  return dispatch(
    doAppGraphqlRequest(
      RESET_LOGIN_ATTEMPTS,
      resetLoginAttemptsMutation,
      { agentId: agentGid },
      {
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['agent'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: `${app.t('Agent')} 2fa attempts has been reset`,
            },
            failed: {
              enabled: true,
              content: `${app.t('Agent')} 2fa attempts reset failed`,
            },
          },
        },
      }
    )
  )
}

export const doChangePasswordWithResetToken = input => async dispatch => {
  const { token, recaptchaToken, password, passwordConfirm, authCode } = input

  return dispatch(
    doAppGraphqlRequest(
      CHANGE_PASSWORD,
      resetPasswordMutation,
      {
        token,
        recaptchaToken,
        password,
        passwordConfirm,
        authCode,
      },
      {
        transformResponse: response => ({
          errors: response?.agentResetPassword?.errors,
        }),
      }
    )
  )
}

export const doAgentSendAdminEmailNotification = notificationType => async dispatch => {
  try {
    const response = await dispatch(
      doAppGraphqlRequest(
        AGENT_SEND_ADMIN_EMAIL_NOTIFICATION,
        agentSendAdminEmailNotification,
        {
          notificationType,
        },
        {
          throwOnError: true,
        }
      )
    )

    const recentNotificationAlert =
      response.agentSendAdminEmailNotification?.errors?.[0]?.message

    if (recentNotificationAlert) {
      toast.notify({
        type: 'warning',
        content: recentNotificationAlert,
        noAction: true,
      })
    } else {
      toast.notify({
        type: 'positive',
        content: 'Access request sent',
        noAction: true,
      })
    }

    return response
  } catch (error) {
    toast.notify({
      type: 'error',
      content: 'Failed to send access request',
      duration: null,
      onClickAction: () =>
        dispatch(doAgentSendAdminEmailNotification(notificationType)),
    })
    Bugsnag.notify(error)
    return { error }
  }
}
