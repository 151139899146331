import { emit } from 'util/realtime'
import {
  selectIsViewingConversation,
  selectIsReplying,
  selectIsForwarding,
  selectIsAddingNote,
} from 'selectors/page'
import { selectIsImpersonating } from 'selectors/app/selectIsImpersonating'
import { selectCurrentUserId } from 'ducks/currentUser/selectors/selectCurrentUserId'
import { selectIsEditorFocused } from 'ducks/editor'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { getVisibilityState } from 'util/browser'
import { debounce } from 'util/functions'
import WindowVisibility from 'util/window_visibility'

import {
  selectAgentsForTypingReplyCollisionByConversationId,
  selectAgentsForTypingNoteCollisionByConversationId,
} from './selectors'

export const agentCollisionsMiddleware = store => next => action => {
  const result = next(action)
  const state = store.getState()

  const isPageAction = action.type.endsWith('_PAGE')
  const isEditorAction = action.type.startsWith('editor/')
  const isImpersonating = selectIsImpersonating(state)

  if ((isPageAction || isEditorAction) && !isImpersonating) {
    setCurrentAgentCollisionStatus(store.getState)
  }

  return result
}

export const startTyping = debounce(
  (ticketId, isNote) => {
    emit(!isNote ? 'typeReplyOnTicket' : 'typeNoteOnTicket', {
      ticketId,
      phase: 'begin',
    })
  },
  500,
  true
)

let isOnTicket = false
export function setCurrentAgentCollisionStatus(getState) {
  const state = getState()
  const ticketId = selectCurrentTicketId(state)
  const isViewing = selectIsViewingConversation(state)
  const isEditorFocused = selectIsEditorFocused(state)
  const isReplying = selectIsReplying(state)
  const isForwarding = selectIsForwarding(state)
  const isAddingNote = selectIsAddingNote(state)
  // When the browser is loading in the background, dont emit any
  // being realtime events
  const isBrowserVisible = getVisibilityState() === 'visible'

  if (isBrowserVisible) {
    if (ticketId && isAddingNote && isEditorFocused) {
      isOnTicket = true
      emit('noteToTicket', {
        ticketId,
        phase: 'begin',
      })
    } else if (ticketId && (isReplying || isForwarding) && isEditorFocused) {
      isOnTicket = true
      emit('replyToTicket', {
        ticketId,
        phase: 'begin',
      })
    } else if (ticketId && isViewing) {
      isOnTicket = true
      emit('viewTicket', {
        ticketId,
        phase: 'begin',
      })
    } else if (isOnTicket) {
      isOnTicket = false
      emit('viewTicket', {
        ticketId,
        phase: 'end',
      })
    }
  }
}

let isWatchingWindow = false
export function startCollisionWindowWatching(getState) {
  if (isWatchingWindow) return
  isWatchingWindow = true
  WindowVisibility.addEventListener('blur', () => {
    const state = getState()
    const ticketId = selectCurrentTicketId(state)
    const currentUserId = selectCurrentUserId(state)
    const isTypingReply = selectAgentsForTypingReplyCollisionByConversationId(
      state,
      ticketId,
      false
    ).some(({ id }) => id === currentUserId)
    const isTypingNote = selectAgentsForTypingNoteCollisionByConversationId(
      state,
      ticketId,
      false
    ).some(({ id }) => id === currentUserId)
    if (isTypingReply) {
      emit('replyToTicket', {
        ticketId,
        phase: 'begin',
      })
    } else if (isTypingNote) {
      emit('noteToTicket', {
        ticketId,
        phase: 'begin',
      })
    }
    // Kevin R: 2023-07-04
    // I've removed the end phase for viewTicket over here due to it being a
    // delayed action. Realtime will take care of tracking the delays and
    // scheduling the timeout server side
  })
}
