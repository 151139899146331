import {
  OPERATOR_TYPE_EQ,
  OPERATOR_TYPE_LT,
  OPERATOR_TYPE_LTEQ,
  OPERATOR_TYPE_NOT_EQ,
} from 'util/operators'
import { FOLDER_CONDITION_TYPES } from '../constants'

export function buildFolderKindsTypes({ currentUserId }) {
  return [
    {
      type: 'unassigned',
      conditions: [
        {
          param: FOLDER_CONDITION_TYPES.ASSIGNED_AGENT,
          operators: [OPERATOR_TYPE_EQ],
          value: [null],
        },
        {
          param: FOLDER_CONDITION_TYPES.ASSIGNED_GROUP,
          operators: [OPERATOR_TYPE_EQ],
          value: [null],
        },
        {
          param: FOLDER_CONDITION_TYPES.STATUS,
          operators: [OPERATOR_TYPE_LTEQ, OPERATOR_TYPE_EQ],
          value: ['opened'],
        },
      ],
    },
    currentUserId && {
      type: 'mine',
      conditions: [
        {
          param: FOLDER_CONDITION_TYPES.ASSIGNED_AGENT,
          operators: [OPERATOR_TYPE_EQ],
          // 4517239960 is scatterswap for -1
          value: [currentUserId, '-1', '4517239960'],
        },
        {
          param: FOLDER_CONDITION_TYPES.STATUS,
          operators: [OPERATOR_TYPE_LTEQ, OPERATOR_TYPE_EQ],
          value: ['opened'],
        },
      ],
    },
    {
      type: 'closed',
      conditions: [
        {
          param: FOLDER_CONDITION_TYPES.STATUS,
          operators: [OPERATOR_TYPE_EQ],
          value: 'closed',
        },
        {
          param: 'snooze_until',
          operators: [OPERATOR_TYPE_LT],
          value: ['indefinitely'],
        },
      ],
    },
    {
      type: 'closed',
      conditions: [
        {
          param: FOLDER_CONDITION_TYPES.STATUS,
          operators: [OPERATOR_TYPE_EQ],
          value: ['closed'],
        },
      ],
    },
    currentUserId && {
      type: 'drafts',
      conditions: [
        {
          param: FOLDER_CONDITION_TYPES.DRAFTS,
          operators: [OPERATOR_TYPE_EQ],
          value: [currentUserId, '-1', '4517239960'],
        },
      ],
    },
    {
      type: 'assigned',
      conditions: [
        {
          param: FOLDER_CONDITION_TYPES.ASSIGNED_AGENT,
          operators: [OPERATOR_TYPE_NOT_EQ],
          value: [null],
        },
        {
          param: FOLDER_CONDITION_TYPES.STATUS,
          operators: [OPERATOR_TYPE_LTEQ, OPERATOR_TYPE_EQ],
          value: ['opened'],
        },
      ],
    },
    {
      type: 'spam',
      conditions: [
        {
          param: FOLDER_CONDITION_TYPES.STATUS,
          operators: [OPERATOR_TYPE_EQ],
          value: ['spam'],
        },
      ],
    },
    {
      type: 'trash',
      conditions: [
        {
          param: FOLDER_CONDITION_TYPES.DELETED,
          operators: [OPERATOR_TYPE_EQ],
          value: ['true'],
        },
      ],
    },
    currentUserId && {
      type: 'snoozed',
      conditions: [
        {
          param: FOLDER_CONDITION_TYPES.SNOOZE_STATE,
          operators: [OPERATOR_TYPE_EQ],
          value: [currentUserId, '-1', '4517239960'],
        },
      ],
    },
    {
      type: 'open',
      conditions: [
        {
          param: FOLDER_CONDITION_TYPES.STATUS,
          operators: [OPERATOR_TYPE_EQ, OPERATOR_TYPE_LTEQ],
          value: ['opened'],
        },
      ],
    },
  ].filter(f => !!f)
}
