import { createSelector } from 'reselect'
import { createBasicSelector } from 'util/redux'
import {
  selectMergingSearchQueryId,
  selectMergingSearchTerm,
} from 'ducks/merging/selectors/base'

import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import { emptyArr } from 'util/arrays'
import { isQueryIdValid, queryIdToQuery } from 'ducks/searches/utils/query'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { selectCurrentConversationsById } from 'ducks/tickets/selectors'

export const selectCurrentMergeSearch = createBasicSelector(
  selectMergingSearchQueryId,
  state => state,
  (queryId, state) => selectSearchByQueryId(state, queryId)
)

export const selectHasPendingSearch = createSelector(
  selectMergingSearchQueryId,
  selectMergingSearchTerm,
  (queryId, search) => !!search && queryIdToQuery(queryId)?.search !== search
)

export const selectCurrentMergeSearchResultTicketIds = createSelector(
  selectCurrentMergeSearch,
  search => search.entityIds || emptyArr
)

const selectMergeSearchResultTickets = createSelector(
  selectCurrentConversationsById,
  selectCurrentMergeSearchResultTicketIds,
  (ticketsById, ticketIds) => {
    return ticketIds.map(ticketId => ticketsById[ticketId])
  }
)

// dont allow current ticket to be merged into itself.
export const selectCurrentMergeSearchResultTickets = createSelector(
  selectMergeSearchResultTickets,
  selectCurrentTicketId,
  (tickets, ticketId) => [].concat(tickets).filter(t => t && t.id !== ticketId)
)

export const selectHasCurrentMergeSearchErrored = createBasicSelector(
  selectCurrentMergeSearch,
  search => {
    if (!search) return false
    return !!search.errored
  }
)

export const selectIsCurrentMergeSearchQueryValid = createSelector(
  selectMergingSearchQueryId,
  queryId => isQueryIdValid(queryId)
)
