import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectCurrentFoldersById } from 'ducks/folders/selectors/folders'
import { createSelector } from 'reselect'
import { any } from 'util/arrays'
import { isMe } from 'util/folders'

const isDraftConditionFor = id => c => {
  return c.param === 'drafts' && (isMe(c.value) || c.value === id)
}

export const selectFolderIdsMatchingDraftForCurrentUser = createSelector(
  selectCurrentFoldersById,
  selectCurrentUser,
  (folders, user) => {
    return Object.keys(folders).filter(id => {
      const folder = folders[id]
      if (!folder || !folder.conditions) return false
      return any(isDraftConditionFor(user.id), folder.conditions)
    })
  }
)
