import {
  doAppGraphqlRequest,
  doGraphqlRequest,
} from 'ducks/requests/operations'
import {
  constructGraphQLOrderByObject,
  queryIdToQuery,
} from 'ducks/searches/utils/query'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { customFieldValueNodeQuery } from 'ducks/crm/customFields/operations/fetching'
import { selectRequestsByKey } from 'ducks/crm/contacts/selectors/base'
import { contactsNormalizationSchema } from 'ducks/crm/contacts/schema'
import { FETCH_CONTACTS } from 'ducks/crm/contacts/types'
import { isDefined } from 'util/nullOrUndefinedChecks'
import { PAGINATION_MODE } from 'ducks/searches/constants'

export const contactCustomFieldValuesSubQueryClientPaging = `
customFieldValues(filter: { customFieldIds: $contactCustomFieldIds }) {
  ${customFieldValueNodeQuery}
}
`

const fetchContactsQuery = `
  query Contacts(
    $filter: ContactFilter,
    $cursor: String,
    $size: Int,
    $orderBy: ContactOrder
  ) {
    contacts(filter: $filter, after: $cursor, first: $size, orderBy: $orderBy) {
      totalCount
      totalPageCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        companies {
          nodes {
            id
            createdAt
            name
            logoUrl
          }
        }
        contactType
        conversationCount
        createdAt
        email
        secondaryEmails
        lastSeenAt
        name
        avatarUrl
        createdBy {
          id
        }
      }
    }
  }
`

export const doFetchContacts = ({ queryId, skipLoaded }) => (
  dispatch,
  getState
) => {
  const { cursor, pageSize = 20, search } =
    queryIdToQuery(queryId, {
      decodeUriKeys: ['search', 'cursor'],
      parseNull: true,
    }) || {}
  const state = getState()
  const orderBy = constructGraphQLOrderByObject(queryId)

  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale
  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({})
  }

  const variables = {
    filter: {
      search,
    },
    size: pageSize,
    orderBy,
  }

  if (isDefined(cursor)) {
    variables.cursor = cursor
  }

  return dispatch(
    doGraphqlRequest(FETCH_CONTACTS, fetchContactsQuery, variables, {
      normalizationSchema: contactsNormalizationSchema,
      app: true,
      searches: {
        queryId,
        cursor,
      },
      meta: {
        paginationMode: PAGINATION_MODE.ES_CURSOR,
      },
    })
  )
}

export function doBasicSearchContacts(search) {
  return (dispatch, getState) => {
    const state = getState()
    const requestKey = search
    const isLoading = selectRequestsByKey(state)[requestKey]?.isLoading
    const variables = {
      first: 20,
      filter: { search },
    }

    if (isLoading) return Promise.resolve(null)

    return dispatch(
      doAppGraphqlRequest(FETCH_CONTACTS, fetchContactsQuery, variables, {
        normalizationSchema: contactsNormalizationSchema,
        meta: {
          requestKey,
          mergeEntities: true,
        },
      })
    )
  }
}

export function makeEntityAvailable(result, contact) {
  // eslint-disable-next-line no-param-reassign
  result[contact.id] = {
    ...contact,
    isPartiallyLoaded: true,
    customFieldValues: contact.customFieldValues || {},
  }
  return result
}
