import { createBasicSelector } from 'util/redux'
import { emptyObj } from 'util/objects'
import selectIntegrationCredentialsById from './selectIntegrationCredentialsById'

const selectIntegrationSettingsForId = createBasicSelector(
  selectIntegrationCredentialsById,
  (_, id) => id,
  (integrationCredentialsById, id) => {
    if (!integrationCredentialsById || !id) return emptyObj

    return integrationCredentialsById[id]?.settings?.settings || emptyObj
  }
)

export default selectIntegrationSettingsForId
