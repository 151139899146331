import { conversationAttachmentFragment } from 'ducks/tickets/fragments'

export const attachmentsQuery = `
  query Attachments($conversationId: ID, $conversationType: String) {
    attachments(filter: {
      conversationId: $conversationId,
      conversationType: $conversationType
    }) {
      edges {
        node {
          ...conversationAttachmentFragment
        }        
      }
    }
  }
  ${conversationAttachmentFragment()}
`
