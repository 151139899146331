export const GMAIL_IMPORT = 'mailboxes/GMAIL_IMPORT'
export const GMAIL_IMPORT_STARTED = 'mailboxes/GMAIL_IMPORT_STARTED'
export const GMAIL_IMPORT_SUCCESS = 'mailboxes/GMAIL_IMPORT_SUCCESS'
export const GMAIL_IMPORT_FAILED = 'mailboxes/GMAIL_IMPORT_FAILED'

export const SMTP_CHECK = 'mailboxes/SMTP_CHECK'

export const SEND_VERIFICATION = 'mailboxes/SEND_VERIFICATION'
export const SEND_VERIFICATION_STARTED = 'mailboxes/SEND_VERIFICATION_STARTED'
export const SEND_VERIFICATION_SUCCESS = 'mailboxes/SEND_VERIFICATION_SUCCESS'
export const SEND_VERIFICATION_FAILED = 'mailboxes/SEND_VERIFICATION_FAILED'

export const SEND_SETUP_INSTRUCTIONS = 'mailboxes/SEND_SETUP_INSTRUCTIONS'
export const SEND_SETUP_INSTRUCTIONS_STARTED =
  'mailboxes/SEND_SETUP_INSTRUCTIONS_STARTED'
export const SEND_SETUP_INSTRUCTIONS_SUCCESS =
  'mailboxes/SEND_SETUP_INSTRUCTIONS_SUCCESS'
export const SEND_SETUP_INSTRUCTIONS_FAILED =
  'mailboxes/SEND_SETUP_INSTRUCTIONS_FAILED'

export const FETCH_MAILBOX = 'mailboxes/FETCH_MAILBOX'
export const FETCH_MAILBOX_STARTED = 'mailboxes/FETCH_MAILBOX_STARTED'
export const FETCH_MAILBOX_SUCCESS = 'mailboxes/FETCH_MAILBOX_SUCCESS'
export const FETCH_MAILBOX_FAILED = 'mailboxes/FETCH_MAILBOX_FAILED'

export const UPDATE_MAILBOX = 'mailboxes/UPDATE_MAILBOX'
export const UPDATE_MAILBOX_STARTED = 'mailboxes/UPDATE_MAILBOX_STARTED'
export const UPDATE_MAILBOX_SUCCESS = 'mailboxes/UPDATE_MAILBOX_SUCCESS'
export const UPDATE_MAILBOX_FAILED = 'mailboxes/UPDATE_MAILBOX_FAILED'

export const TEST_SMTP = 'mailboxes/TEST_SMTP'
export const TEST_SMTP_STARTED = 'mailboxes/TEST_SMTP_STARTED'
export const TEST_SMTP_SUCCESS = 'mailboxes/TEST_SMTP_SUCCESS'
export const TEST_SMTP_FAILED = 'mailboxes/TEST_SMTP_FAILED'

export const TEST_IMAP = 'mailboxes/TEST_IMAP'
export const TEST_IMAP_STARTED = 'mailboxes/TEST_IMAP_STARTED'
export const TEST_IMAP_SUCCESS = 'mailboxes/TEST_IMAP_SUCCESS'
export const TEST_IMAP_FAILED = 'mailboxes/TEST_IMAP_FAILED'

export const CHECK_DNS = 'mailboxes/CHECK_DNS'
export const CHECK_DNS_STARTED = 'mailboxes/CHECK_DNS_STARTED'
export const CHECK_DNS_SUCCESS = 'mailboxes/CHECK_DNS_SUCCESS'
export const CHECK_DNS_FAILED = 'mailboxes/CHECK_DNS_FAILED'

export const DESTROY_MAILBOX = 'mailboxes/DESTROY_MAILBOX'
export const DESTROY_MAILBOX_STARTED = 'mailboxes/DESTROY_MAILBOX_STARTED'
export const DESTROY_MAILBOX_SUCCESS = 'mailboxes/DESTROY_MAILBOX_SUCCESS'
export const DESTROY_MAILBOX_FAILED = 'mailboxes/DESTROY_MAILBOX_FAILED'

export const CREATE_MAILBOX = 'mailboxes/CREATE_MAILBOX'
export const CREATE_MAILBOX_REQUEST = 'mailboxes/CREATE_MAILBOX_REQUEST'
export const CREATE_MAILBOX_SUCCESS = 'mailboxes/CREATE_MAILBOX_SUCCESS'
export const CREATE_MAILBOX_FAILURE = 'mailboxes/CREATE_MAILBOX_FAILURE'

export const REMOVE_MAILBOX_LOCALLY = 'mailboxes/REMOVE_MAILBOX_LOCALLY'
