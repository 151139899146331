import { createSelector } from 'reselect'
import { selectConversationLinkedResources } from './selectConversationLinkedResources'

export const selectConversationLinkedResourceByExternalId = createSelector(
  selectConversationLinkedResources,
  linkedResources => {
    return linkedResources.reduce((result, linkedResource) => {
      const id = linkedResource.externalId
      // eslint-disable-next-line no-param-reassign
      result[id] = linkedResource

      return result
    }, {})
  }
)
