import { createBasicSelector } from 'util/redux'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectConversationLinkedResourcesByConversationId } from './selectConversationLinkedResourcesByConversationId'

export const selectCurrentTicketLinkedResources = createBasicSelector(
  selectCurrentTicketId,
  selectConversationLinkedResourcesByConversationId,
  (ticketId, linkedResourcesByConversationId) => {
    return linkedResourcesByConversationId[ticketId]
  }
)
