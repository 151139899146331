import { companies } from 'ducks/entities/schema'

export const companiesNormalizationSchema = {
  companies: {
    nodes: [companies],
  },
}

export const companyNormalizationSchema = {
  node: companies,
}

export const createCompanySchema = {
  companyCreate: {
    company: companies,
  },
}
