import { createBasicSelector } from 'util/redux'
import { emptyObj } from 'util/objects'

import { selectBase } from './selectBase'

const selectSuggestions = createBasicSelector(
  selectBase,
  ai => ai.suggestions || emptyObj
)

const selectSuggestionsByTicketId = createBasicSelector(
  selectSuggestions,
  suggestions => suggestions.byTicketId || emptyObj
)

export const selectSuggestionsForTicketId = createBasicSelector(
  selectSuggestionsByTicketId,
  (state, ticketId) => ticketId,
  (byTicketId, ticketId) => byTicketId[ticketId] || emptyObj
)

export const selectSuggestionsIsLoading = createBasicSelector(
  selectSuggestionsForTicketId,
  byTicketId => byTicketId.isLoading
)

export const selectSuggestionsIsLoaded = createBasicSelector(
  selectSuggestionsForTicketId,
  byTicketId => byTicketId.isLoaded
)

export const selectSuggestionForType = createBasicSelector(
  selectSuggestionsForTicketId,
  (state, ticketId, { type }) => type,
  (byTicketId, type) => byTicketId[type] || emptyObj
)
