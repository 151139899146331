import { folder } from 'ducks/entities/schema'

export const folderGraphQlV2ResponseSchema = {
  folders: {
    nodes: [folder],
  },
}

export const folderGraphQlCreateResponseSchema = {
  folderCreate: { folder },
}

export const folderGraphQlUpdateResponseSchema = {
  folderUpdate: { folder },
}
