import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_AI_JOB_INSTANT_REPLY_RESULT_STARTED,
  FETCH_AI_JOB_INSTANT_REPLY_RESULT_END,
  FETCH_AI_JOB_INSTANT_REPLY_RESULT_CANCEL,
  FETCH_AI_JOB_INSTANT_REPLY_RESULT_HIDE,
  FETCH_AI_JOB_INSTANT_REPLY_RESULT_CLEAR,
  FETCH_AI_JOB_INSTANT_REPLY_RESULT_FAILED,
} from 'ducks/ai/types'
import { SEARCH_PAGE, TICKET_PAGE, TICKETS_PAGE } from 'constants/pages'

const initialState = {
  response: null,
  loading: true,
  loaded: false,
  error: false,
  lastRequest: null,
  cancel: true,
  showPrompt: true,
}

export default createActionTypeReducer(
  {
    [FETCH_AI_JOB_INSTANT_REPLY_RESULT_STARTED]: draftState => {
      Object.assign(draftState, initialState)

      draftState.loading = true

      return draftState
    },
    [FETCH_AI_JOB_INSTANT_REPLY_RESULT_END]: (draftState, { payload }) => {
      const response = payload.result.result

      draftState.response = response
      draftState.loaded = true
      draftState.loading = false
      draftState.lastRequest = payload.lastRequest

      return draftState
    },
    [FETCH_AI_JOB_INSTANT_REPLY_RESULT_CANCEL]: draftState => {
      Object.assign(draftState, initialState)

      draftState.response = { title: '', body: '' }
      draftState.loading = false
      draftState.cancel = true
      draftState.loaded = true

      return draftState
    },
    [FETCH_AI_JOB_INSTANT_REPLY_RESULT_HIDE]: draftState => {
      draftState.showPrompt = false
      draftState.loading = false
      draftState.loaded = true

      return draftState
    },
    [FETCH_AI_JOB_INSTANT_REPLY_RESULT_CLEAR]: draftState => {
      Object.assign(draftState, initialState)

      return draftState
    },
    [FETCH_AI_JOB_INSTANT_REPLY_RESULT_FAILED]: draftState => {
      draftState.response = { title: '', body: '' }
      draftState.error = true
      draftState.loading = false
      draftState.loaded = true

      return draftState
    },
    [SEARCH_PAGE]: draftState => {
      Object.assign(draftState, initialState)

      return draftState
    },
    [TICKET_PAGE]: draftState => {
      Object.assign(draftState, initialState)

      return draftState
    },
    [TICKETS_PAGE]: draftState => {
      Object.assign(draftState, initialState)

      return draftState
    },
  },
  initialState
)
