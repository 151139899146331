import { createSelector } from 'reselect'
import { selectLocationState } from 'redux-first-router'
import { selectCurrentTicketNoteDraft } from 'ducks/drafts2/selectors'
import { selectAgentsSortedByUsername } from 'selectors/agents/base'
import { selectCurrentMailboxId } from 'ducks/mailboxes/selectors/selectCurrentMailboxId'
import { selectCurrentConversation } from 'ducks/tickets/selectors/selectCurrentConversation'
import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'
import { isViewer } from 'util/agents'
import createCachedSelector from 're-reselect'

const emptyAccessList = { widget: {}, mailbox: {} }

const selectAccessLists = createBasicSelector(
  state => state.app.accessLists || emptyAccessList
)

export const selectMailboxAccessList = createBasicSelector(
  selectAccessLists,
  accessLists => accessLists.mailbox || emptyObj
)

export const selectWidgetAccessLists = createBasicSelector(
  selectAccessLists,
  accessLists => accessLists.widget || emptyObj
)

export const selectCurrentChannelAccessList = createBasicSelector(
  selectAccessLists,
  selectLocationState,
  selectCurrentConversation,
  selectCurrentMailboxId,
  (accessLists, location, ticket, listMailboxId) => {
    if ((location?.query?.channelId || '').startsWith('wid_')) {
      return accessLists.widget[location.query.channelId]
    } else if (ticket?.mailboxId) {
      return accessLists.mailbox[ticket.mailboxId]
    }
    return listMailboxId
  }
)

const selectNewNoteDraftMailboxAccessList = createBasicSelector(
  selectAccessLists,
  selectCurrentTicketNoteDraft,
  (accessLists, draft) => {
    if (!draft?.mailboxId) return null
    return accessLists.mailbox[draft.mailboxId]
  }
)

export const selectCurrentMailboxAgents = createCachedSelector(
  selectAgentsSortedByUsername,
  selectCurrentChannelAccessList,
  (_state, includeViewers) => !!includeViewers,
  (agents, accessList, includeViewers) => {
    if (accessList && accessList.agentIds && accessList.agentIds.length > 0) {
      return agents.filter(
        agent =>
          (includeViewers || !isViewer(agent)) &&
          accessList.agentIds.indexOf(agent.id) > -1
      )
    }
    return agents.filter(agent => includeViewers || !isViewer(agent))
  }
)((_state, includeViewers) => `${!!includeViewers}`)

export const selectNewNoteDraftMailboxAgents = createSelector(
  selectAgentsSortedByUsername,
  selectNewNoteDraftMailboxAccessList,
  (agents, accessList) => {
    if (accessList && accessList.agentIds && accessList.agentIds.length > 0) {
      return agents.filter(agent => accessList.agentIds.indexOf(agent.id) > -1)
    }
    return agents
  }
)
