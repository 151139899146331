import { getValueByPath } from 'util/objects'

const name = customer => {
  if (!customer) return ''
  return customer.label || customer.first_name || customer.name || ''
}

export const firstInitial = customer => name(customer).charAt(0)

export const mergeCustomer = (previous, current) => {
  const target = previous || {}
  const source = current || {}
  const merged = { ...target, ...source }
  if (target.twitter || source.twitter) {
    merged.twitter = { ...target.twitter, ...source.twitter }
  }
  return merged
}

export const twitterHandle = user => {
  const username = getValueByPath('twitter.username', user)
  if (!username) return null
  return `@${username}`
}

export const twitterHandleFromContact = contact => {
  const username = getValueByPath(
    'customFieldValues.contact_twitter.value.text',
    contact
  )
  if (!username) return null
  return `@${username}`
}
