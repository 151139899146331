import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { selectCurrentUserId } from 'ducks/currentUser/selectors/selectCurrentUserId'
import { selectAgentCount } from 'selectors/agents/base'
import {
  selectPendingEntityById,
  selectCurrentEntityById,
  selectCurrentEntityIds,
  selectCurrentEntitiesSortedBy,
  selectCurrentEntitiesById,
} from 'ducks/entities/selectors'
import { emptyArr } from 'util/arrays'
import { selectInaccessibleMailboxesIds } from 'ducks/mailboxes/selectors/selectInaccessibleMailboxesIds'
import {
  selectCurrentPath,
  isLiveChatPath,
  isSocialChatPath,
  isInboxPath,
  selectQueryParamsQueryId,
} from 'selectors/location'
import { SETTINGS_CHANNEL_TABLE_ID } from 'ducks/tables/ids'
import {
  queryIdToQuery,
  queryStringToQueryId,
} from 'ducks/searches/utils/query'
import { changeEntity } from 'ducks/entities/actionUtils'
import { searchAddCursorEntityIds } from 'ducks/searches/utils/action'
import { emptyObj } from 'util/objects'
import { getRawId } from 'util/globalId'
import { getChannelTypeByTypeValue, getRestrictionType } from './utils'
import { isEmailChannelType } from './channelTypes'

export const selectPendingChannelById = (state, id) =>
  selectPendingEntityById(state, 'channel', getRawId(id)) || null

export const selectCurrentChannelById = (state, id) =>
  selectCurrentEntityById(state, 'channel', getRawId(id)) || null

export const selectCurrentChannelsById = state =>
  selectCurrentEntitiesById(state, 'channel') || null

export const selectCurrentChannels = state =>
  selectCurrentEntitiesSortedBy(state, 'channel', 'position', 'asc') || emptyArr

export const selectAccessibleCurrentEmailChannels = createSelector(
  selectCurrentChannels,
  selectInaccessibleMailboxesIds,
  (currentChannels, inaccessibleMailboxesIds) => {
    return currentChannels.filter(
      c =>
        isEmailChannelType(getChannelTypeByTypeValue(c.type)) &&
        !inaccessibleMailboxesIds.includes(c.id)
    )
  }
)

export const selectCurrentChannelRestriction = createCachedSelector(
  selectCurrentChannelById,
  selectCurrentUserId,
  (channel, currentUserId) => getRestrictionType(channel, currentUserId)
)((_state, channelId) => channelId)

export const selectCurrentChannelCount = state => {
  return (selectCurrentEntityIds(state, 'channel') || emptyArr).length
}

export const selectChannelTypeFromUrl = createSelector(
  selectCurrentPath,
  path => {
    if (isInboxPath(path)) return 'mailbox'
    if (isLiveChatPath(path)) return 'widget'
    if (isSocialChatPath(path)) return 'social'

    return null
  }
)

export const selectChannelCreationStepCount = createCachedSelector(
  selectAgentCount,
  (_state, channelConfig) => channelConfig,
  (activeAgentCount, channelConfig) => {
    const { totalStepCount } = channelConfig || {}
    if (!totalStepCount) return 0
    return activeAgentCount > 1 ? totalStepCount : totalStepCount - 1
  }
)((_state, channelConfig) => channelConfig?.name || 'unknown')

export const selectChannelEntitiesToAddAfterMailboxCreation = createCachedSelector(
  state =>
    // needed to update the inboxes data table (if mounted) with newly created mailbox
    selectQueryParamsQueryId(state, {
      targetId: SETTINGS_CHANNEL_TABLE_ID,
    }),
  (_state, newMailbox) => newMailbox,
  (rawQueryId, newMailbox) => {
    if (!newMailbox) return emptyObj
    const queryObject = queryIdToQuery(rawQueryId)
    // The mailbox maybe is created on chats page, need to ensure we add the mailbox id to the inboxes data table
    const queryId =
      queryObject && queryObject?.type !== 'inboxes'
        ? queryStringToQueryId({
            ...queryObject,
            type: 'inboxes',
          })
        : rawQueryId

    return {
      ...changeEntity('channel', newMailbox.id, newMailbox),
      // add newly created mailbox entity to search cursor (aka inbox datatable) without invalidating
      // the entities and fetching all mailboxes from api again. because it's already in the entities.current store
      ...(queryId && searchAddCursorEntityIds(queryId, [newMailbox.id])),
    }
  }
)((_state, newMailbox) => newMailbox.id)
