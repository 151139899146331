import { createSelector } from 'reselect'
import { selectIsInboxBootstrapped } from 'ducks/bootstrap/selectors/selectIsInboxBootstrapped'
import { selectIsInInbox } from 'selectors/location'
import { selectKnownMailboxes } from './selectKnownMailboxes'

export const selectShowDemoMailboxBar = createSelector(
  selectIsInboxBootstrapped,
  selectKnownMailboxes,
  selectIsInInbox,
  // eslint-disable-next-line no-unused-vars
  (bootstrapped, mailboxes, isInInbox) => {
    // TODO: temp: Nick wants this hidden for now
    return false
    /* if (!bootstrapped || !mailboxes || !isInInbox) {
      return false
    }
 
    // If the agent has no mailbox at all we don't want to show the demo bar
    if (isEmpty(mailboxes)) return false
 
    const hasRealMailbox = mailboxes.some(mailbox => {
      return mailbox.state !== 'demo'
    })
    return !hasRealMailbox */
  }
)
