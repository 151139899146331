import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCannedReplyPreview } from 'ducks/cannedReplies/operations'
import { selectHasLoadedReplyCategories } from 'ducks/cannedReplies/selectors'
import useIsMounted from 'util/hooks/useIsMounted'
import DropdownMenu from './DropdownMenu'
import Preview from './Preview'
import { styles } from './styles'

const DropdownMenuWithPreview = ({
  onSelect,
  items,
  recentlyUsedTemplates,
  visible,
  onVisibleChange,
}) => {
  const dispatch = useDispatch()
  const isMounted = useIsMounted()
  const hasLoadedReplyCategories = useSelector(selectHasLoadedReplyCategories)
  const [search, setSearch] = useState('')
  const [openPreview, setOpenPreview] = useState(false)
  const [arrowPosition, setArrowPosition] = useState()
  const [cannedReplyPreview, setCannedReplyPreview] = useState()
  const [currentPreviewTemplateId, setCurrentPreviewTemplateId] = useState()
  const arrowPositionRef = useRef()

  const handleGetPreview = useCallback(
    async item => {
      const preview = await dispatch(getCannedReplyPreview(item))
      if (!isMounted()) return
      // Set the arrow postion with the new content together
      setArrowPosition(arrowPositionRef.current)
      setCannedReplyPreview(preview)
    },
    [dispatch, isMounted]
  )

  useEffect(
    () => {
      if (!visible) {
        setSearch('')
        setOpenPreview(false)
      }
    },
    [visible]
  )

  useEffect(
    () => {
      if (!openPreview && cannedReplyPreview?.id) {
        setCannedReplyPreview(null)
        setCurrentPreviewTemplateId(null)
      }
    },
    [openPreview, cannedReplyPreview]
  )

  return (
    <div className="grui flex h-100" css={styles.overlay}>
      <DropdownMenu
        onSelect={onSelect}
        items={items}
        recentlyUsedTemplates={recentlyUsedTemplates}
        search={search}
        setSearch={setSearch}
        hasLoadedReplyCategories={hasLoadedReplyCategories}
        visible={visible}
        onVisibleChange={onVisibleChange}
        setOpenPreview={setOpenPreview}
        onGetPreview={handleGetPreview}
        currentPreviewTemplateId={currentPreviewTemplateId}
        setCurrentPreviewTemplateId={setCurrentPreviewTemplateId}
        setArrowPosition={setArrowPosition}
        arrowPositionRef={arrowPositionRef}
      />
      <Preview
        cannedReplyPreview={cannedReplyPreview}
        open={openPreview}
        onVisibleChange={onVisibleChange}
        arrowPosition={arrowPosition}
      />
    </div>
  )
}

export default React.memo(DropdownMenuWithPreview)
