import { createSelector } from 'reselect'
import { createBasicSelector } from 'util/redux'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectCurrentTicketReplyDraft } from 'ducks/drafts2/selectors'
import {
  selectCurrentEntities,
  selectPendingEntityById,
  selectCurrentEntityById,
  selectCurrentEntitiesById,
  selectPendingEntitiesById,
} from 'ducks/entities/selectors'
import { selectAccount } from 'selectors/app/base'
import { selectCurrentTicketDenormalizedEventGroups } from 'ducks/tickets/selectors/selectCurrentTicketEventGroups'
import { selectCurrentTicketMailbox } from 'selectors/tickets/mailbox'
import { emptyArr, sortByName } from 'util/arrays'
import {
  withInterpolatedSnippet,
  interpolateCannedReply,
} from 'util/cannedReply'
import { curry } from 'util/functions'
import { emptyObj } from 'util/objects'
import { selectCurrentDenormalizedConversation } from 'ducks/tickets/selectors/selectCurrentDenormalizedConversation'
import { selectTemplates } from './selectTemplates'
import { selectBase } from './selectBase'

const selectCategories = createBasicSelector(
  selectBase,
  base => base.categories || emptyObj
)

const selectTemplate = createBasicSelector(
  selectBase,
  base => base.template || emptyObj
)

const selectSearch = createBasicSelector(selectBase, base => base.search)

const selectVariables = createBasicSelector(
  selectBase,
  base => base.variables || emptyObj
)

export const selectCannedRepliesSearchTerm = createBasicSelector(
  selectSearch,
  search => search.searchTerm || ''
)

export const selectLastRequestedSearchTerm = createBasicSelector(
  selectSearch,
  search => search.lastRequestedSearchTerm || ''
)

export const selectIsSearchingCannedReplies = createSelector(
  selectCannedRepliesSearchTerm,
  term => term && term !== ''
)

export const selectHasSearchedCannedReplies = createSelector(
  selectLastRequestedSearchTerm,
  term => term && term !== ''
)

export const selectCannedReplyCategories = createSelector(
  selectCategories,
  selectTemplates,
  selectHasSearchedCannedReplies,
  (categories, templates, hasSearched) => {
    if (!categories.data || categories.data.length === 0) return emptyArr
    if (hasSearched && templates.data && templates.data.length > 0) {
      const sortedCategories = []
      templates.data.forEach(template => {
        const findCategory = c => c.id === template.categoryId
        if (
          !sortedCategories.some(findCategory) &&
          categories.data.some(findCategory)
        ) {
          sortedCategories.push(categories.data.find(findCategory))
        }
      })
      return sortedCategories
    }
    return sortByName(categories.data)
  }
)

export const selectCannedReplyTemplates = createSelector(
  selectTemplates,
  selectHasSearchedCannedReplies,
  (templates, hasSearched) => {
    if (!templates.data) return emptyArr
    if (hasSearched) return templates.data
    return sortByName(templates.data)
  }
)

export const selectDecoratedCannedReplyTemplates = createSelector(
  selectCannedReplyTemplates,
  selectCurrentUser,
  selectAccount,
  selectCurrentDenormalizedConversation,
  selectCurrentTicketDenormalizedEventGroups,
  selectCurrentTicketMailbox,
  selectCurrentTicketReplyDraft,
  (templates, user, account, ticket, eventGroups, mailbox, draft) => {
    return templates.map(t =>
      withInterpolatedSnippet(
        t,
        user,
        account,
        ticket,
        eventGroups,
        mailbox,
        draft
      )
    )
  }
)

export const selectCannedReplyTemplatesWithCategory = createSelector(
  selectDecoratedCannedReplyTemplates,
  selectCannedReplyCategories,
  (templates, categories) => {
    return templates.map(t => ({
      ...t,
      category: categories.find(c => t.categoryId === c.id),
    }))
  }
)

export const selectIsLoadingCannedReplyCategories = createBasicSelector(
  selectCategories,
  categories => categories.isLoading
)

export const selectHasLoadedReplyCategories = createBasicSelector(
  selectCategories,
  categories => categories.isLoaded
)

export const selectHasErrorCannedReplyCategories = createBasicSelector(
  selectCategories,
  categories => categories.hasError
)

export const selectHasLoadedTemplates = createBasicSelector(
  selectTemplates,
  templates => templates.isLoaded
)

export const selectMailboxIdForTemplate = createBasicSelector(
  selectTemplates,
  templates => templates.mailboxId
)

export const selectCannedReplyCategoriesWithTemplates = createSelector(
  selectCannedReplyCategories,
  selectDecoratedCannedReplyTemplates,
  (categories, templates) => {
    return categories.map(c => ({
      ...c,
      templates: templates.filter(t => t.categoryId === c.id),
    }))
  }
)

export const selectExpandedCannedReplyCategoryIds = createBasicSelector(
  selectCategories,
  categories => categories.expandedCategoryIds || emptyArr
)

export const selectLoadingCannedReplyCategoryIds = createBasicSelector(
  selectTemplates,
  templates => templates.loadingCategoryIds || emptyArr
)

export const selectLoadedCannedReplyCategoryIds = createBasicSelector(
  selectTemplates,
  templates => templates.loadedCategoryIds || emptyArr
)

export const selectFlatCannedReplyCategoriesWithTemplates = createSelector(
  selectCannedReplyCategoriesWithTemplates,
  selectExpandedCannedReplyCategoryIds,
  selectLoadedCannedReplyCategoryIds,
  selectLoadingCannedReplyCategoryIds,
  (categories, expandedCategoryIds, loadedCategoryIds, loadingCategoryIds) => {
    const items = []
    categories.forEach(c => {
      items.push({
        ...c,
        type: 'category',
        loaded: loadedCategoryIds.includes(c.id),
        loading: loadingCategoryIds.includes(c.id),
      })
      if (c.templates.length > 0 && expandedCategoryIds.includes(c.id)) {
        c.templates.forEach(t => items.push({ ...t, type: 'template' }))
      }
      if (c.templates.length === 0 && loadedCategoryIds.includes(c.id)) {
        c.templates.push({
          id: -c.id,
          name: `No ${app.t('canned_replies')} in this category...`,
        })
      }
    })
    return items
  }
)

export const selectIsLoadingCannedReplyTemplates = createBasicSelector(
  selectTemplates,
  templates => templates.isLoading
)

export const selectIsLoadingCategoriesOrTemplates = createSelector(
  selectIsLoadingCannedReplyTemplates,
  selectIsLoadingCannedReplyCategories,
  (isTemplateLoading, isCategoryLoading) =>
    isCategoryLoading || isTemplateLoading
)

export const selectCurrentCategoryBatchId = createBasicSelector(
  selectTemplates,
  templates => templates.categoryBatchId
)

export const selectInterpolateCannedReply = createSelector(
  selectCurrentUser,
  selectAccount,
  selectCurrentDenormalizedConversation,
  selectCurrentTicketDenormalizedEventGroups,
  selectCurrentTicketMailbox,
  selectCurrentTicketReplyDraft,
  (user, account, ticket, eventGroups, mailbox, draft) => {
    return curry(interpolateCannedReply)(
      user,
      account,
      ticket,
      eventGroups,
      mailbox,
      draft
    )
  }
)

export const selectIsLoadingCannedReplyVariables = createBasicSelector(
  selectVariables,
  variables => variables.isLoading
)

export const selectHasLoadedCannedReplyVariables = createBasicSelector(
  selectVariables,
  variables => variables.isLoaded
)

export const selectHasErrorCannedReplyVariables = createBasicSelector(
  selectVariables,
  variables => variables.hasError
)

export const selectIsLoadingCannedReplyTemplate = createBasicSelector(
  selectTemplate,
  template => template.isLoading
)

export const selectHasLoadedCannedReplyTemplate = createBasicSelector(
  selectTemplate,
  template => template.isLoaded
)

export const selectHasErrorCannedReplyTemplate = createBasicSelector(
  selectTemplate,
  template => template.hasError
)

export const selectCurrentCannedReplyCategories = state =>
  selectCurrentEntities(state, 'cannedReplyCategory') || []

export const selectCurrentCannedReplyVariables = state =>
  selectCurrentEntities(state, 'cannedReplyVariables') || []

export const selectPendingCannedReplyById = (state, id) =>
  selectPendingEntityById(state, 'cannedReply', id) || null

export const selectCurrentCannedReplyCategoryById = (state, id) =>
  selectCurrentEntityById(state, 'cannedReplyCategory', id) || null

export const selectCurrentCannedRepliesById = state =>
  selectCurrentEntitiesById(state, 'cannedReply') || null

export const selectCurrentCannedReplyById = (state, id) =>
  selectCurrentCannedRepliesById(state)?.[id] || null

export const selectCurrentCannedReplyCategoriesById = state =>
  selectCurrentEntitiesById(state, 'cannedReplyCategory') || null

export const selectPendingCannedReplyCategoryById = (state, id) =>
  selectPendingEntityById(state, 'cannedReplyCategory', id) || null

export const selectCurrentAttachmentsById = state =>
  selectCurrentEntitiesById(state, 'attachment')

export const selectPendingAttachmentsById = state =>
  selectPendingEntitiesById(state, 'attachment')
