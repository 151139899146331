import { createSelector } from 'reselect'
import { selectChannelCustomFieldCategoryGroups } from './selectChannelCustomFieldCategoryGroups'

export const selectChannelCustomFieldsCategoriesById = createSelector(
  selectChannelCustomFieldCategoryGroups,
  groups => {
    return groups.reduce((result, group) => {
      // eslint-disable-next-line no-param-reassign
      result[group.id] = group.fields
      return result
    }, {})
  }
)
