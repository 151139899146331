import {
  updateConversationFragments,
  updateConversationResponse,
} from 'ducks/tickets/mutations'

export const changeContactMutation = `
mutation changeContactMutation($conversationId: ID!, $contactId: ID!) {
  conversationChangeContact(input: { conversationId: $conversationId, contactId: $contactId }) {
    ${updateConversationResponse()}
  }
}
  ${updateConversationFragments()}
`
