import { useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isBoostrappedSelector } from 'selectors/app'
import { emptyArr } from 'util/arrays'

import {
  doBootstrapCustomFieldsAndCategories,
  doFetchAllTrashedCustomFields,
} from './operations/fetching'
import { CONTACT, COMPANY } from './types'
import {
  selectIsTrashedCustomFieldsLoading,
  selectIsTrashedCustomFieldsErrored,
  selectIsTrashedCustomFieldsForType,
} from './selectors/trashed'

import {
  selectAllCustomFieldsHasErrored,
  selectAllCustomFieldsIsLoaded,
  selectAllCustomFieldsIsLoading,
  selectCustomFieldForId,
  selectCustomFieldForKey,
  selectCustomFields,
  selectCustomFieldsByCategoryIds,
  selectIsCustomFieldsOrCategoriesLoading,
} from './selectors'
import {
  selectAllCustomFieldCategoriesHasErrored,
  selectAllCustomFieldCategoriesIsLoaded,
  selectAllCustomFieldCategoriesIsLoading,
  selectCustomFieldCategories,
  selectCustomFieldCategoriesForType,
} from '../customFieldCategories'

export function useTrashedCustomFields() {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsTrashedCustomFieldsLoading)
  const isErrored = useSelector(selectIsTrashedCustomFieldsErrored)
  useEffect(() => {
    if (!isLoading) dispatch(doFetchAllTrashedCustomFields())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    isLoading,
    isErrored,
    contactFields: useSelector(state =>
      selectIsTrashedCustomFieldsForType(state, { type: CONTACT })
    ),
    companyFields: useSelector(state =>
      selectIsTrashedCustomFieldsForType(state, { type: COMPANY })
    ),
  }
}

export const useCustomField = (identifier, { mode = 'id' } = {}) => {
  const isLoading = useSelector(selectIsCustomFieldsOrCategoriesLoading)

  const isBootstrapped = useSelector(isBoostrappedSelector)

  const loading = isLoading || !isBootstrapped

  const customField = useSelector(state => {
    if (mode === 'id') {
      return selectCustomFieldForId(state, { id: identifier })
    }
    return selectCustomFieldForKey(state, { key: identifier })
  })

  return {
    customField: loading ? null : customField,
    isLoading: loading,
    isMissing: !customField && !loading,
  }
}

export const useLoadAllCustomFields = () => {
  const dispatch = useDispatch()
  const isCustomFieldsLoading = useSelector(selectAllCustomFieldsIsLoading)
  const isCustomFieldCategoriesLoading = useSelector(
    selectAllCustomFieldCategoriesIsLoading
  )

  const isCustomFieldsLoaded = useSelector(selectAllCustomFieldsIsLoaded)
  const isCustomFieldCategoriesLoaded = useSelector(
    selectAllCustomFieldCategoriesIsLoaded
  )

  const hasErrorCustomFields = useSelector(selectAllCustomFieldsHasErrored)
  const hasErrorCustomFieldsCategories = useSelector(
    selectAllCustomFieldCategoriesHasErrored
  )

  const customFields = useSelector(selectCustomFields)
  const customFieldCategories = useSelector(selectCustomFieldCategories)

  const isLoading = isCustomFieldsLoading || isCustomFieldCategoriesLoading
  const isLoaded = isCustomFieldsLoaded && isCustomFieldCategoriesLoaded
  const hasError = hasErrorCustomFields || hasErrorCustomFieldsCategories

  const reload = useCallback(
    () => dispatch(doBootstrapCustomFieldsAndCategories()),
    [dispatch]
  )

  useEffect(
    () => {
      if (!isLoading && !isLoaded) {
        reload()
      }
    },
    [reload, isLoading, isLoaded]
  )

  return {
    isLoading,
    isLoaded,
    hasError,
    isCustomFieldsLoading,
    isCustomFieldCategoriesLoading,
    isCustomFieldsLoaded,
    isCustomFieldCategoriesLoaded,
    hasErrorCustomFields,
    hasErrorCustomFieldsCategories,
    customFields,
    customFieldCategories,
    reload,
  }
}

export const useLoadCustomFieldsForType = (type, options = {}) => {
  const { excludeCategoryKeys: excludeKeys = [], forDisplay = false } =
    options || {}

  const {
    isLoading,
    isLoaded,
    hasError,
    isCustomFieldsLoading,
    isCustomFieldCategoriesLoading,
    isCustomFieldsLoaded,
    isCustomFieldCategoriesLoaded,
    hasErrorCustomFields,
    hasErrorCustomFieldsCategories,
    reload,
  } = useLoadAllCustomFields()

  const customFieldCategories =
    useSelector(state =>
      selectCustomFieldCategoriesForType(state, {
        type,
        excludeKeys,
        forDisplay,
      })
    ) || emptyArr

  const categoryIds = useMemo(() => customFieldCategories.map(({ id }) => id), [
    customFieldCategories,
  ])

  const customFields = useSelector(state =>
    selectCustomFieldsByCategoryIds(state, categoryIds, forDisplay)
  )

  return {
    isLoading,
    isLoaded,
    hasError,
    isCustomFieldsLoading,
    isCustomFieldCategoriesLoading,
    isCustomFieldsLoaded,
    isCustomFieldCategoriesLoaded,
    hasErrorCustomFields,
    hasErrorCustomFieldsCategories,
    customFields,
    customFieldCategories,
    reload,
  }
}
