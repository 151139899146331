import { UPDATE_DRAFT } from 'ducks/drafts2/constants'
import { doSendDraftToServer } from './doSendDraftToServer'
import { buildSharedDraftActionMetadata } from '../util'

export function doReinstateDraft(draft) {
  return (dispatch, getState) => {
    const state = getState()

    dispatch({
      type: UPDATE_DRAFT,
      payload: {
        draftType: draft.draftType,
        draftId: draft.id,
        ticketId: draft.ticketId,
        fields: draft,
      },
      meta: {
        ...buildSharedDraftActionMetadata(state),
      },
    })
    return dispatch(
      doSendDraftToServer(draft.id, draft.ticketId, draft.draftType)
    )
  }
}
