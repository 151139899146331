import { normalize } from 'normalizr'
import {
  changeEntity,
  syncEntity,
  clearEntities,
  mergeEntityChanges,
} from 'ducks/entities/actionUtils'
import { isEmpty } from 'util/objects'
import { all } from 'util/arrays'
import { isFunction } from 'util/functions'

// Kevin R (2021-08-03)
// Note the original entities structure returned data in this format
// { entityType1: {id1: entity1, id2: entity2 }, entityType2: {id1: entity1, id2: entity2 }}
// The new format request the following structure
// { current: { entityType1: {id1: entity1, id2: entity2 }, entityType2: {id1: entity1, id2: entity2 }} }
// For backward compatibility, we're constructing the following payload
// {
//   entityType1: {id1: entity1, id2: entity2 },
//   entityType2: {id1: entity1, id2: entity2 },
//   current: { entityType1: {id1: entity1, id2: entity2 }, entityType2: {id1: entity1, id2: entity2 }} }
// }
function extractEntities(transformedData = {}, phase, options = {}) {
  const {
    // to override the default changeEntity destination store
    // (if you want to save a transformed entity to pending instead of current for example)
    targetStore,
    // to override the default changeEntity operation
    targetOperation,
    missingEntityActions,
    additionalActions,
  } =
    options?.moduleOptions?.entities || {}

  let normalizedEntities = {}
  let transformedEntities = {}

  if (phase === 'STARTED' && options?.optimist?.entities) {
    normalizedEntities = transformedData
  } else if (options.normalizationSchema && !isEmpty(transformedData)) {
    normalizedEntities =
      normalize(transformedData, options.normalizationSchema).entities ||
      normalizedEntities
  }

  transformedEntities = options.transformEntities
    ? options.transformEntities(normalizedEntities)
    : normalizedEntities

  const entityChanges = []
  Object.keys(transformedEntities).forEach(entityType => {
    if (transformedEntities[entityType]) {
      Object.keys(transformedEntities[entityType]).forEach(entityId => {
        const entity = transformedEntities[entityType][entityId]
        entityChanges.push(
          changeEntity(
            entityType,
            entityId,
            entity,
            targetOperation,
            targetStore
          )
        )
      })
    }
  })

  // missingEntityActions: option to indicate removing an entity (if exists) from the store if there is no transformed entity returned
  // a scenario where this useful is:
  // 1. User A: has a loaded datagrid of which entityid:5 is included
  // 2. User B: has a loaded datagrid of which entityid:5 is included on a separate machine
  // 3. User B: deletes entityid:5 which nukes it from backend
  // 4. User A: has not refreshed the datagrid so now has deleted/stale data of entityid:5
  // 5. User A: clicks on 'Edit entityid:5', which fetches entityid:5 from API
  // 6. User A: API returns no data for entityid:5 since it's already deleted
  // 7. User A: missingEntityActions here realises that and also removes it (by id) from the 'current' entity data store
  if (missingEntityActions && Object.keys(transformedEntities).length === 0) {
    missingEntityActions.forEach(({ entityType, entityId, phases }) => {
      if (!phases || phases.includes(phase)) {
        entityChanges.push(changeEntity(entityType, entityId, {}, 'remove'))
      }
    })
  }

  entityChanges.push(
    ...batchAdditionalEntityActions(
      additionalActions,
      phase,
      transformedData,
      normalizedEntities,
      transformedEntities,
      options
    )
  )

  const { entities } = mergeEntityChanges(entityChanges)

  const backwardCompatibileEntities = {
    ...entities,
    ...transformedEntities,
  }

  const response = {
    normalizedEntities,
    transformedEntities,
    entities: backwardCompatibileEntities,
  }

  // console.log('extractEntities', {
  //   transformedData,
  //   normalizedEntities,
  //   transformedEntities,
  //   entityChanges,
  //   backwardCompatibileEntities,
  //   response,
  //   options,
  // })

  if (all(obj => isEmpty(obj), Object.values(response))) return null
  return response
}

/**
 * Instruct module to make additional changeEntity commands after the initial command.
 * Format of additionalActions looks something like:
 *
 * additionalActions: [
 *  {
 *    entityType: 'cannedReply',
 *    entityId: '123456',
 *    entity: {id: '123456', name: 'foo'}
 *    stores: ['pending']
 *    operation: 'replace'
 *    phases: ['SUCCESS', 'STARTED']
 *  }
 * ]
 */
function batchAdditionalEntityActions(
  additionalActions = [],
  currentPhase,
  transformedData,
  normalizedEntities,
  transformedEntities,
  options
) {
  if (!additionalActions) return []

  const entityChanges = []
  additionalActions.forEach(entityActionOrFn => {
    const entityAction = isFunction(entityActionOrFn)
      ? entityActionOrFn(
          currentPhase,
          transformedData,
          normalizedEntities,
          transformedEntities,
          options
        )
      : entityActionOrFn

    // if no entity action phases is defined,
    // assume additional actions are to be applied for all phases
    const {
      phases = ['STARTED', 'SUCCESS', 'FAILED'],
      entityId,
      entityType,
      operation,
      entity = {}, // not needed for remove operations
      type = 'change',
    } =
      entityAction || {}

    if (phases.includes(currentPhase)) {
      entityAction.stores.forEach(store => {
        if (type === 'sync') {
          entityChanges.push(syncEntity(entityType, entityId, operation, store))
        } else if (type === 'change') {
          entityChanges.push(
            changeEntity(entityType, entityId, entity, operation, store)
          )
        } else if (type === 'clear') {
          entityChanges.push(clearEntities(entityType, store))
        }
      })
    }
  })

  return entityChanges
}

export default function entitiesActionModule(phase, action, payload, options) {
  const optimistEntities = options?.optimist?.entities

  if (phase !== 'FAILED') {
    Object.assign(
      action,
      extractEntities(
        (phase === 'SUCCESS' ? payload : optimistEntities) || {},
        phase,
        options
      )
    )
  }

  return action
}
