import { createSelector } from 'reselect'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'
import { selectCurrentFoldersById } from '.'

export const selectCurrentFolderName = createSelector(
  selectCurrentFoldersById,
  selectCurrentQuery,
  (foldersById, query) => {
    return foldersById[query.folder]?.name
  }
)
