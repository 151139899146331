import {
  ADDRESS,
  BOOLEAN,
  DATE,
  DROPDOWN,
  FILE_TYPE,
  LINK,
  MONEY,
  MULTI_LINE,
  MULTI_SELECT,
  NUMBER,
  SINGLE_LINE,
  TIMEZONE,
} from 'ducks/crm/customFields/types'
import { FETCH_CONTACT_PAGE } from './types'

export const fetchContactForCustomFieldRequestKey = (key, value) => {
  return `${FETCH_CONTACT_PAGE}/${key}/${value}`
}

const contentNull = { content: null }

const defaultValueByType = {
  [ADDRESS]: {
    street: null,
    street2: null,
    city: null,
    state: null,
    postalCode: null,
    country: null,
  },
  [BOOLEAN]: { content: false },
  [DATE]: contentNull,
  [DROPDOWN]: contentNull,
  [FILE_TYPE]: {
    fileName: null,
    url: null,
    file: null,
  },
  [LINK]: {
    link: null,
    text: null,
  },
  [MONEY]: {
    amount: null,
    currencyCode: null,
  },
  [MULTI_LINE]: contentNull,
  [MULTI_SELECT]: [],
  [NUMBER]: contentNull,
  [SINGLE_LINE]: contentNull,
  [TIMEZONE]: contentNull,
  [URL]: contentNull,
}

export const defaultFormCustomFieldValue = customField => {
  const { type, isArray } = customField

  if (isArray) return []

  return defaultValueByType[type] || null
}
