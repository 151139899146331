import {
  account,
  feature,
  plan,
  pricing,
  usage,
  creditCard,
  discount,
  channel,
  folder,
  customFields,
  customFieldCategories,
} from 'ducks/entities/schema'

export const bootstrapSchema = {
  account,
  billing: {
    availableFeatures: [feature],
    usages: [usage],
    plans: [plan],
    pricings: [pricing],
    creditCards: [creditCard],
    discount,
  },
  channels: {
    edges: [
      {
        node: channel,
      },
    ],
  },
  folders: {
    nodes: [folder],
  },
  customFields: {
    edges: [
      {
        node: customFields,
      },
    ],
  },
  customFieldCategories: {
    edges: [
      {
        node: customFieldCategories,
      },
    ],
  },
}
