import { contacts, customFieldValueSubject } from 'ducks/entities/schema'
import { createConversationUpdateResponseSchema } from 'ducks/tickets/schema'

export const contactsNormalizationSchema = {
  contacts: {
    nodes: [contacts],
  },
}

export const conversationContactNormalizationSchema = {
  conversation: {
    contact: contacts,
  },
}

export const customFieldValueUpdateNormalizationScheme = {
  customFieldValuesUpdate: {
    subject: customFieldValueSubject,
  },
}

export const changeContactMutationSchema = createConversationUpdateResponseSchema(
  'conversationChangeContact'
)

export const createContactMutationSchema = {
  contactCreate: {
    contact: contacts,
  },
}

export const resyncContactMutationSchema = {
  contactResync: {
    contact: contacts,
  },
}

export const contactNormalizationSchema = {
  node: contacts,
}
