import { createBasicSelector } from 'util/redux'
import { selectCurrentMailbox } from './selectCurrentMailbox'

export const selectCurrentMailboxErrorMessage = createBasicSelector(
  selectCurrentMailbox,
  mailbox => {
    if (!mailbox) return null
    if (!mailbox.email_integration_error) return null

    return mailbox.email_integration_error.error_message
  }
)
