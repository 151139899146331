import { selectCompaniesById } from 'ducks/crm/companies/selectors/base'
import { isCompanyId, isConversationId } from 'ducks/crm/companies/utils'
import { selectContactsById } from 'ducks/crm/contacts/selectors/base'
import { selectCurrentConversationsById } from 'ducks/tickets/selectors'
import createCachedSelector from 're-reselect'
import { getRawId } from 'util/globalId'

export const selectSubjectById = createCachedSelector(
  (_state, subjectId) => subjectId,
  selectCompaniesById,
  selectContactsById,
  selectCurrentConversationsById,
  (subjectId, companiesById, contactsById, conversationsById) => {
    if (isCompanyId(subjectId)) return companiesById[subjectId]
    if (isConversationId(subjectId))
      return conversationsById[getRawId(subjectId)]
    return contactsById[subjectId]
  }
)((_state, subjectId) => subjectId || 'unknown')
