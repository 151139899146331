export const FETCH_FOLDERS_V2 = 'folders/FETCH_FOLDERS_V2'
export const FETCH_FOLDERS_V2_REQUEST = 'folders/FETCH_FOLDERS_V2_STARTED'
export const FETCH_FOLDERS_V2_SUCCESS = 'folders/FETCH_FOLDERS_V2_SUCCESS'
export const FETCH_FOLDERS_V2_FAIL = 'folders/FETCH_FOLDERS_V2_FAILED'

export const FETCH_CHAT_FOLDERS = 'folders/FETCH_CHAT_FOLDERS'
export const FETCH_CHAT_FOLDERS_REQUEST = 'folders/FETCH_CHAT_FOLDERS_STARTED'
export const FETCH_CHAT_FOLDERS_SUCCESS = 'folders/FETCH_CHAT_FOLDERS_SUCCESS'
export const FETCH_CHAT_FOLDERS_FAIL = 'folders/FETCH_CHAT_FOLDERS_FAILED'

export const FETCH_FOLDERS_BY_IDS_V2 = 'folders/FETCH_FOLDERS_BY_IDS_V2'
export const FETCH_FOLDERS_BY_IDS_V2_REQUEST =
  'folders/FETCH_FOLDERS_BY_IDS_V2_STARTED'
export const FETCH_FOLDERS_BY_IDS_V2_SUCCESS =
  'folders/FETCH_FOLDERS_BY_IDS_V2_SUCCESS'
export const FETCH_FOLDERS_BY_IDS_V2_FAIL =
  'folders/FETCH_FOLDERS_BY_IDS_V2_FAILED'

export const FETCH_FOLDER_CHANNELS = 'folders/FETCH_FOLDER_CHANNELS'
export const FETCH_FOLDER_CHANNELS_REQUEST =
  'folders/FETCH_FOLDER_CHANNELS_STARTED'
export const FETCH_FOLDER_CHANNELS_SUCCESS =
  'folders/FETCH_FOLDER_CHANNELS_SUCCESS'
export const FETCH_FOLDER_CHANNELS_FAIL = 'folders/FETCH_FOLDER_CHANNELS_FAILED'

export const DELETE_FOLDER = 'folders/DELETE_FOLDER'
export const DELETE_FOLDER_STARTED = 'folders/DELETE_FOLDER_STARTED'
export const DELETE_FOLDER_SUCCESS = 'folders/DELETE_FOLDER_SUCCESS'
export const DELETE_FOLDER_FAILED = 'folders/DELETE_FOLDER_FAILED'

export const UPDATE_FOLDER = 'folders/UPDATE_FOLDER'
export const UPDATE_FOLDER_STARTED = 'folders/UPDATE_FOLDER_STARTED'
export const UPDATE_FOLDER_SUCCESS = 'folders/UPDATE_FOLDER_SUCCESS'
export const UPDATE_FOLDER_FAILED = 'folders/UPDATE_FOLDER_FAILED'

export const CREATE_FOLDER = 'folders/CREATE_FOLDER'
export const CREATE_FOLDER_STARTED = 'folders/CREATE_FOLDER_STARTED'
export const CREATE_FOLDER_SUCCESS = 'folders/CREATE_FOLDER_SUCCESS'
export const CREATE_FOLDER_FAILED = 'folders/CREATE_FOLDER_FAILED'
