import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { selectChannelCustomFieldsCategoriesById } from 'ducks/crm/channels/selectors/selectChannelCustomFieldsCategoriesById'
import { emptyArr } from 'util/arrays'
import { createBasicSelector } from 'util/redux'

import { selectCustomFields, selectCustomFieldsById } from './base'
import { sortCustomFieldsForSettingsDisplay } from './utils'

export const selectCustomFieldsByCategoryId = createSelector(
  selectCustomFieldsById,
  selectChannelCustomFieldsCategoriesById,
  (fields, channelCategoriesById) => {
    const rep = Object.assign(
      Object.values(fields).reduce((result, field) => {
        const { category } = field

        if (!result[category]) {
          // eslint-disable-next-line no-param-reassign
          result[category] = []
        }

        if (!field.deletedAt) {
          result[category].push(field)
        }

        return result
      }, {}),
      channelCategoriesById
    )
    return rep
  }
)

export const selectCustomFieldsForCategoryId = createBasicSelector(
  selectCustomFieldsByCategoryId,
  (state, { categoryId }) => categoryId,
  (state, { forDisplay }) => forDisplay,
  (fieldByCategoryId, categoryId, forDisplay) => {
    const fields = fieldByCategoryId[categoryId] || emptyArr

    if (forDisplay) {
      return sortCustomFieldsForSettingsDisplay(fields)
    }

    return fields
  }
)

export const selectTrashedCustomFieldsForCategoryId = createCachedSelector(
  selectCustomFields,
  (state, { categoryId }) => categoryId,
  (fields, categoryId) => {
    return fields.filter(
      ({ deletedAt, category }) => !!deletedAt && category === categoryId
    )
  }
)((state, { categoryId }) => categoryId)

export const selectCustomFieldsForCategoryIdByKey = createCachedSelector(
  selectCustomFieldsForCategoryId,
  fields => {
    return fields.reduce((result, field) => {
      // eslint-disable-next-line no-param-reassign
      result[field.key] = field
      return result
    }, {})
  }
)((state, { categoryId }) => categoryId)

export const selectCustomFieldIdsForCategoryId = createCachedSelector(
  selectCustomFieldsByCategoryId,
  (_state, { categoryId }) => categoryId,
  (customFieldsByCategoryId, categoryId) => {
    return customFieldsByCategoryId[categoryId]?.map(({ id }) => id) || emptyArr
  }
)((state, { categoryId }) => categoryId)
