import { buildFolderKindsTypes } from './buildFolderKindsTypes'

export function getFolderKind(folder, data) {
  return (
    buildFolderKindsTypes(data).find(folderType => {
      const conditions = folder.conditions.nodes || folder.conditions
      if (conditions.length !== folderType.conditions.length) return null
      let isMatch = true
      folderType.conditions.some(folderTypeCondition => {
        isMatch = conditions.some(folderCondition => {
          return (
            folderTypeCondition.param === folderCondition.param &&
            folderTypeCondition.value.includes(folderCondition.value) &&
            folderTypeCondition.operators.includes(folderCondition.operator)
          )
        })
        return !isMatch
      })
      return isMatch ? folderType : null
    })?.type || null
  )
}
