import { combineReducers } from 'core/redux'

import customProfile from '../customProfile/reducers'
import emailMarketing from '../emailMarketing/reducers'
import github from '../github/reducers'
import jiraCloud from '../jiraCloud/reducers'
import jiraServer from '../jiraServer/reducers'
import trello from '../trello/reducers'
import credentials from './credentials'
import hubspot from '../hubspot/reducers'
import recentConversations from '../recentConversations/reducers'
import salesforce from '../salesforce/reducers'
import shopify from '../shopify/reducers'
import slack from '../slack/reducers'
import recharge from '../recharge/reducers'
import stripe from '../stripe/reducers'

export default combineReducers({
  credentials,
  emailMarketing,
  recentConversations,
  thirdPartyData: combineReducers({
    customProfile,
    github,
    jiraCloud,
    jiraServer,
    trello,
    hubspot,
    salesforce,
    shopify,
    slack,
    recharge,
    stripe,
  }),
})
