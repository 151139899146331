import { useCallback, useEffect, useState } from 'react'
import emailParser from 'email-addresses'
import { queryParams } from 'util/params'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDraftDefaultByTicketId,
  selectDraftIdByTicketId,
} from 'ducks/drafts2/selectors'
import {
  doDeleteEmptyDraft,
  doSearchRecipients,
} from 'ducks/drafts2/operations'
import { doUpsertReplyDraft } from 'ducks/drafts2/operations/doUpsertReplyDraft'
import { selectRequestByType } from 'ducks/requests/selectors'

import { fetchDraftsActionRequestKey } from './util'
import {
  DRAFT_NEW_CONVERSATION_CONVERSATION_ID,
  REQUEST_DRAFT_TYPES,
} from './constants'

export function useQueryParamRecipientLoader(draftId) {
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [draftRecipients, setDraftRecipients] = useState(null)
  const dispatch = useDispatch()

  const loadRecipientByEmail = useCallback(
    async (type, address) => {
      let found = null
      const parsed = emailParser.parseAddressList({
        input: address,
        rejectTLD: true,
      })
      if (parsed && parsed[0]) {
        const email = parsed[0].address
        found = { email: parsed[0].address, name: parsed[0].name }
        const recipients = await dispatch(doSearchRecipients(email))
        const loadedRecipient = (recipients?.contacts || []).find(recipient => {
          return recipient.email === email
        })
        if (loadedRecipient) found = loadedRecipient
      }
      return [type, found]
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (loading) return
      setLoading(true)
      const updateRecipients = async () => {
        const loadPromises = []

        const params = queryParams()
        ;['to', 'cc', 'bcc'].forEach(type => {
          if (!params[type]) return

          loadPromises.push(loadRecipientByEmail(type, params[type]))
        })

        const responses = await Promise.all(loadPromises)

        const recipients = responses.reduce((acc, [type, recipient]) => {
          // eslint-disable-next-line no-param-reassign
          if (!acc[type]) acc[type] = []
          if (recipient) acc[type].push(recipient)

          return acc
        }, {})

        if (Object.keys(recipients).length > 0) {
          setDraftRecipients(recipients)
        }

        setFetching(false)
      }
      updateRecipients()
    },
    [dispatch, setFetching, draftId, loadRecipientByEmail, loading]
  )

  return {
    loading: fetching,
    draftRecipients,
  }
}

export const useDraft = (ticketId, type) => {
  const [draftsLoaded, setDraftsLoaded] = useState(false)
  const dispatch = useDispatch()
  const draftId = useSelector(state =>
    selectDraftIdByTicketId(state, ticketId, type)
  )

  const {
    loading: loadingToRecipient,
    draftRecipients,
  } = useQueryParamRecipientLoader(draftId)

  const requestKey = fetchDraftsActionRequestKey(
    DRAFT_NEW_CONVERSATION_CONVERSATION_ID,
    REQUEST_DRAFT_TYPES[type]
  )

  const serverDraftLoaded = useSelector(state =>
    selectRequestByType(state, requestKey)
  ).loaded

  const loaded = !loadingToRecipient && serverDraftLoaded && draftsLoaded

  const draftDefaults = useSelector(state =>
    selectDraftDefaultByTicketId(state, ticketId, type)
  )

  useEffect(
    () => {
      if (loadingToRecipient || !serverDraftLoaded || draftsLoaded) return

      if (draftId && !draftRecipients) {
        // use existing draft
        setDraftsLoaded(true)
        return
      }

      dispatch(
        doUpsertReplyDraft(draftId, type, ticketId, null, {
          ...draftDefaults,
          ...draftRecipients,
        })
      )
      setDraftsLoaded(true)
    },
    [
      dispatch,
      draftId,
      loadingToRecipient,
      serverDraftLoaded,
      draftsLoaded,
      type,
      draftRecipients,
      draftDefaults,
      ticketId,
    ]
  )

  useEffect(
    () => {
      return () => {
        dispatch(doDeleteEmptyDraft(ticketId, type))
      }
    },
    [dispatch, ticketId, type]
  )

  return {
    ticketId,
    draftId,
    loaded,
  }
}
