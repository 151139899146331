import { createSelector } from 'reselect'
import { isEmpty, emptyArr, compact } from 'util/arrays'
import { selectSelectedTicketsMailboxIds } from './selectSelectedTicketsMailboxIds'
import { selectMailboxesById } from './selectMailboxesById'

export const selectSelectedTicketsMailboxes = createSelector(
  selectSelectedTicketsMailboxIds,
  selectMailboxesById,
  (mailboxIds, mailboxesById) => {
    if (isEmpty(mailboxIds)) return emptyArr
    return compact(mailboxIds.map(id => mailboxesById[id]))
  }
)
