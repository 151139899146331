import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'

const selectIntegrationData = state =>
  state?.integrations?.thirdPartyData?.stripe || emptyObj

export const selectCustomers = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.customers
)

const selectCustomersByIntegrationId = createBasicSelector(
  selectCustomers,
  customers => customers.byIntegrationId || emptyObj
)

export const selectCustomersForIntegrationId = createBasicSelector(
  selectCustomersByIntegrationId,
  (state, integrationId) => integrationId,
  (customersByIntegrationId, integrationId) =>
    customersByIntegrationId[integrationId] || emptyObj
)

export const selectCustomersByCustomerEmail = createBasicSelector(
  selectCustomersForIntegrationId,
  customersForIntegrationId =>
    customersForIntegrationId.byCustomerEmail || emptyObj
)

export const selectCustomerForCustomerEmail = createBasicSelector(
  selectCustomersByCustomerEmail,
  (state, integrationId, { email }) => email,
  (byCustomerEmail, email) => byCustomerEmail[email] || emptyObj
)
