import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAttachmentsForTicketId,
  selectConversationAttachmentsRequestByTicketId,
} from 'ducks/integrations/attachments/selectors'
import { emptyArr } from 'util/arrays'
import { buildIdFromAny } from 'util/globalId'
import { doFetchAttachments } from './operations'

export const useAttachments = ticketId => {
  const dispatch = useDispatch()
  const conversationId = buildIdFromAny('Conversation', ticketId)
  const isNewConversation = ticketId && ticketId === 'new'
  const { loaded, loading, untried } = useSelector(state =>
    selectConversationAttachmentsRequestByTicketId(state, ticketId)
  )
  const attachments = useSelector(state =>
    selectAttachmentsForTicketId(state, conversationId)
  )

  useEffect(
    () => {
      if (untried && !loaded && !loading && !isNewConversation) {
        dispatch(doFetchAttachments(ticketId, 'CURRENT'))
      }
    },
    [dispatch, ticketId, isNewConversation, untried, loaded, loading]
  )

  return {
    attachments: attachments || emptyArr,
    isLoading: !isNewConversation && (loading || untried),
    isLoaded: loaded,
  }
}
