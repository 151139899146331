/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { withPush, without } from 'util/arrays'
import { getRawId } from 'util/globalId'

import { COLLISION_STATES } from './constants'
import {
  FETCH_AGENT_COLLISION_STATUSES_SUCCESS,
  UPDATE_AGENT_TICKET_COLLISION_STATUS,
} from './actionTypes'

// ===================================================
//         Logic table for agent collisions V2
// ---------------------------------------------------
//                         view    replying  noting   typing
//    begin typing note     x                  x         x
//     end typing note      x                  x
//   begin leaving note     x                  x
//    end leaving note      x
//   begin typing reply     x         x                  x
//    end typing reply      x         x
//       begin replying     x         x
//        end replying      x
//        begin viewing     x
//         end viewing
// ===================================================

const initialState = {
  byAgentId: {},
  byConversationId: {},
}

function updateAgentCollision(
  draftState,
  { agentId, conversationId, timestamp, states }
) {
  if (!draftState.byAgentId[agentId]) {
    draftState.byAgentId[agentId] = {}
  }
  draftState.byAgentId[agentId].conversationId = conversationId
  draftState.byAgentId[agentId].timestamp = timestamp
  COLLISION_STATES.forEach((stateName, index) => {
    draftState.byAgentId[agentId][stateName] = states[index]
  })
}

function updateTicketCollision(
  draftState,
  { agentId, conversationId, states }
) {
  if (!draftState.byConversationId[conversationId]) {
    draftState.byConversationId[conversationId] = {}
    COLLISION_STATES.forEach(stateName => {
      draftState.byConversationId[conversationId][stateName] = []
    })
  }

  Object.keys(draftState.byConversationId).forEach(stateConversationId => {
    COLLISION_STATES.forEach((stateName, index) => {
      const applyChange =
        conversationId === stateConversationId && states[index]
          ? withPush
          : without
      applyChange(
        draftState.byConversationId[stateConversationId][stateName],
        agentId
      )
    })
  })
}

export default createActionTypeReducer(
  {
    [FETCH_AGENT_COLLISION_STATUSES_SUCCESS]: (draftState, { payload }) => {
      Object.keys(payload).forEach(agentId => {
        const {
          conversationId: inputConversationId,
          states,
          timestamp,
        } = payload[agentId]
        const conversationId = getRawId(inputConversationId)
        updateAgentCollision(draftState, {
          agentId,
          conversationId,
          states,
          timestamp,
        })
        updateTicketCollision(draftState, {
          agentId,
          conversationId,
          states,
          timestamp,
        })
      })
      return draftState
    },
    [UPDATE_AGENT_TICKET_COLLISION_STATUS]: (
      draftState,
      {
        payload: {
          agentId,
          conversationId: inputConversationId,
          states,
          timestamp,
        },
      }
    ) => {
      const conversationId = getRawId(inputConversationId)
      updateAgentCollision(draftState, {
        agentId,
        conversationId,
        states,
        timestamp,
      })
      updateTicketCollision(draftState, {
        agentId,
        conversationId,
        states,
        timestamp,
      })
      return draftState
    },
  },
  initialState
)
