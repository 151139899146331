import { createBasicSelector } from 'util/redux'

import selectGitHubNodesById from './selectGitHubNodesById'

export default createBasicSelector(
  selectGitHubNodesById,
  (state, { repositoryId }) => repositoryId,
  (nodes, repositoryId) => {
    const node = nodes[repositoryId]
    return node && node.isLoadingIssues
  }
)
