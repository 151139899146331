import {
  selectCurrentEntities,
  selectCurrentEntitiesById,
} from 'ducks/entities/selectors'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { groupBy } from 'util/arrays'
import { createBasicSelector } from 'util/redux'
import { DEFAULT_CATEGORY_KEY_ORDER } from '../constants'

export function selectBase(state) {
  return state.crm.customFieldCategories
}
export function selectCustomFieldCategoriesById(state) {
  return selectCurrentEntitiesById(state, 'customFieldCategories')
}

export const selectAllCustomFieldCategories = createBasicSelector(state =>
  selectCurrentEntities(state, 'customFieldCategories')
)

export const selectCustomFieldCategories = createSelector(
  selectAllCustomFieldCategories,
  categories => {
    return categories.filter(({ key }) => key !== 'contact_handles')
  }
)

export const selectCustomFieldCategoriesByKey = createSelector(
  selectCustomFieldCategories,
  categories => groupBy(categories, ({ key }) => key)
)

export const selectCustomFieldCategoryForKey = createBasicSelector(
  selectCustomFieldCategoriesByKey,
  (state, { key }) => key,
  (customFieldCategories, key) => {
    const forKey = customFieldCategories[key]
    if (forKey) return forKey[0]
    return undefined
  }
)

export const selectCustomFieldCategoryKeys = createSelector(
  selectCustomFieldCategories,
  categories => categories.map(({ key }) => key)
)

export const selectCustomFieldCategoriesForType = createCachedSelector(
  selectCustomFieldCategories,
  (state, { type }) => type,
  (state, { excludeKeys }) => excludeKeys,
  (state, { forDisplay }) => forDisplay,
  (categories, type, excludeKeys, forDisplay) => {
    const exceptKeys = excludeKeys || []

    // Filter categories based on type and excluded keys
    const filteredCategories = categories.filter(
      ({ type: categoryType, key }) =>
        categoryType === type &&
        !exceptKeys.some(
          excluded =>
            excluded instanceof RegExp ? excluded.test(key) : excluded === key
        )
    )

    if (forDisplay) {
      // Create a Set for quick lookup of ordered keys
      const orderSet = new Set(DEFAULT_CATEGORY_KEY_ORDER)

      // Initialize a Map to store ordered categories and an array for others
      const categoryMap = new Map()
      const otherCategories = []

      // Partition categories into ordered and others in a single pass
      filteredCategories.forEach(category => {
        if (orderSet.has(category.key)) {
          categoryMap.set(category.key, category)
        } else {
          otherCategories.push(category)
        }
      })

      // Build the ordered categories array based on DEFAULT_CATEGORY_KEY_ORDER
      const orderedCategories = DEFAULT_CATEGORY_KEY_ORDER.map(key =>
        categoryMap.get(key)
      ).filter(Boolean) // Remove undefined if the key wasn't found

      // Sort the other categories by createdAt descending
      otherCategories.sort((a, b) => b.createdAt - a.createdAt)

      // Combine the ordered and other categories
      return [...orderedCategories, ...otherCategories]
    }
    // Default sorting by createdAt descending
    return filteredCategories.sort((a, b) => b.createdAt - a.createdAt)
  }
)((state, { type, excludeKeys, forDisplay }) => {
  const typeKey = type || 'UNKNOWN'
  const excludeKeysKey = (excludeKeys || []).join('-') || 'UNKNOWN'
  const forDisplayKey = forDisplay || 'UNKNOWN'

  return `${typeKey}-${excludeKeysKey}-${forDisplayKey}`
})

export const selectOtherCategoriesAsOptions = createCachedSelector(
  selectCustomFieldCategories,
  (state, { categoryId }) => categoryId,
  (categories, categoryId) => {
    return categories
      .filter(({ id }) => id !== categoryId)
      .sort(({ name: a }, { name: b }) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1
        if (a.toLowerCase() > b.toLowerCase()) return 1
        return 0
      })
      .map(({ id, name }) => ({
        text: name,
        value: id,
      }))
  }
)((state, { categoryId }) => categoryId)
