import { selectCurrentAction, selectQueryParams } from 'selectors/location'
import { setKind } from 'redux-first-router'
import { mergePlain } from 'util/merge'

export const goToPage = (pageAction, { kind, preserveQuery, merge } = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const currentPageQuery = selectQueryParams(state)
  const currentPageAction = selectCurrentAction(state)
  const baseAction = merge ? currentPageAction : {}

  const action = { ...mergePlain(baseAction, pageAction) }

  if (preserveQuery) {
    action.meta = {
      ...(action.meta || {}),
      query: { ...(action?.meta?.query || {}), ...currentPageQuery },
    }
  }

  return dispatch(kind ? setKind(action, kind) : action)
}
